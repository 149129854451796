import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import {
  Drawer,
  Tooltip,
  Badge,
  Tab,
  Tabs,
  Popover,
  Button
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import useStyles from './ChatRoomStyles';
import clsx from 'clsx';
import useWindowDimensions from '../../hooks/windowDimensions';
import {
  setRoomClosed,
  setRoomData,
  setDrawerOpen,
  setForceTabIndex,
  setQuizStatus,
  setQaCount,
  setQaNotifyCount
} from '../../store/actions/roomStatus';
import {
  setSessionId,
  setEmail,
  setDisplayName,
  setCompanyName,
  setCountryCode,
  setLanguage,
  setTitle,
  setPhoneNumber,
  setOrgType
} from '../../store/actions/auth';
import Loader from '../loader/Loader';
import ChatSettingsDialog from '../chatSettingsDialog/ChatSettingsDialog';
import { updateActiveSession } from '../../api/session';
import { useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  videoEndingIn,
  videoStartingIn,
  getVideoStartTimeLocal
} from '../../utils/TimeUtils';
import { getEmbedLinkFromLanguage } from '../../utils/CountryUtils';
import {
  getNextWebcastsData,
  getNextWebcastsRegisterLink
} from '../../utils/RoomUtils';
import { isValidEmail } from '../../utils/StringUtils';
import liveChatTitle from '../../assets/live_chat_logo.png';
import titeLogo from '../../assets/mc-icon-white.png';
import drawerCollapseIcon from '../../assets/svg_icons/collapse_icon.svg';
import drawerOpenIcon from '../../assets/svg_icons/open_icon.svg';
import WebcastCard from '../webcastsCard/WebcastCard';
import EndingDialog from '../endingDialog/EndingDialog';
import CountDown from '../countDown/CountDown';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import WebviewDialog from '../webviewDialog/WebviewDialog';
import LiveChatTab from '../liveChatTab/LiveChatTab';
import PeopleTab from '../peopleTab/PeopleTab';
import VideoPlayer from '../videoPlayer/videoPlayer';
import QuizTab from '../quizTab/QuizTab';
import EjectIcon from '@material-ui/icons/Eject';
import QaTab from '../qaTab/QaTab';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Logo from './logo';
//this component fetches and sets roomStatus from indigo_live_rooms and renders the chat tabs
function TabPanel(props) {
  const {
    children,
    value,
    index,
    maxHeight,
    minHeight,
    iframeTab,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`chat-tabpanel-${index}`}
      aria-labelledby={`chat-tab-${index}`}
      {...other}
    >
      {!iframeTab && value === index && (
        <div
          style={{
            height: '100%',
            maxHeight: maxHeight,
            minHeight: minHeight
          }}
        >
          {children}
        </div>
      )}
      {iframeTab && (
        <div
          style={{
            height: '100%',
            maxHeight: maxHeight,
            minHeight: minHeight,
            display: value === index ? null : 'none'
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
const ChatRoom = props => {
  const { width, height } = useWindowDimensions();
  const [commentUserColor, setCommentUserColor] = useState(null); // for color of user according to session
  const [chatStarted, setChatStarted] = React.useState(false);
  const [videoStarted, setVideoStarted] = React.useState(false);
  const [timerSet, setTimerSet] = React.useState(false); //for chat timeout if chat did not start yet
  const [activityTimerSet, setActivityTimer] = React.useState(false); //for updating session every 5 minutes
  const [dialogOpen, setDialogOpen] = React.useState(false); //for settings dialog
  const [credentialsSet, setCredentialsSet] = React.useState(false); //to stop updating session when user is in settings
  const [resendSession, setResendSession] = React.useState(false); //to resend session update when user updates settings after first save
  const [endingDialogOpen, setEndingDialogOpen] = React.useState(false);
  const [registerDialogOpen, setRegisterDialogOpen] = React.useState(false);
  const [selectedTabIndex, setSelectedTabIndexState] = React.useState(0);
  const [forceReloadQuiz, setForceReloadQuiz] = React.useState(false);
  const [mobileQuizButton, setMobileQuizButton] = React.useState(false);
  const [scrolledDown, setScrolledDown] = React.useState(false);
  const scrollDownRef = React.useRef(null);
  const scrollUpRef = React.useRef(null);
  const [peopleAnchorEl, setPeopleAnchorEl] = React.useState(null);
  const peopleOpen = Boolean(peopleAnchorEl);
  const [
    selectedOtherPrivateSession,
    setSelectedOtherPrivateSession
  ] = useState(null);
  const [selectedPivateRoomId, setSelectedPivateRoomId] = useState(null);
  const [
    videoNotificationMessage,
    setVideoNotificationMessage
  ] = React.useState(null);
  const [quizIframeHeight, setQuizIframeHeight] = React.useState(
    width <= 800 ? `${height - 340}px` : '100%'
  );

  const {
    setRoomData,
    setDrawerOpen,
    roomStatus,
    auth,
    localSession,
    setSessionId,
    setDisplayName,
    setEmail,
    setCompanyName,
    setCountryCode,
    setTitle,
    setPhoneNumber,
    setOrgType,
    setLanguage,
    roomId,
    setForceTabIndex,
    setQuizStatus,
    setQaCount,
    setQaNotifyCount,
    roomData
  } = props;
  const QA_COUNT_SUB = gql`
    subscription qaCountSub {
      db_main_indigo_live_qa_aggregate(where: {deleted_at: {_is_null: true}, _and: {room_id: {_eq: "${roomId}"}}}) {
        aggregate {
          count
        }
      }
    }
  `;
  const QA_COUNT_SUB_RESULT = useSubscription(QA_COUNT_SUB);
  useEffect(() => {
    if (
      QA_COUNT_SUB_RESULT &&
      !QA_COUNT_SUB_RESULT.error &&
      !QA_COUNT_SUB_RESULT.loading &&
      QA_COUNT_SUB_RESULT.data &&
      QA_COUNT_SUB_RESULT.data.db_main_indigo_live_qa_aggregate &&
      QA_COUNT_SUB_RESULT.data.db_main_indigo_live_qa_aggregate.aggregate
    ) {
      var newQaCount =
        QA_COUNT_SUB_RESULT.data.db_main_indigo_live_qa_aggregate.aggregate
          .count;
      if (roomStatus.qaCount == null || roomStatus.qaNotifyStatus == 'inside')
        setQaCount(newQaCount);
      else if (roomStatus.qaCount > newQaCount) {
        setQaCount(newQaCount);
      } else if (roomStatus.qaCount < newQaCount) {
        setQaNotifyCount(newQaCount - roomStatus.qaCount);
      }
    }
  }, [
    QA_COUNT_SUB_RESULT,
    roomStatus.qaCount,
    roomStatus.qaNotifyStatus,
    setQaCount,
    setQaNotifyCount
  ]);
  const QA_TAB_NAME = (
    <Badge
      color="secondary"
      badgeContent={auth.is_organizer ? roomStatus.qaNotifyCount : null}
    >
      Questions
    </Badge>
  );
  const qaTabActive = roomData ? roomData.qa_tab : null;
  const allowChat = roomData ? roomData.chat_tab : false;
  const quizTabIndex = qaTabActive ? 2 : 1;
  const classes = useStyles({
    background: roomData && roomData.style ? roomData.style.background : null
  });
  const [embedLink, setEmbedLink] = React.useState(
    getEmbedLinkFromLanguage(auth.language)
  );

  const onChatSettingsConfirm = function(data) {
    const dataNewEmail = data.email;
    const dataNewDisplayName = data.display_name;
    const dataNewCompanyName = data.company_name;
    const dataNewCountryCode = data.country_code;
    const dataNewCaptionLanguage = data.language;
    const dataNewTitle = data.title;
    const dataNewPhoneNumber = data.phone_number;
    const dataNewOrgType = data.org_type;
    if (dataNewCaptionLanguage !== captionLanguage) {
      setLanguage(dataNewCaptionLanguage);
      setCaptionLanguage(dataNewCaptionLanguage);
      setEmbedLink(getEmbedLinkFromLanguage(dataNewCaptionLanguage));
    }
    if (isValidEmail(dataNewEmail)) {
      if (dataNewEmail !== auth.email) {
        setNewEmail(dataNewEmail);
        setEmail(dataNewEmail);
      }
      if (dataNewDisplayName !== auth.display_name) {
        setNewDisplayName(dataNewDisplayName);
        setDisplayName(dataNewDisplayName);
      }
      if (dataNewCompanyName !== auth.company_name) {
        setNewCompanyName(dataNewCompanyName);
        setCompanyName(dataNewCompanyName);
      }
      if (dataNewTitle !== auth.title) {
        setNewTitle(dataNewTitle);
        setTitle(dataNewTitle);
      }

      if (dataNewCountryCode !== auth.country_code) {
        setNewCountryCode(dataNewCountryCode);
        setCountryCode(dataNewCountryCode);
      }
      if (dataNewPhoneNumber !== auth.phone_number) {
        setNewPhonenumber(dataNewPhoneNumber);
        setPhoneNumber(dataNewPhoneNumber);
      }
      if (dataNewOrgType !== auth.org_type) {
        setNewOrgType(dataNewOrgType);
        setOrgType(dataNewOrgType);
      }

      setCredentialsSet(true);
      setResendSession(true);
      setDialogOpen(false);
    } else {
      alert('Please fill a valid email');
    }
  };

  const [newEmail, setNewEmail] = React.useState(auth ? auth.email : null);
  const [newDisplayName, setNewDisplayName] = React.useState(
    auth ? auth.display_name : null
  );
  const [newCompanyName, setNewCompanyName] = React.useState(
    auth ? auth.company_name : ''
  );
  const [newCountryCode, setNewCountryCode] = React.useState(
    auth ? auth.country_code : ''
  );
  const [captionLanguage, setCaptionLanguage] = React.useState(
    auth ? auth.language : ''
  );
  const [newTitle, setNewTitle] = React.useState(auth ? auth.title : '');
  const [triggerUpdateSession, setTriggerUpdateSession] = React.useState(false);

  const [newPhonenumber, setNewPhonenumber] = React.useState(
    auth ? auth.phone_number : ''
  );
  const [newOrgType, setNewOrgType] = React.useState(auth ? auth.org_type : '');
  const drawerWidth = 450;
  if (!allowChat && roomStatus.chatDrawerOpen) {
    setDrawerOpen(false);
  }
  if (width <= 800 && roomStatus.chatDrawerOpen) {
    setDrawerOpen(false);
  }
  const updateSession =
    credentialsSet &&
    auth.display_name === newDisplayName &&
    auth.email === newEmail &&
    auth.company_name === newCompanyName &&
    auth.country_code === newCountryCode &&
    auth.title === newTitle &&
    auth.phone_number === newPhonenumber;
  const videoEndingSecs = videoEndingIn(
    roomStatus.start_time,
    roomStatus.duration
  );
  const videoDidEnd =
    videoEndingIn(roomStatus.start_time, roomStatus.duration) < -345; //320
  const QaTabComp = qaTabActive && (
    <Tab label={QA_TAB_NAME} id={`chat-tab-${1}`} style={{ fontSize: 16 }} />
  );

  useEffect(() => {
    if (triggerUpdateSession) {
      updateActiveSession(auth, localSession).then(result => {
        if (result && result.content && result.content.id)
          setSessionId(result.content.id);
      });
      setTriggerUpdateSession(false);
    }
  }, [auth, localSession, setSessionId, triggerUpdateSession]);

  useEffect(() => {
    // localStorage.setItem('auth', JSON.stringify(auth));
    if (resendSession && updateSession) {
      updateActiveSession(auth, localSession).then(result => {
        if (result && result.content && result.content.id)
          setSessionId(result.content.id);
      });
      setResendSession(false);
    }
    if (!activityTimerSet && updateSession) {
      updateActiveSession(auth, localSession).then(result => {
        if (result && result.content && result.content.id)
          setSessionId(result.content.id);
      });
      setInterval(() => {
        setTriggerUpdateSession(true);
      }, 300000);
      setActivityTimer(true);
    }
    const vidStarting = videoStartingIn(
      roomStatus.start_time,
      roomStatus.duration
    );
    //VIDEO /CHAT STARTING LOGIC AND TIMERS
    if (vidStarting !== null && !isNaN(vidStarting)) {
      if (vidStarting <= 1800) {
        if (videoEndingSecs < -1800) {
          if (chatStarted) setChatStarted(false);
        }
        // if starting in less than 30 minutes, enable chat
        else if (!chatStarted) setChatStarted(true);
        if (vidStarting > 0 && !timerSet) {
          //vidStarting + 300
          setTimerSet(true);
          setVideoStarted(false);
          //timeout for video rendering
          if (vidStarting < 5000) {
            setTimeout(() => {
              setVideoStarted(true);
              setChatStarted(true);
            }, vidStarting * 1000); //vidStarting + 300
          }
        } else if (vidStarting <= 0 && !videoStarted) {
          //vidStarting + 300
          setVideoStarted(true);
        }
      } else if (!timerSet) {
        setTimerSet(true);
        setChatStarted(false);
        setVideoStarted(false);
        if (vidStarting - 1800 < 5000) {
          setTimeout(() => {
            setChatStarted(true);
            setTimerSet(false);
          }, (vidStarting - 1800) * 1000);
        }
      }
    }
  }, [
    resendSession,
    updateSession,
    activityTimerSet,
    roomStatus.start_time,
    roomStatus.duration,
    auth,
    localSession,
    setSessionId,
    timerSet,
    videoEndingSecs,
    chatStarted,
    videoStarted
  ]);
  const TIMER_DATE = getVideoStartTimeLocal(roomStatus.start_time);

  const SESSION_SUB = gql`
    subscription mySessionSub {
        db_main_indigo_live_active_sessions(where: {deleted_at: {_is_null: true}, _and: {id: {_eq: "${auth.session_id ||
          0}"}}}) {
          id
          color
          display_name
          active
          email
          phone_number
          title
        }
    }
  `;
  const SESSION_SUB_STATUS = useSubscription(SESSION_SUB);

  const SESSION_COUNT_SUB = gql`
  subscription activeCountSub {
    db_main_indigo_live_active_sessions_aggregate(where: {deleted_at: {_is_null: true}, _and: {active: {_eq: true}, _and: {room_id: {_eq: "${auth.room_id}"}}}}) {
      aggregate {
        count
      }
    }
  }`;
  const SESSION_COUNT_SUB_STATUS = useSubscription(SESSION_COUNT_SUB);
  var activeCount =
    !SESSION_COUNT_SUB_STATUS.loading &&
    !SESSION_COUNT_SUB_STATUS.error &&
    SESSION_COUNT_SUB_STATUS.data &&
    SESSION_COUNT_SUB_STATUS.data
      .db_main_indigo_live_active_sessions_aggregate &&
    SESSION_COUNT_SUB_STATUS.data.db_main_indigo_live_active_sessions_aggregate
      .aggregate &&
    SESSION_COUNT_SUB_STATUS.data.db_main_indigo_live_active_sessions_aggregate
      .aggregate.count
      ? SESSION_COUNT_SUB_STATUS.data
          .db_main_indigo_live_active_sessions_aggregate.aggregate.count - 1
      : null;

  if (
    !SESSION_SUB_STATUS.loading &&
    !SESSION_SUB_STATUS.error &&
    SESSION_SUB_STATUS.data &&
    SESSION_SUB_STATUS.data.db_main_indigo_live_active_sessions &&
    SESSION_SUB_STATUS.data.db_main_indigo_live_active_sessions.length > 0 &&
    updateSession
  ) {
    if (
      !commentUserColor ||
      commentUserColor !==
        SESSION_SUB_STATUS.data.db_main_indigo_live_active_sessions[0].color
    )
      setCommentUserColor(
        SESSION_SUB_STATUS.data.db_main_indigo_live_active_sessions[0].color
      );
    if (
      !SESSION_SUB_STATUS.data.db_main_indigo_live_active_sessions[0].active
    ) {
      updateActiveSession(auth, localSession).then(result => {
        if (result && result.content && result.content.id)
          setSessionId(result.content.id);
      });
    }
  }

  const ROOM_SUBSCRIPTION = gql`
    subscription roomSub {
      db_main_indigo_live_rooms(limit: 1, where: {room_id: {_eq: "${auth.room_id}"}}) {
        limit
        start_time
        duration
        vidyard_uuid
        detail
        description
        webinar_number
        private_chat
        video_type
        mux_asset_id
        external_link
        is_pre_recorded
        feedback_formid 
        chat_tab
      }
    }
  `;
  const quizFormId = roomData
    ? roomData.live_quiz_formid
      ? roomData.live_quiz_formid
      : '-1'
    : '-1';
  const QUIZ_SUBSCRIPTION = gql`
    subscription quizSub {
      db_ninja_quiz_live_session(where: {form_id: {_eq: "${quizFormId}"}, _and: {is_active: {_in: true}}}, limit: 1) {
        id
        session_question_id
        is_active
        form_id
        started_at
        updated_at
      }
    }
  `;
  const setSelectedTabIndex = newTabIndex => {
    setSelectedTabIndexState(newTabIndex);
  };
  useEffect(() => {
    if (typeof roomStatus.forceTabIndex == 'number') {
      if (roomStatus.forceTabIndex != selectedTabIndex) {
        setSelectedTabIndex(roomStatus.forceTabIndex);
      }
      setForceTabIndex(null);
    }
  }, [roomStatus.forceTabIndex, selectedTabIndex, setForceTabIndex]);
  const { data, loading, error } = useSubscription(ROOM_SUBSCRIPTION);
  const QaTabPabel = qaTabActive && (
    <TabPanel
      value={selectedTabIndex}
      index={1}
      maxHeight={`${height - 160}px`}
      minHeight={`${height - 160}px`}
    >
      <QaTab
        onSettingsClick={() => {
          setCredentialsSet(false);
          //setDialogOpen(true);
        }}
        chatDisabled={!chatStarted}
        loading={loading}
        error={error}
        drawerWidth={drawerWidth}
        videoDidEnd={videoDidEnd}
      />
    </TabPanel>
  );
  if (
    !loading &&
    !error &&
    data &&
    data.db_main_indigo_live_rooms &&
    data.db_main_indigo_live_rooms.length > 0 &&
    (!roomStatus.roomFound ||
      (!roomStatus.vidyard_uuid &&
        data.db_main_indigo_live_rooms[0].vidyard_uuid) ||
      roomStatus.vidyard_uuid != data.db_main_indigo_live_rooms[0].vidyard_uuid)
  ) {
    setRoomData(data.db_main_indigo_live_rooms[0]);
  }
  const quizResult = useSubscription(QUIZ_SUBSCRIPTION);
  if (
    !quizResult.loading &&
    !quizResult.error &&
    quizResult.data &&
    quizResult.data.db_ninja_quiz_live_session &&
    quizResult.data.db_ninja_quiz_live_session.length > 0 &&
    ((!roomStatus.quizStatus &&
      quizResult.data.db_ninja_quiz_live_session[0]) ||
      (roomStatus.quizStatus &&
        roomStatus.quizStatus.form_id !=
          quizResult.data.db_ninja_quiz_live_session[0].form_id) ||
      (roomStatus.quizStatus &&
        roomStatus.quizStatus.is_active !=
          quizResult.data.db_ninja_quiz_live_session[0].is_active) ||
      (roomStatus.quizStatus &&
        roomStatus.quizStatus.session_question_id !=
          quizResult.data.db_ninja_quiz_live_session[0].session_question_id) ||
      (roomStatus.quizStatus &&
        roomStatus.quizStatus.started_at !=
          quizResult.data.db_ninja_quiz_live_session[0].started_at))
  ) {
    if (
      roomStatus.quizStatus &&
      roomStatus.quizStatus.started_at !=
        quizResult.data.db_ninja_quiz_live_session[0].started_at &&
      !forceReloadQuiz
    )
      setForceReloadQuiz(true);
    if (
      typeof roomStatus.forceTabIndex != 'number' &&
      quizResult.data.db_ninja_quiz_live_session[0] &&
      quizResult.data.db_ninja_quiz_live_session[0].is_active
    ) {
      if (!videoNotificationMessage)
        setVideoNotificationMessage('New Quiz Question');
    }
    setQuizStatus(quizResult.data.db_ninja_quiz_live_session[0]);
  }

  useEffect(() => {
    if (videoNotificationMessage) {
      if (!roomStatus.chatDrawerOpen && width > 800) setDrawerOpen(true);
      if (roomStatus.forceTabIndex != quizTabIndex)
        setForceTabIndex(quizTabIndex);
      setVideoNotificationMessage(null);
    }
  }, [
    quizTabIndex,
    roomStatus.chatDrawerOpen,
    roomStatus.forceTabIndex,
    setDrawerOpen,
    setForceTabIndex,
    videoNotificationMessage,
    width
  ]);

  const nextRegisterLink = getNextWebcastsRegisterLink(
    auth.region,
    roomStatus.room_detail,
    roomStatus.wave
  );
  useEffect(() => {
    setForceReloadQuiz(false);
  }, [forceReloadQuiz]);

  const PROOMS_SUB = gql`
      subscription privateRoomsSub {
        db_main_indigo_live_private_chat_rooms(where: {
            _and: [
                {deleted_at: {_is_null: true}, live_room_id: {_eq: "${auth.room_id}"}},
                {_or:[ 
                  {participant_session_id: {_eq: "${auth.session_id}"}},
                  {creator_session_id: {_eq: "${auth.session_id}"}}		
                ]}
            ]
          }
        ) {
          creator_session_id
          live_room_id
          participant_session_id
          room_id
          indigoLiveActiveSessionByCreatorSessionId {
            color
            company_name
            country_code
            display_name
            email
            id
            title
            phone_number
            active
          }
          indigo_live_active_session {
            color
            company_name
            country_code
            display_name
            email
            id
            title
            phone_number
            active
          }
          indigo_live_private_chats_aggregate(where: {deleted_at: {_is_null: true}}) {
            aggregate {
              count
            }
          }
        }
      }
  `;
  const PROOMS_RES = useSubscription(PROOMS_SUB);
  const scrollDown = () => {
    if (scrollDownRef && scrollDownRef.current) {
      scrollDownRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };
  const scrollUp = () => {
    if (scrollUpRef && scrollUpRef.current) {
      scrollUpRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };
  useEffect(() => {
    if (width > 800) return;
    if (scrolledDown) {
      setMobileQuizButton(false);
      setQuizIframeHeight(`${height - 50}px`);
    } else {
      setMobileQuizButton(true);
      setQuizIframeHeight(`${height - 340}px`);
    }
  }, [height, scrolledDown, width]);
  //start rendering
  const mobileCommentSection =
    width > 800 ? null : loading ? (
      <Loader />
    ) : error ? (
      <div>Error</div>
    ) : !roomStatus.roomFound ? (
      <div>Cannot be found</div>
    ) : (
      <div
        style={{
          marginTop: '10px',
          marginBottom: '10px',
          backgroundColor: 'white'
        }}
      >
        <Tabs
          value={selectedTabIndex}
          centered
          indicatorColor="primary"
          onChange={(event, newIndex) => {
            setSelectedTabIndex(newIndex);
          }}
          variant="fullWidth"
        >
          {allowChat && (
            <Tab label="Chat" id={`chat-tab-${0}`} style={{ fontSize: 16 }} />
          )}
          {QaTabComp}
          {roomData.live_quiz_formid && !forceReloadQuiz && (
            <Tab
              label="Quiz"
              id={`chat-tab-${quizTabIndex}`}
              style={{ fontSize: 16 }}
            />
          )}
        </Tabs>
        {allowChat && (
          <TabPanel
            value={selectedTabIndex}
            index={0}
            maxHeight={`${height - 160}px`}
            minHeight={`${height - 160}px`}
          >
            <LiveChatTab
              onSettingsClick={() => {
                setCredentialsSet(false);
                //setDialogOpen(true);
              }}
              chatDisabled={!chatStarted}
              loading={loading}
              error={error}
              drawerWidth={drawerWidth}
              videoDidEnd={videoDidEnd}
              isMobile={true}
            />
          </TabPanel>
        )}

        {qaTabActive && (
          <TabPanel
            value={selectedTabIndex}
            index={1}
            maxHeight={`${height - 160}px`}
            minHeight={`${height - 160}px`}
          >
            <QaTab
              onSettingsClick={() => {
                setCredentialsSet(false);
                //setDialogOpen(true);
              }}
              chatDisabled={!chatStarted}
              loading={loading}
              error={error}
              drawerWidth={drawerWidth}
              videoDidEnd={videoDidEnd}
              isMobile={true}
            />
          </TabPanel>
        )}
        {roomData.live_quiz_formid && !forceReloadQuiz && (
          <TabPanel
            value={selectedTabIndex}
            index={quizTabIndex}
            maxHeight={`${height - 160}px`}
            minHeight={`${height - 160}px`}
          >
            <QuizTab roomData={roomData} iframeHeight={quizIframeHeight} />
            <div
              ref={scrollDownRef}
              style={{
                position: 'absolute',
                backgroundColor: 'white',
                top: `${height + 260}px`,
                padding: '5px',
                borderRadius: '5px',
                right: '5px'
              }}
              onClick={() => {
                scrollUp();
              }}
            >
              <VisibilitySensor
                onChange={visible => {
                  if (visible) {
                    setScrolledDown(true);
                  } else setScrolledDown(false);
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography style={{ fontSize: '10px' }}>
                    Back to video
                  </Typography>
                  <EjectIcon />
                </div>
              </VisibilitySensor>
            </div>
          </TabPanel>
        )}
      </div>
    );
  const peopleTabComp = peopleOpen && (
    <PeopleTab
      onSettingsClick={() => {
        setCredentialsSet(false);
        //setDialogOpen(true);
      }}
      onConversationStart={(session, roomId) => {
        setSelectedOtherPrivateSession(session);
        setSelectedPivateRoomId(roomId);
        setPeopleAnchorEl(null);
      }}
      maxHeight={`${height - 160}px`}
      chatDisabled={!chatStarted}
      loading={loading}
      error={error}
      drawerWidth={drawerWidth}
      videoDidEnd={videoDidEnd}
      roomId={roomId}
      pRoomsRes={PROOMS_RES}
    />
  );

  const videoRender = loading ? (
    <Loader style={{ height: `${height * 0.5}px` }} />
  ) : error ? (
    <div>Error</div>
  ) : !roomStatus.roomFound ? (
    <div>Cannot be found</div>
  ) : !videoStarted ? (
    <div></div>
  ) : roomStatus.vidyard_uuid && roomStatus.video_type == 'mux' ? (
    <VideoPlayer
      isLive={true}
      src={`https://stream.mux.com/${roomStatus.vidyard_uuid}.m3u8`}
      // placeholderSrc="https://stream.mux.com/g02U02LFEgSTEyrmlq02OeiayLwAHGgahAV6JrfNoPRYGI.m3u8"
      isPreRecorded={roomStatus.is_pre_recorded}
      defaultSubtitles={auth.language}
      drawerOpen={roomStatus.chatDrawerOpen}
      isMobile={width <= 800}
      displayNotification={videoNotificationMessage}
      onVideoEnd={() => {
        setEndingDialogOpen(true);
      }}
    />
  ) : roomStatus.video_type == 'embed' ? (
    <div
      style={{
        paddingBottom: '56.25%',
        position: 'relative'
      }}
    >
      <iframe
        src={embedLink}
        width="100%"
        height="100%"
        frameborder="0"
        scrolling="auto"
        title="HP-MAIN"
        style={{ position: 'absolute' }}
        allowfullscreen
      ></iframe>
    </div>
  ) : null;
  if (roomStatus && roomStatus.external_link) {
    window.location.href = roomStatus.external_link;
    return null;
  }

  return (
    <div className={classes.root}>
      <div
        position="fixed"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '64px',
          width: '100%',
          backgroundColor: 'transparent',
          padding: '0px'
        }}
      >
        {/* <img src={titeLogo} width="90px" style={{ marginTop: '20px' }} /> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // width: '100%',
            marginTop: '10px'
            // alignItems: 'center'
          }}
        >
          {roomData && roomData.style && roomData.style.logo ? (
            <img
              src={roomData.style.logo}
              style={{ marginLeft: '10px',height:"50px",width:"auto" }}
              ref={scrollUpRef}
            />
          ) : (
            <div />
          )}
          <Logo url={roomData.style ? roomData.style.logo2 : null} />

          {mobileQuizButton && selectedTabIndex == quizTabIndex && (
            <Button
              style={{
                padding: '10px',
                borderRadius: '5px',
                marginRight: '5px',
                color: 'white',
                position: 'absolute',
                right: '0',
                bottom: '10px',
                background:
                  'linear-gradient(141.96deg, #CEA615 -0.54%, #F4D258 63.75%, #DABF61 99.95%)'
              }}
              onClick={() => {
                scrollDown();
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    fontSize: '10px'
                  }}
                >
                  Expand
                </Typography>
                <FullscreenIcon />
              </div>
            </Button>
          )}
        </div>

        {width > 800 ? (
          <Tooltip title="Live chat">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                if (allowChat) setDrawerOpen(true);
              }}
              edge="start"
              className={clsx(
                classes.menuButton,
                roomStatus.chatDrawerOpen && classes.hide
              )}
            >
              <Badge
                badgeContent={
                  roomStatus.unreadCommentsCount > 0
                    ? roomStatus.unreadCommentsCount.toString()
                    : null
                }
                color="secondary"
              >
                <img
                  src={drawerOpenIcon}
                  width="20px"
                  style={{ marginRight: '20px' }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        ) : (
          <div />
        )}
      </div>

      <Popover
        id={'peopleTab'}
        open={peopleOpen}
        anchorEl={peopleAnchorEl}
        onClose={() => {
          setPeopleAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {peopleTabComp}
      </Popover>

      <EndingDialog
        width={width}
        open={endingDialogOpen}
        onClose={() => {
          setEndingDialogOpen(false);
        }}
        onConfirm={() => {}}
      />

      <WebviewDialog
        width={width}
        open={registerDialogOpen}
        onClose={() => {
          setRegisterDialogOpen(false);
        }}
        url={nextRegisterLink}
        title="Register for upcoming webcasts"
      />

      <ChatSettingsDialog
        width={width}
        open={dialogOpen}
        onClose={() => {
          setEmail(auth.email);
          setDisplayName(auth.display_name);
          setCompanyName(auth.company_name);
          setCredentialsSet(true);
          setDialogOpen(false);
        }}
        onConfirm={onChatSettingsConfirm}
      />
      {allowChat && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={roomStatus.chatDrawerOpen}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div
            className={classes.drawerHeader}
            style={{ maxHeight: '100px', minHeight: '100px' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Tooltip title="Collapse">
                <IconButton
                  onClick={() => {
                    setDrawerOpen(false);
                  }}
                >
                  <img src={drawerCollapseIcon} width="20px" />
                </IconButton>
              </Tooltip>

              <img src={liveChatTitle} width="120" />
            </div>

            {activeCount ? (
              <Typography
                style={{
                  fontSize: 12,
                  marginTop: '10px',
                  marginRight: '15px',
                  fontWeight: 'bold',
                  cursor: 'pointer'
                }}
                onClick={event => {
                  setPeopleAnchorEl(event.currentTarget);
                }}
              >
                {activeCount} friends live
              </Typography>
            ) : null}
          </div>

          <Tabs
            value={selectedTabIndex}
            centered
            indicatorColor="primary"
            onChange={(event, newIndex) => {
              setSelectedTabIndex(newIndex);
            }}
            variant="fullWidth"
          >
            {allowChat && (
              <Tab label="Chat" id={`chat-tab-${0}`} style={{ fontSize: 16 }} />
            )}

            {QaTabComp}
            {roomData.live_quiz_formid && !forceReloadQuiz && (
              <Tab
                label="Quiz"
                id={`chat-tab-${quizTabIndex}`}
                style={{ fontSize: 16 }}
              />
            )}
          </Tabs>
          {allowChat && (
            <TabPanel
              value={selectedTabIndex}
              index={0}
              maxHeight={`${height - 160}px`}
              minHeight={`${height - 160}px`}
            >
              <LiveChatTab
                onSettingsClick={() => {
                  setCredentialsSet(false);
                  //setDialogOpen(true);
                }}
                selectedOtherPrivateSession={selectedOtherPrivateSession}
                setSelectedOtherPrivateSession={setSelectedOtherPrivateSession}
                selectedPivateRoomId={selectedPivateRoomId}
                setSelectedPivateRoomId={setSelectedPivateRoomId}
                chatDisabled={!chatStarted}
                loading={loading}
                error={error}
                drawerWidth={drawerWidth}
                videoDidEnd={videoDidEnd}
              />
            </TabPanel>
          )}

          {QaTabPabel}
          {roomData.live_quiz_formid && !forceReloadQuiz && (
            <TabPanel
              value={selectedTabIndex}
              index={quizTabIndex}
              maxHeight={`${height - 160}px`}
              minHeight={`${height - 160}px`}
            >
              <QuizTab roomData={roomData} iframeHeight={quizIframeHeight} />
            </TabPanel>
          )}
        </Drawer>
      )}

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: roomStatus.chatDrawerOpen
        })}
        style={
          !roomStatus.chatDrawerOpen && width > 800
            ? {
                // width: `${width * 0.8}px`,
                marginRight: `${height > 1000 ? 70 : 200}px`,
                marginLeft: `${height > 1000 ? 70 : 200}px`
              }
            : null
        }
      >
        {videoDidEnd ? (
          <div
            style={{
              display: 'flex',
              // height: '60vh',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '25px'
            }}
          >
            <div
              style={{
                color: 'white',
                fontFamily: 'Roboto',
                fontSize: width > 768 ? 26 : 18,
                textAlign: 'center'
              }}
            >
              Thank you for watching <br />
              {getNextWebcastsData(
                roomStatus.region,
                roomStatus.room_detail,
                roomStatus.wave
              ) &&
              getNextWebcastsData(
                roomStatus.region,
                roomStatus.room_detail,
                roomStatus.wave
              ).length > 0 ? (
                getNextWebcastsData(
                  roomStatus.region,
                  roomStatus.room_detail,
                  roomStatus.wave
                ).length > 1 ? (
                  <div style={{ marginBottom: width > 768 ? 40 : 0 }}>
                    See you in our next HP Indigo Live Webcasts <br />
                  </div>
                ) : (
                  <div style={{ marginBottom: width > 768 ? 40 : 0 }}>
                    See you in our next HP Indigo Live Webcast <br />
                  </div>
                )
              ) : null}
              {getNextWebcastsData(
                roomStatus.region,
                roomStatus.room_detail,
                roomStatus.wave
              )
                ? getNextWebcastsData(
                    roomStatus.region,
                    roomStatus.room_detail,
                    roomStatus.wave
                  ).map(data => (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setRegisterDialogOpen(true);
                      }}
                    >
                      <WebcastCard
                        webcastDate={data.date}
                        webcastName={data.name}
                        webcastTime={data.time}
                        webcastTitle={data.title}
                      />
                    </div>
                  ))
                : null}
              {getNextWebcastsData(
                roomStatus.region,
                roomStatus.room_detail,
                roomStatus.wave
              ) &&
              getNextWebcastsData(
                roomStatus.region,
                roomStatus.room_detail,
                roomStatus.wave
              ).length > 0 &&
              nextRegisterLink ? (
                <div
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderRadius: '10px',
                      border: '2px solid white',
                      width: '200px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setRegisterDialogOpen(true);
                    }}
                  >
                    <div
                      style={{
                        textDecoration: 'none',
                        fontSize: 16,
                        color: 'white',
                        fontWeight: 'bold',
                        padding: '15px 10px'
                      }}
                    >
                      Register Here{' '}
                    </div>
                    <ArrowForwardIcon />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {videoStarted || !TIMER_DATE ? null : (
          <div
            style={{
              display: 'flex',
              height: '80vh',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <CountDown
              title={roomData.title}
              timeTillDate={TIMER_DATE}
              timeFormat="MM DD YYYY, h:mm a"
            />
            <div style={{ direction: 'rtl', color: 'white', fontSize: '18px' }}>
              עם תחילת השידורים, בבקשה ליחצו על Unmute בווידאו
            </div>
          </div>
        )}
        <br />
        {videoDidEnd ? null : videoRender}
        {mobileCommentSection}
      </main>
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus,
    localSession: state.localSession
  }),
  dispatch =>
    bindActionCreators(
      {
        setSessionId: sessionId => setSessionId(sessionId),
        setDisplayName: displayName => setDisplayName(displayName),
        setEmail: email => setEmail(email),
        setRoomData: data => setRoomData(data),
        setRoomClosed: () => setRoomClosed(),
        setDrawerOpen: open => setDrawerOpen(open),
        setCompanyName: companyName => setCompanyName(companyName),
        setCountryCode: countryCode => setCountryCode(countryCode),
        setLanguage: language => setLanguage(language),
        setTitle: title => setTitle(title),
        setPhoneNumber: phoneNumber => setPhoneNumber(phoneNumber),
        setOrgType: orgType => setOrgType(orgType),
        setForceTabIndex,
        setQuizStatus,
        setQaCount,
        setQaNotifyCount
      },
      dispatch
    )
)(ChatRoom);
