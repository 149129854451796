import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import hpLogo from '../../assets/hp_logo.png';
import Flag from 'react-world-flags';

const styles = theme => ({});

const UserSession = props => {
  const { session, auth, isLarge } = props;
  if (!session) return <div></div>;
  var mailtoLink = 'mailto:' + session.email;
  var telLink = 'tel:' + session.phone_number;
  return (
    <div style={props.style}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'center',
          minWidth: '200px',
          // maxWidth:"400px",
          marginLeft: '20px',
          marginBottom: '20px',
          marginRight: '50px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10,
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Typography
              style={{
                color: session.color,
                fontSize: isLarge ? 16 : 12,
                fontWeight: 'bold'
              }}
            >
              {session.display_name}
            </Typography>
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: session.active
                  ? 'rgba(125, 194, 66, 0.7)'
                  : 'rgba(197, 197, 197, 0.7)',
                borderRadius: '5px',
                marginLeft: '2px'
              }}
            ></div>
          </div>

          <Typography
            style={{
              fontSize: isLarge ? 14 : 12,
              color: 'grey'
            }}
          >
            {session.email && session.email.includes('@hp.com') ? (
              <img src={hpLogo} height={isLarge ? '24px' : '16px'} />
            ) : (
              session.company_name
            )}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10,
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start'
            }}
          >
            <Typography
              style={{
                color: 'grey',
                fontSize: isLarge ? 14 : 12,
                // margin: '0px 10px 0px 5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center'
              }}
            >
              {session.title ? session.title : null}
            </Typography>

            {auth.email && auth.email.includes('@hp.com') ? (
              <div>
                <a href={mailtoLink} style={{ textDecoration: 'none' }}>
                  <Typography
                    style={{
                      color: '#008bbf',
                      fontSize: isLarge ? 14 : 12,
                      fontWeight: 'bold'
                    }}
                  >
                    {session.email}
                  </Typography>
                </a>

                <a href={telLink} style={{ textDecoration: 'none' }}>
                  <Typography
                    style={{
                      color: '#008bbf',
                      fontSize: isLarge ? 14 : 12,
                      fontWeight: 'bold'
                    }}
                  >
                    {session.phone_number}
                  </Typography>
                </a>
              </div>
            ) : null}
          </div>

          <Typography
            style={{
              fontSize: isLarge ? 14 : 12,
              color: 'grey'
            }}
          >
            {session.country_code ? (
              <Flag
                style={{
                  marginRight: '3px',
                  width: '20px',
                  height: '20px'
                }}
                code={session.country_code}
              />
            ) : null}
          </Typography>
        </div>
      </div>
    </div>
  );
};

UserSession.propTypes = {
  session: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    color: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.any,
    company_name: PropTypes.string,
    title: PropTypes.string
  })
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(withStyles(styles)(UserSession));
