import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useWindowDimensions from '../../hooks/windowDimensions';
import PropTypes from 'prop-types';
import background from '../../assets/live_background.png';
import { Typography, Button, Card } from '@material-ui/core';
import { timeToLocalString } from '../../utils/TimeUtils';
import EventIcon from '@material-ui/icons/Event';

const RoomCard = props => {
  const { height, width } = useWindowDimensions();
  const { auth, roomStatus, room, applyMargin } = props;

  useEffect(() => {}, []);

  const getResponsiveWidth = function() {
    var res = (width - 200) / 3.3;
    if (width <= 800) res = width - 50;
    if (res > 500) res = 500;
    return res;
  };

  const isMobile = width <= 800;

  if (!room) return null;
  return (
    <Card
      {...props}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        width: getResponsiveWidth(),
        marginRight: applyMargin,
        marginBottom: '20px'
      }}
    >
      <img
        src={background}
        style={{
          width: getResponsiveWidth(),
          height: getResponsiveWidth() * 0.4
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          onClick={() => {
            if (!room.occured) {
              window.open('https://usestrive.typeform.com/to/BNYLy9', '_blank');
            } else if (room.occured && room.recording_url) {
              window.open(`${room.recording_url}`, '_blank');
            }
          }}
          style={{
            fontSize: isMobile ? 12 : 16,
            fontWeight: 600,
            color: '#1a73e8',
            cursor: 'pointer',
            marginTop: '20px',
            font:
              '400 24px/32px Google Sans,Noto Sans,Noto Sans JP,Noto Sans KR,Noto Naskh Arabic,Noto Sans Thai,Noto Sans Hebrew,Noto Sans Bengali,sans-serif',
            marginLeft: '20px'
          }}
        >
          {room.title}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '15px',
            marginLeft: '20px'
          }}
        >
          <EventIcon style={{ color: '#757575' }} />
          <Typography>
            {timeToLocalString(
              room.start_time,
              room.occured ? 'MMMM d' : 'MMM d - hh:mm a'
            )}
          </Typography>
          <Typography style={{ marginLeft: '25px' }}>{room.regions}</Typography>
        </div>
        <br />

        {!room.occured && (
          <Button
            style={{ width: '50px', color: '#1a73e8', marginLeft: '14px' }}
            color="primary"
            onClick={() => {
              window.open('https://usestrive.typeform.com/to/BNYLy9', '_blank');
            }}
          >
            Register
          </Button>
        )}

        {room.occured && room.recording_url && (
          <Button
            style={{ width: '50px', color: '#1a73e8', marginLeft: '14px' }}
            color="primary"
            onClick={() => {
              window.open(`${room.recording_url}`, '_blank');
            }}
          >
            Recording
          </Button>
        )}
        <br />
      </div>
    </Card>
  );
};

RoomCard.propTypes = {
  room: PropTypes.any.isRequired
};

export default connect(state => ({
  auth: state.auth,
  roomStatus: state.roomStatus
}))(RoomCard);
