import React, { useEffect, useRef } from 'react';
import { LinearProgress, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import CountDown from '../countDown/CountDown';
import { getVideoSubtitles } from '../../api/rooms';
import {
  videoStartingIn,
  getVideoStartTimeLocal,
  videoEndingIn
} from '../../utils/TimeUtils';
import { connect } from 'react-redux';
import Loader from '../loader/Loader';
import { bindActionCreators } from 'redux';
import { isAndroid, isIOS } from 'react-device-detect';
import { setRoomSubtitles } from '../../store/actions/roomStatus';
import useSound from 'use-sound';
import soundEffect from '../../assets/pristine-609.mp3';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import useWindowDimensions from '../../hooks/windowDimensions';

// import { setVideoDidEnd } from '../../store/actions/roomStatus';
const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#877f94'
  },
  barColorPrimary: {
    backgroundColor: '#cfb9e4'
  }
})(LinearProgress);
let player;
const VideoPlayer = props => {
  const {
    src,
    isLive,
    placeholderSrc,
    roomStatus,
    onVideoEnd,
    auth,
    autoPlay,
    isPreRecorded,
    setRoomSubtitles,
    displayNotification,
    drawerOpen,
    isMobile
  } = props;
  const TIMER_DATE = getVideoStartTimeLocal(roomStatus.start_time, 5);
  const playerRef = useRef();
  const [startedLive, setStartedLive] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [subtitles, setSubtitles] = React.useState(null);
  const [loadedPlayer, setLoadedPlayer] = React.useState(null);
  const [selectedSubtitles, setSelectedSubtitles] = React.useState(null);
  const [videoPlaying, setVideoPlaying] = React.useState(false);
  const [videoDidEnd, setVideoDidEnd] = React.useState(false);
  const [showUnmute, setShowUnmute] = React.useState(isLive ? true : autoPlay);
  const [notificationMesssage, setNotificationMessage] = React.useState(null);
  const { height } = useWindowDimensions();

  const [playSound] = useSound(soundEffect);

  useEffect(() => {
    if (videoDidEnd && isLive) return;
    if (displayNotification) {
      var currVolume;
      if (player) {
        currVolume = player.volume();
        player.volume(0.2);
      }
      playSound();
      if (!isMobile) {
        setNotificationMessage(displayNotification);
        // setTimeout(() => {
        //   setNotificationMessage(`${displayNotification} in 2`);
        // }, 1000);
        // setTimeout(() => {
        //   setNotificationMessage(`${displayNotification} in 1`);
        // }, 2000);
      }
      setTimeout(() => {
        if (player && currVolume) {
          player.volume(currVolume * 0.2);
          setTimeout(() => {
            player.volume(currVolume * 0.4);
          }, 200);
          setTimeout(() => {
            player.volume(currVolume * 0.6);
          }, 500);
          setTimeout(() => {
            player.volume(currVolume * 0.8);
          }, 700);
          setTimeout(() => {
            player.volume(currVolume);
          }, 1000);
        }
        if (!isMobile) setNotificationMessage(null);
      }, 3000);
    }
  }, [displayNotification]);

  const changePlayerSubtitles = function(languageCode) {
    var startingInSecs = videoStartingIn(roomStatus.start_time);
    // if (startingInSecs > -150) {
    //   startingInSecs = 150 + startingInSecs;
    // }
    if (placeholderSrc && startingInSecs > 0) return;
    if (!loadedPlayer) return;
    let tracks = loadedPlayer.textTracks();
    if (!tracks || tracks.length == 0) return;
    if ((loadedPlayer && loadedPlayer.paused()) || !videoPlaying) return;
    if (languageCode == selectedSubtitles) return;
    for (let i = 0; i < tracks.length; i++) {
      let track = tracks[i];
      if (track.language === languageCode) {
        track.mode = 'showing';
      } else {
        track.mode = 'hidden';
      }
    }
    setSelectedSubtitles(languageCode);
  };

  useEffect(() => {
    //TO INJECT / GET HEADERS
    videojs.Hls.xhr.beforeRequest = function(options) {
      if (options.uri.startsWith('https://stream.mux.com')) return options;
      // options.uri = options.uri + '&beta_serve_pdt=true';
      return options;
    };
  }, []);
  useEffect(() => {
    if (player) {
      player.src(src);
    }
  }, [src]);

  useEffect(() => {
    if (loading) {
      getVideoSubtitles(roomStatus.mux_asset_id)
        .then(subtitles => {
          setRoomSubtitles(subtitles);
          setSubtitles(subtitles);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
      return;
    }
    const videoEndingSecs = videoEndingIn(
      roomStatus.start_time,
      roomStatus.duration
    );
    if (videoEndingSecs !== null && !isNaN(videoEndingSecs)) {
      //disposing video
      if (videoEndingSecs + 345 < 5000) {
        setTimeout(() => {
          if (loadedPlayer) loadedPlayer.dispose();
          setVideoDidEnd(true);
        }, (videoEndingSecs + 340) * 1000);
      }
    }
    var startingInSecs = videoStartingIn(roomStatus.start_time);
    // if (startingInSecs > -150) {
    //   startingInSecs = 150 + startingInSecs;
    // }
    if (isLive) {
      require('./styles.css');
    }
    player = videojs(
      playerRef.current,
      {
        autoplay: isLive ? true : autoPlay,
        muted: isLive ? true : autoPlay,
        loop: placeholderSrc,
        controls: true,
        html5: {
          hls: {
            overrideNative: isIOS || isAndroid ? true : false
          }
        }
      },
      () => {
        if (startingInSecs <= 0 || !placeholderSrc) {
          if (!startedLive) setStartedLive(true);
        }
        player.on('playing', () => {
          var startingInSecs = videoStartingIn(roomStatus.start_time);
          // if (startingInSecs > -150) {
          //   startingInSecs = 150 + startingInSecs;
          // }
          if (placeholderSrc && startingInSecs > 0) return;
          if (!videoPlaying) setVideoPlaying(true);
        });
        player.on('waiting', () => {
          if (videoPlaying) setVideoPlaying(false);
        });
        player.src(placeholderSrc && startingInSecs > 0 ? placeholderSrc : src);
        setLoadedPlayer(player);
        if (isLive || autoPlay) player.play();
      }
    );
    if (placeholderSrc) {
      setTimeout(() => {
        player.src(src);
        player.loop(false);
        setStartedLive(true);
        setVideoPlaying(false);
        player.on('ended', function() {
          setVideoDidEnd(true);
          if (onVideoEnd) onVideoEnd();
        });
        setTimeout(() => {
          changePlayerSubtitles(auth.language);
        }, 1000);
      }, startingInSecs * 1000);
    }
    if (isLive) {
      player.controlBar.progressControl.disable();
      player.controlBar.playToggle.disable();
      if (!isPreRecorded) return;
      if (startingInSecs < 0) {
        const startTime = (startingInSecs + 0) * -1; //+150
        player.currentTime(startTime);
        player.on('timeupdate', () => {
          if (!isNaN(player.duration()) && startTime >= player.duration()) {
            setVideoDidEnd(true);
            player.dispose();
            return;
          }
          if (player.currentTime() < startTime) {
            player.currentTime(startTime);
          }
        });
      } else if (startingInSecs > 0 && placeholderSrc) {
        player.currentTime(450 + (0 - startingInSecs)); //150-
      }
    }
    if (!placeholderSrc || startingInSecs <= 0) {
      player.on('ended', function() {
        setVideoDidEnd(true);
        if (onVideoEnd) onVideoEnd();
      });
    }
    return () => {
      player.dispose();
    };
  }, [loading]);
  if (loading) return <Loader />;
  changePlayerSubtitles(auth.language);

  return videoDidEnd && isLive ? (
    <div
      style={{
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 24,
        textAlign: 'center'
      }}
    >
      Thank you for watching
    </div>
  ) : (
    <div>
      <div data-vjs-player>
        <video ref={playerRef} className="video-js vjs-16-9" playsInline>
          {startedLive &&
            subtitles &&
            subtitles
              .filter(st => st.language_code == 'en')
              .map((st, i) => (
                <track
                  key={`${i}`}
                  kind="captions"
                  src={`https://stream.mux.com/${roomStatus.vidyard_uuid}/text/${st.id}.vtt`}
                  label={`${st.name}`}
                />
              ))}
        </video>
      </div>
      {showUnmute && player && player.muted() && (
        <div
          style={{
            position: 'absolute',
            top: '85px',
            left: isMobile
              ? '5px'
              : drawerOpen
              ? '475px'
              : height < 1000
              ? '215px'
              : '75px', //drawerClose? 75px , mobile? 5 px
            padding: '15px',
            borderRadius: '5px',
            color: 'white',
            border: '1px solid white',
            background: 'black',
            cursor: 'pointer',
            display: 'flex'
          }}
          onClick={() => {
            if (showUnmute) setShowUnmute(false);
            if (player) player.muted(false);
          }}
        >
          <VolumeOffIcon />
          <Typography
            style={{ fontSize: isMobile ? '12px' : '16px', fontWeight: 'bold' }}
          >
            Unmute
          </Typography>
        </div>
      )}
      {notificationMesssage && (
        <div
          style={{
            position: 'absolute',
            top: '85px',
            right: isMobile ? '5px' : drawerOpen ? '475px' : '75px', //drawerClose? 75px , mobile? 5 px
            padding: '10px',
            borderRadius: '5px',
            color: 'white',
            background:
              'linear-gradient(141.96deg, #CEA615 -0.54%, #F4D258 63.75%, #DABF61 99.95%)'
          }}
        >
          <Typography
            style={{ fontSize: isMobile ? '12px' : '16px', fontWeight: 'bold' }}
          >
            {notificationMesssage}
          </Typography>
          {/* <ColorLinearProgress variant="determinate" /> */}
        </div>
      )}
    </div>
  );
};

VideoPlayer.propTypes = {
  src: PropTypes.string,
  isLive: PropTypes.bool,
  placeholderSrc: PropTypes.bool,
  onVideoEnd: PropTypes.func,
  defaultSubtitles: PropTypes.string
};

VideoPlayer.defaultProps = {
  isLive: true
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({ setRoomSubtitles }, dispatch)
)(VideoPlayer);
