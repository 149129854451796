import background from '../../assets/live_background.png';
import { makeStyles } from '@material-ui/core';
const drawerWidth = 450;
const useStyles = makeStyles(theme => ({
  menuButton: {
    marginLeft: 'auto'
  },
  hide: {
    display: 'none'
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'space-between'
  },
  content: {},
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth + 20,
    marginLeft: '20px'
  },
  root: {
    // backgroundImage: `url(https://storage.googleapis.com/indigo_live/hpnews2021/web-4096x2304.png)`,
    background: props => props.background,
    backgroundPosition: 'start',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    // background:
    //   'linear-gradient(208.37deg, #1323DD 0%, #080E59 36.36%, #000000 72.19%, #000000 101.17%)',
    minHeight: '100vh'
  }
}));
export default useStyles;
