import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useSubscription, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import UserSession from '../userSession/UserSession';
import { Button } from '@material-ui/core';

const PeopleTab = props => {
  const {
    roomStatus,
    auth,
    maxHeight,
    pRoomsRes,
    roomId,
    onConversationStart
  } = props;

  const CREATE_ROOM = gql`
    mutation createNewPrivateRoom  ( $pid: bigint!){
      insert_db_main_indigo_live_private_chat_rooms(objects: {creator_session_id: "${auth.session_id}", participant_session_id: $pid, live_room_id: "${auth.room_id}"}) {
        affected_rows
        returning {
          room_id
        }
      }
    }
  `;
  const [createRoom] = useMutation(CREATE_ROOM);

  const ROOM_SESSIONS_SUB = gql`
  subscription allRoomSessionsSub {
    db_main_indigo_live_active_sessions(where: {room_id: {_eq: "${roomId}"}, _and: {deleted_at: {_is_null: true}, _and: {active: {_eq: true}}}}, order_by: {display_name: asc, id:asc}) {
      active
      color
      company_name
      display_name
      email
      id
      title
      phone_number
    }
  }
  `;
  const allSessionsResult = useSubscription(ROOM_SESSIONS_SUB);

  const allSessionsList =
    allSessionsResult &&
    allSessionsResult.data &&
    allSessionsResult.data.db_main_indigo_live_active_sessions
      ? allSessionsResult.data.db_main_indigo_live_active_sessions
      : null;

  const pRoomsList =
    pRoomsRes &&
    pRoomsRes.data &&
    pRoomsRes.data.db_main_indigo_live_private_chat_rooms
      ? pRoomsRes.data.db_main_indigo_live_private_chat_rooms
      : null;
  //indigo_live_private_chats_aggregate.aggregate.count
  const pRoomsOtherSessionIds = pRoomsList
    ? pRoomsList.map(pr =>
        pr.creator_session_id == auth.session_id
          ? pr.participant_session_id
          : pr.creator_session_id
      )
    : null;
  const pRoomsOtherSessions = pRoomsList
    ? pRoomsList.map(pr =>
        pr.creator_session_id == auth.session_id
          ? {
              session: pr.indigo_live_active_session,
              roomId: pr.room_id,
              count: pr.indigo_live_private_chats_aggregate.aggregate.count
            }
          : {
              session: pr.indigoLiveActiveSessionByCreatorSessionId,
              roomId: pr.room_id,
              count: pr.indigo_live_private_chats_aggregate.aggregate.count
            }
      )
    : null;

  const pRoomList = !pRoomsOtherSessions
    ? null
    : pRoomsOtherSessions.map(pr => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '1px solid rgba(0,0,0,0.1)',
              justifyContent: 'center'
            }}
          >
            <UserSession session={pr.session} isLarge={true} />
            {roomStatus.privateChat && (
              <Button
                style={{
                  alignSelf: 'center',
                  border: '1px solid rgba(0,0,0,0.1)',
                  borderRadius: '5px',
                  marginBottom: '5px'
                }}
                onClick={() => {
                  if (onConversationStart)
                    onConversationStart(pr.session, pr.roomId);
                }}
              >
                Continue conversation
              </Button>
            )}
          </div>
        );
      });

  const peopleList = !allSessionsList
    ? null
    : allSessionsList
        .filter(
          s =>
            s.id != auth.session_id &&
            (!pRoomsOtherSessionIds ||
              (pRoomsOtherSessionIds && !pRoomsOtherSessionIds.includes(s.id)))
        ) //filtering private rooms
        .map(s => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderBottom: '1px solid rgba(0,0,0,0.1)',
                justifyContent: 'center'
              }}
            >
              <UserSession session={s} isLarge={true} />

              {roomStatus.privateChat && (
                <Button
                  style={{
                    alignSelf: 'center',
                    border: '1px solid rgba(0,0,0,0.1)',
                    borderRadius: '5px',
                    marginBottom: '5px'
                  }}
                  onClick={async () => {
                    var data = await createRoom({
                      variables: {
                        pid: s.id
                      }
                    });
                    if (
                      data &&
                      data.data.insert_db_main_indigo_live_private_chat_rooms &&
                      data.data.insert_db_main_indigo_live_private_chat_rooms
                        .returning &&
                      data.data.insert_db_main_indigo_live_private_chat_rooms
                        .returning.length > 0 &&
                      data.data.insert_db_main_indigo_live_private_chat_rooms
                        .returning[0].room_id
                    ) {
                      if (onConversationStart)
                        onConversationStart(
                          s,
                          data.data
                            .insert_db_main_indigo_live_private_chat_rooms
                            .returning[0].room_id
                        );
                    }
                  }}
                >
                  Start conversation
                </Button>
              )}
            </div>
          );
        });

  return (
    <div
      style={{
        width: '550px',
        maxHeight: maxHeight,
        minHeight: maxHeight,
        backgroundColor: 'white',
        overflow: 'auto'
      }}
    >
      <div>
        {pRoomList}
        {peopleList}
      </div>
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(PeopleTab);
