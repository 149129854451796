import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IconButton, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Gif } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const gf = new GiphyFetch('5sULLXdSVcxZJ4ZERHodmV6sgBvtDylV');

const GifPicker = props => {
  const { auth, width, onSelect, roomStatus, initialSelectedGif } = props;
  const [selectedGif, setSelectedGif] = useState(initialSelectedGif);
  const [gifSearchText, setGifSearchText] = React.useState('');
  const [fetchedGifSearch, setFetchedGifSearch] = React.useState('');
  const fetchGifs = offset =>
    gifSearchText
      ? gf.search(gifSearchText, { offset, limit: 10, rating: 'pg-13' })
      : gf.trending({ offset, limit: 10, rating: 'pg-13' });

  if (fetchedGifSearch !== gifSearchText) {
    setTimeout(() => {
      setFetchedGifSearch(gifSearchText);
    }, 200);
  }
  return (
    <div style={{ height: '300px', width: width > 800 ? '400px' : '350px' }}>
      {selectedGif && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px'
          }}
        >
          <Gif
            gif={selectedGif}
            width={200}
            hideAttribution={true}
            onGifClick={(gif, e) => {
              e.preventDefault();
            }}
          />
          <IconButton
            onClick={event => {
              setSelectedGif(null);
              if (onSelect) onSelect(null);
            }}
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <TextField
          id="comment-send-field"
          variant="outlined"
          placeholder="Search ..."
          multiline
          value={gifSearchText}
          autoFocus={width && width > 800}
          InputProps={{
            style: {
              paddingRight: '10px',
              paddingLeft: '10px',
              fontSize: 12,
              backgroundColor: '#f7f7f7',
              padding: '13px'
            }
          }}
          style={{
            marginTop: '5px',
            marginBottom: '10px',
            width: width > 800 ? '350px' : null
          }}
          fullWidth={width <= 800}
          onChange={event => {
            setGifSearchText(event.target.value);
          }}
        />
      </div>
      {fetchedGifSearch !== gifSearchText ? null : (
        <Grid
          width={width > 800 ? 400 : 350}
          columns={width > 800 ? 3 : 2}
          gutter={6}
          hideAttribution={true}
          fetchGifs={fetchGifs}
          onGifClick={(gif, e) => {
            e.preventDefault();
            setSelectedGif(gif);
            if (onSelect) onSelect(gif);
          }}
        />
      )}
    </div>
  );
};

GifPicker.propTypes = {
  width: PropTypes.number,
  onSelect: PropTypes.func,
  initialSelectedGif: PropTypes.any
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(GifPicker);
