import axios from './axios';

export const getRecordingData = async roomId => {
  try {
    if (!roomId) return null;
    var res = await axios({
      method: 'get',
      url: `/api/Recording/Data?room_id=${roomId}&api_key=qLqb3IPJft1tfjMOnlh49H7KsMMhvvHg`
    });
    return res.data.content;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const logRecordingSession = async (auth, roomId, localSession) => {
  try {
    var ipAddress;
    if (!localSession) {
      try {
        var ipRes = await axios.get('https://ipapi.co/json/');
        ipAddress = ipRes.data.ip;
      } catch (error) {}
    }
    ipAddress = localSession.ip;
    var res = await axios({
      method: 'post',
      url: `/api/Recording/Event?api_key=qLqb3IPJft1tfjMOnlh49H7KsMMhvvHg`,
      data: {
        type: 'recording_session',
        performer_id: auth.email,
        subject_id: roomId,
        payload: {
          ip: ipAddress,
          invite_id: auth.invite_id,
          language: auth.language,
          company_name: auth.company_name,
          display_name: auth.display_name,
          country_code: auth.country_code
        }
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
