import { SET_LOCAL_SESSION, UPDATE_AUTO_FILLED } from '../types/localSession';
const INITIAL_STATE = {
  ip: null,
  countryCode: null,
  autofilled: false
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOCAL_SESSION: {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }
    case UPDATE_AUTO_FILLED: {
      return {
        ...state,
        autofilled: true
      };
    }

    default:
      return state;
  }
};
