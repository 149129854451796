import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles, AppBar, Toolbar, Typography } from '@material-ui/core';
import styles from './LiveStyles';
import useWindowDimensions from '../../hooks/windowDimensions';
import appBarBackground from '../../assets/appbar_background.png';
import titeLogo from '../../assets/title_logo.png';

import queryString from 'querystring';
import PreChatRoom from '../../component/preChatRoom/PreChatRoom';
import jwt_decode from 'jwt-decode';

const Live = props => {
  const { height } = useWindowDimensions();
  const { match } = props;
  const [shutdownIntercom, setShutdownIntercom] = React.useState(true);
  const queryParams = queryString.decode(props.location.search.substring(1));

  if (
    (match.params.token && match.params.token == 'recordings') ||
    match.params.token == 'video' ||
    match.params.token == 'timeline' ||
    match.params.token == 'ongoing' ||
    match.params.token == 'masterclass' ||
    match.params.token == 'mc2021' ||
    match.params.token == 'sneakpeek'
  ) {
    return null;
  }

  if (window.Intercom && shutdownIntercom) {
    window.Intercom('shutdown');
    setShutdownIntercom(false);
  }
  const appBarRender = (
    <AppBar position="fixed">
      <Toolbar
        style={{
          backgroundImage: `url(${appBarBackground})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <img src={titeLogo} height="80%" />
      </Toolbar>
    </AppBar>
  );
  if (!queryParams.token)
    return (
      <div>
        {/* {appBarRender} */}
        <div
          style={{
            height: `${height * 0.8}px`,
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography style={{ marginLeft: '20px', textAlign: 'center' }}>
            Looks like you have no access, please ask for another link
          </Typography>
        </div>
      </div>
    );

  try {
    var payload = jwt_decode(queryParams.token);
  } catch (error) {
    payload = null;
  }
  if (!payload || !payload.id)
    return (
      <div>
        {appBarRender}
        <div
          style={{
            height: `${height * 0.8}px`,
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography style={{ marginLeft: '20px', textAlign: 'center' }}>
            Looks like you have no access, please ask for another link
          </Typography>
        </div>
      </div>
    );
  return (
    <PreChatRoom
      inviteId={payload.id}
      roomId={match.params.token}
      jwt={queryParams.token}
    />
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(withStyles(styles)(Live));
