import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: 'none'
    },
    fontSize: 12
  },
  palette: {
    primary: { main: '#008bbf' }
  }
});

export default theme;
