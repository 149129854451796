import {
  AUTHENTICATE,
  SET_SESSION_ID,
  SET_DISPLAY_NAME,
  SET_EMAIL,
  SET_COMPANY_NAME,
  SET_COUNTRY_CODE,
  SET_LANGUAGE,
  SET_TITLE,
  SET_PHONE_NUMBER,
  SET_ORG_TYPE
} from '../types/auth';

export const authenticate = (payload, jwt) => {
  return dispatch => {
    dispatch({
      type: AUTHENTICATE,
      payload: payload,
      jwt: jwt
    });
  };
};

export const setSessionId = sessionId => {
  return dispatch => {
    dispatch({
      type: SET_SESSION_ID,
      payload: sessionId
    });
  };
};

export const setDisplayName = displayName => {
  return dispatch => {
    dispatch({
      type: SET_DISPLAY_NAME,
      payload: displayName
    });
  };
};

export const setEmail = email => {
  return dispatch => {
    dispatch({
      type: SET_EMAIL,
      payload: email
    });
  };
};

export const setCompanyName = companyName => {
  return dispatch => {
    dispatch({
      type: SET_COMPANY_NAME,
      payload: companyName
    });
  };
};

export const setCountryCode = countryCode => {
  return dispatch => {
    dispatch({
      type: SET_COUNTRY_CODE,
      payload: countryCode
    });
  };
};

export const setLanguage = language => {
  return dispatch => {
    dispatch({
      type: SET_LANGUAGE,
      payload: language
    });
  };
};

export const setTitle = title => {
  return dispatch => {
    dispatch({
      type: SET_TITLE,
      payload: title
    });
  };
};

export const setPhoneNumber = phoneNumber => {
  return dispatch => {
    dispatch({
      type: SET_PHONE_NUMBER,
      payload: phoneNumber
    });
  };
};

export const setOrgType = orgType => {
  return dispatch => {
    dispatch({
      type: SET_ORG_TYPE,
      payload: orgType
    });
  };
};
