import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../loader/Loader';
import gql from 'graphql-tag';
import UserSession from '../userSession/UserSession';

const styles = theme => ({});

const UserSessionLoader = props => {
  const { session_id } = props;

  const SESSION_QUERY = gql`
    query getSession {
      db_main_indigo_live_active_sessions(where: {id: {_eq: "${session_id}"}}) {
        active
        company_name
        color
        email
        display_name
        phone_number
        title
        id
      }
    }
  `;
  var { data, loading, error } = useQuery(SESSION_QUERY);
  if (loading) return <Loader />;
  if (
    error ||
    !data ||
    !data.db_main_indigo_live_active_sessions ||
    data.db_main_indigo_live_active_sessions.length <= 0
  )
    return <div>Error</div>;
  var session = data.db_main_indigo_live_active_sessions[0];
  return <UserSession session={session} />;
};

UserSessionLoader.propTypes = {
  session_id: PropTypes.any.isRequired
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(withStyles(styles)(UserSessionLoader));
