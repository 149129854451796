import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { store, history } from './store/Store';
import { BrowserRouter } from 'react-router-dom';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloProvider } from '@apollo/react-hooks';

const urlParams = new URLSearchParams(window.location.search);
const jwt = urlParams.get('token');
// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: `wss://cloud.strive-hasura.online/v1/graphql`,
  options: {
    reconnect: true,
    reconnectionAttempts: 20,
    connectionParams: {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    }
  }
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: wsLink,
  cache: cache
});

render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter store={store} history={history}>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
