import axios from './axios';

export const updateActiveSession = async (auth, localSession) => {
  try {
    var ipRes;
    if (!localSession) {
      try {
        ipRes = await axios.get('https://ipapi.co/json/');
      } catch (error) {
        console.log(error);
        ipRes = null;
      }
    }
    var ipAddress = localSession
      ? localSession.ip
      : ipRes && ipRes.data
      ? ipRes.data.ip
      : 'null';
    var res = await axios({
      method: 'post',
      url: `/api/Session/Update?ip=${ipAddress}`,
      headers: {
        Authorization: 'Bearer ' + auth.jwt
      },
      data: {
        display_name: auth.display_name,
        email: auth.email,
        company_name: auth.company_name,
        country_code: auth.country_code,
        title: auth.title,
        phone_number: auth.phone_number,
        org_type: auth.org_type
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateOngoingSession = async (auth, startTime, localSession) => {
  try {
    var ipRes;
    if (!localSession) {
      try {
        ipRes = await axios.get('https://ipapi.co/json/');
      } catch (error) {
        console.log(error);
        ipRes = null;
      }
    }
    var ipAddress = localSession
      ? localSession.ip
      : ipRes && ipRes.data
      ? ipRes.data.ip
      : 'null';
    var res = await axios({
      method: 'post',
      url: `/api/Session/Ongoing?ip=${ipAddress}`,
      headers: {
        Authorization: 'Bearer ' + auth.jwt
      },
      data: {
        display_name: auth.display_name,
        email: auth.email,
        company_name: auth.company_name,
        country_code: auth.country_code,
        title: auth.title,
        phone_number: auth.phone_number,
        org_type: auth.org_type,
        start_watch_time: startTime
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateOngoingStartTime = async (jwt, roomId, email, startTime) => {
  try {
    var res = await axios({
      method: 'post',
      url: '/api/Session/ongoing_start_time',
      headers: {
        Authorization: 'Bearer ' + jwt
      },
      data: {
        start_watch_time: startTime,
        room_id: roomId,
        email: email
      }
    });
  } catch (error) {
    console.log(error);
    return null;
  }
};
