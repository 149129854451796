import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Button,
  Tooltip,
  Checkbox
} from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  getAllCountryOptions,
  getCountryNameByCode,
  fetchCountryCode,
  getAllLanguageOptions,
  getLanguageLabelByValue
} from '../../utils/CountryUtils';
import Select from 'react-select';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Flag from 'react-world-flags';

const EntryFormDialog = props => {
  const { auth, width, recording, translationLanguages } = props;
  const [newEmail, setNewEmail] = React.useState(auth ? auth.email : null);
  const [newDisplayName, setNewDisplayName] = React.useState(
    auth ? auth.display_name : null
  );
  const [newCompanyName, setNewCompanyName] = React.useState(
    auth ? auth.company_name : ''
  );

  const [newCountryCode, setNewCountryCode] = React.useState(
    auth ? auth.country_code : ''
  );
  const [newCountryName, setNewCountryName] = React.useState(
    auth && auth.country_code ? getCountryNameByCode(auth.country_code) : ''
  );
  const [fetchedCountry, setFetchedCountry] = React.useState(false);
  const [captionLanguage, setCaptionLanguage] = React.useState(
    auth ? auth.language : null
  );
  const [displayCompany, setDisplayCompany] = React.useState(true);

  useEffect(() => {
    if (!auth.country_code && !fetchedCountry) {
      setFetchedCountry(true);
      fetchCountryCode()
        .then(code => {
          if (!auth.country_code && !newCountryCode && code) {
            setNewCountryName(getCountryNameByCode(code));
            setNewCountryCode(code);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  });

  const getCurrLanguagesOptions = function() {
    const allOptions = getAllLanguageOptions();
    if (translationLanguages) {
      const result = allOptions.filter(
        option => translationLanguages[option.value]
      );
      return result;
    } else {
      return allOptions;
    }
  };

  const fieldsWidth = width > 800 ? '400px' : '240px';
  return (
    <Dialog open={props.open} onClose={() => {}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#efeff1',
            width: '100%'
          }}
        >
          <div
            style={{
              alignSelf: 'flex-end'
            }}
          ></div>
          <div
            style={{
              backgroundColor: '#efeff1',
              borderBottom: '1px solid rgba(0,0,0,0.1)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                marginBottom: '20px',
                marginTop: '20px'
              }}
            >
              Please enter your details
            </Typography>
          </div>
        </div>

        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'flex-center',
            flexDirection: 'column',
            alignItems: 'center',
            width: width > 800 ? '450px' : '100%'
          }}
        >
          {/* Display name section*/}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'start',
              width: fieldsWidth,
              marginTop: 10
            }}
          >
            <Typography style={{ fontSize: 12 }}>Name</Typography>
          </div>
          <TextField
            autoFocus
            margin="dense"
            placeholder="Name"
            variant="outlined"
            value={newDisplayName}
            style={{ width: fieldsWidth }}
            required
            InputProps={{
              style: {
                fontSize: 12,
                // backgroundColor: loading ? '#e8e8e8' : '#f7f7f7'
                backgroundColor: '#f7f7f7'
              }
            }}
            onChange={event => {
              setNewDisplayName(event.target.value);
            }}
          />

          {/* Email Section*/}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'start',
              width: fieldsWidth
            }}
          >
            <Typography style={{ fontSize: 12 }}>Email</Typography>
          </div>
          <TextField
            margin="dense"
            placeholder="Email Address"
            style={{ width: fieldsWidth }}
            type="email"
            variant="outlined"
            InputProps={{
              style: {
                fontSize: 12,
                // backgroundColor: loading ? '#e8e8e8' : '#f7f7f7'
                backgroundColor: '#f7f7f7'
              }
            }}
            value={newEmail}
            onChange={event => {
              setNewEmail(event.target.value);
            }}
          />
          <br />

          {/* Company Section*/}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'start',
              width: fieldsWidth
            }}
          >
            <Typography style={{ fontSize: 12 }}>Company</Typography>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: fieldsWidth
            }}
          >
            <TextField
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  props.onConfirm({
                    display_name: newDisplayName,
                    company_name: displayCompany ? newCompanyName : null,
                    email: newEmail,
                    country_code: newCountryCode,
                    language: captionLanguage
                  });
                  ev.preventDefault();
                }
              }}
              margin="dense"
              placeholder="Company Name"
              style={{ width: fieldsWidth }}
              variant="outlined"
              InputProps={{
                style: {
                  fontSize: 12,
                  backgroundColor: '#f7f7f7'
                }
              }}
              value={newCompanyName}
              onChange={event => {
                setNewCompanyName(event.target.value);
              }}
            />
          </div>
          <br />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'start',
              width: fieldsWidth,
              marginBottom: '5px'
            }}
          >
            <Typography style={{ fontSize: 12 }}>Country</Typography>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: fieldsWidth
            }}
          >
            <div
              style={{
                width: width > 800 ? '300px' : '145px',
                fontSize: 12,
                fontFamily: 'Roboto'
              }}
            >
              <Select
                options={getAllCountryOptions()}
                components={{
                  Option: props => {
                    const { ...data } = props;
                    return (
                      <div
                        style={{
                          display: 'flex',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          props.selectOption(data.data);
                        }}
                      >
                        <Flag
                          style={{
                            width: '22px',
                            height: '22px'
                          }}
                          code={data.value}
                        />
                        <span style={{ marginLeft: '5px' }}>{data.label}</span>
                      </div>
                    );
                  }
                }}
                value={
                  newCountryCode && newCountryName
                    ? { value: newCountryCode, label: newCountryName }
                    : null
                }
                onChange={option => {
                  setNewCountryCode(option.value);
                  setNewCountryName(option.label);
                }}
              />
            </div>
            <div
              style={{
                width: width > 800 ? '45px' : '35px',
                height: '35px',
                marginLeft: '5px',
                border: 'solid 1px #d1d1d1',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {newCountryCode ? (
                <Flag
                  style={{
                    width: '22px',
                    height: '22px'
                  }}
                  code={newCountryCode}
                />
              ) : (
                <Typography
                  style={{
                    fontSize: 12
                  }}
                >
                  Pick
                </Typography>
              )}
            </div>

            <div
              style={{
                width: width > 800 ? '45px' : '35px',
                height: '35px',
                marginLeft: '5px',
                border: 'solid 1px #d1d1d1',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <IconButton
                disabled={!newCountryCode}
                onClick={event => {
                  setNewCountryCode(null);
                  setNewCountryName(null);
                }}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
          <br />

          {/* Preferred translation Section */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'start',
              width: fieldsWidth,
              marginBottom: '5px'
            }}
          >
            <Typography style={{ fontSize: 12 }}>
              Preferred subtitle language
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                width: width > 800 ? '350px' : '190px',
                fontSize: 12,
                fontFamily: 'Roboto'
              }}
            >
              <Select
                options={getCurrLanguagesOptions()}
                components={{
                  Option: props => {
                    const { ...data } = props;
                    return (
                      <div
                        style={{
                          display: 'flex',
                          cursor: 'pointer',
                          marginBottom: '10px'
                        }}
                        onClick={() => {
                          props.selectOption(data.data);
                        }}
                      >
                        <span style={{ marginLeft: '5px' }}>{data.label}</span>
                      </div>
                    );
                  }
                }}
                value={
                  captionLanguage
                    ? {
                        value: captionLanguage,
                        label: getLanguageLabelByValue(captionLanguage)
                      }
                    : null
                }
                onChange={option => {
                  setCaptionLanguage(option.value);
                }}
              />
            </div>
            <div
              style={{
                width: '50px',
                height: '35px',
                marginLeft: '5px',
                border: 'solid 1px #d1d1d1',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Tooltip title="Turn off captions">
                <IconButton
                  disabled={!captionLanguage}
                  onClick={event => {
                    setCaptionLanguage(null);
                  }}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <br />

          <Button
            style={{
              backgroundColor: '#008bbf',
              color: 'white',
              marginTop: '20px',
              marginBottom: '40px',
              width: fieldsWidth
            }}
            onClick={() => {
              if (props.onConfirm)
                props.onConfirm({
                  display_name: newDisplayName,
                  company_name: displayCompany ? newCompanyName : null,
                  email: newEmail,
                  country_code: newCountryCode,
                  language: captionLanguage
                });
            }}
          >
            Submit to View Webcast
          </Button>
        </DialogContent>
      </div>
    </Dialog>
  );
};

EntryFormDialog.propTypes = {
  width: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  recording: PropTypes.bool,
  translationLanguages: PropTypes.any
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(EntryFormDialog);
