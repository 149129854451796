import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Button,
  Tooltip,
  Checkbox
} from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  getAllCountryOptions,
  getCountryNameByCode,
  fetchCountryCode,
  getMappedSubtitles,
  getLanguageLabelByValue
} from '../../utils/CountryUtils';
import Select from 'react-select';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { isValidEmail } from '../../utils/StringUtils';
import { getOrgTypeOptions } from '../../utils/RoomUtils';
import { updateAutoFilled } from '../../store/actions/localSession';
import Flag from 'react-world-flags';

const ChatSettingsDialog = props => {
  const {
    auth,
    width,
    recording,
    roomStatus,
    localSession,
    updateAutoFilled
  } = props;
  const [newEmail, setNewEmail] = React.useState(auth ? auth.email : null);
  const [newDisplayName, setNewDisplayName] = React.useState(
    auth ? auth.display_name : null
  );
  const [newCompanyName, setNewCompanyName] = React.useState(
    auth ? auth.company_name : ''
  );

  const [newCountryCode, setNewCountryCode] = React.useState(
    auth ? auth.country_code : ''
  );
  const [newCountryName, setNewCountryName] = React.useState(
    auth && auth.country_code ? getCountryNameByCode(auth.country_code) : ''
  );
  const [captionLanguage, setCaptionLanguage] = React.useState(
    auth ? auth.language : null
  );
  const [orgType, setOrgType] = React.useState(auth ? auth.org_type : null);
  const [newTitle, setNewTitle] = React.useState(auth ? auth.title : '');
  const [displayCompany, setDisplayCompany] = React.useState(true);
  const [newNumber, setNewNumber] = React.useState(
    auth && auth.phone_number ? auth.phone_number : ''
  );

  useEffect(() => {
    if (
      !auth.country_code &&
      ((localSession && !localSession.autofilled) || !localSession)
    ) {
      fetchCountryCode(localSession)
        .then(code => {
          if (!auth.country_code && !newCountryCode && code) {
            updateAutoFilled();
            setNewCountryName(getCountryNameByCode(code));
            setNewCountryCode(code);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [localSession]);
  const fieldsWidth = width > 800 ? '400px' : '100%';
  const fieldsWidthSplit = width > 800 ? '200px' : '100%';
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={() => {}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#efeff1',
            width: '100%'
          }}
        >
          <div
            style={{
              alignSelf: 'flex-end'
            }}
          >
            <IconButton
              disabled={!auth.email || !auth.display_name}
              style={{ width: '24px', height: '32px', marginRight: '10px' }}
              onClick={event => {
                setNewEmail(auth.email);
                setNewDisplayName(auth.display_name);
                setNewCompanyName(auth.company_name);
                setNewCountryCode(auth.country_code);
                setCaptionLanguage(auth.language);
                if (props.onClose) props.onClose();
              }}
            >
              <CloseIcon
                style={{ width: '16px', height: '16px' }}
                fontSize="small"
              />
            </IconButton>
          </div>
          <div
            style={{
              backgroundColor: '#efeff1',
              borderBottom: '1px solid rgba(0,0,0,0.1)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                marginBottom: '20px'
              }}
            >
              Settings
            </Typography>
          </div>
        </div>

        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'flex-center',
            flexDirection: 'column',
            alignItems: 'center',
            width: width > 800 ? '450px' : '100%'
          }}
        >
          {/* Country Section */}
          {recording ? null : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'start',
                width: fieldsWidth,
                marginBottom: '5px'
              }}
            >
              <Typography style={{ fontSize: 12 }}>Country</Typography>
            </div>
          )}

          {recording && false ? null : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: fieldsWidth
              }}
            >
              <div
                style={{
                  width: width > 800 ? '300px' : fieldsWidth,
                  fontSize: 12,
                  fontFamily: 'Roboto'
                }}
              >
                <Select
                  options={getAllCountryOptions()}
                  components={{
                    Option: props => {
                      const { ...data } = props;
                      return (
                        <div
                          style={{
                            display: 'flex',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            props.selectOption(data.data);
                          }}
                        >
                          <Flag
                            style={{ width: '22px', height: '22px' }}
                            code={data.value}
                          />
                          <span style={{ marginLeft: '5px' }}>
                            {data.label}
                          </span>
                        </div>
                      );
                    }
                  }}
                  value={
                    newCountryCode && newCountryName
                      ? { value: newCountryCode, label: newCountryName }
                      : null
                  }
                  onChange={option => {
                    setNewCountryCode(option.value);
                    setNewCountryName(option.label);
                  }}
                />
              </div>
              <div
                style={{
                  width: width > 800 ? '45px' : '35px',
                  height: '35px',
                  marginLeft: '5px',
                  border: 'solid 1px #d1d1d1',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {newCountryCode ? (
                  <Flag
                    style={{ width: '22px', height: '22px' }}
                    code={newCountryCode}
                  />
                ) : (
                  <Typography
                    style={{
                      fontSize: 12
                    }}
                  >
                    Pick
                  </Typography>
                )}
              </div>

              <div
                style={{
                  width: width > 800 ? '45px' : '35px',
                  height: '35px',
                  marginLeft: '5px',
                  border: 'solid 1px #d1d1d1',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <IconButton
                  disabled={!newCountryCode}
                  onClick={event => {
                    setNewCountryCode(null);
                    setNewCountryName(null);
                  }}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          )}
          {recording && false ? null : <br />}

          {/* Preferred translation Section */}
          {roomStatus.subtitles && roomStatus.subtitles.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'start',
                width: fieldsWidth,
                marginBottom: '5px'
              }}
            >
              <Typography style={{ fontSize: 12 }}>
                Preferred subtitle language
              </Typography>
            </div>
          )}
          {roomStatus.subtitles && roomStatus.subtitles.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: fieldsWidth
              }}
            >
              <div
                style={{
                  width: width > 800 ? '350px' : fieldsWidth,
                  fontSize: 12,
                  fontFamily: 'Roboto'
                }}
              >
                <Select
                  options={getMappedSubtitles(roomStatus.subtitles)}
                  components={{
                    Option: props => {
                      const { ...data } = props;
                      return (
                        <div
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                            marginBottom: '10px'
                          }}
                          onClick={() => {
                            props.selectOption(data.data);
                          }}
                        >
                          <span style={{ marginLeft: '5px' }}>
                            {data.label}
                          </span>
                        </div>
                      );
                    }
                  }}
                  value={
                    captionLanguage
                      ? {
                          value: captionLanguage,
                          label: getLanguageLabelByValue(
                            captionLanguage,
                            getMappedSubtitles(roomStatus.subtitles)
                          )
                        }
                      : null
                  }
                  onChange={option => {
                    setCaptionLanguage(option.value);
                  }}
                />
              </div>
              <div
                style={{
                  width: '50px',
                  height: '35px',
                  marginLeft: '5px',
                  border: 'solid 1px #d1d1d1',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Tooltip title="Turn off captions">
                  <IconButton
                    disabled={!captionLanguage}
                    onClick={event => {
                      setCaptionLanguage(null);
                    }}
                  >
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
          {roomStatus.subtitles && roomStatus.subtitles.length > 0 && <br />}

          {/* Email Section*/}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'start',
              width: fieldsWidth
            }}
          >
            <Typography style={{ fontSize: 12 }}>Email*</Typography>
          </div>
          <TextField
            autoFocus
            margin="dense"
            placeholder="Email Address"
            style={{ width: fieldsWidth }}
            type="email"
            variant="outlined"
            InputProps={{
              style: {
                fontSize: 12,
                backgroundColor: '#f7f7f7'
              }
            }}
            value={newEmail}
            onChange={event => {
              setNewEmail(event.target.value);
            }}
          />
          <br />

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* Display name section*/}
            <div>
              {recording && false ? null : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'start',
                    width: fieldsWidthSplit
                  }}
                >
                  <Typography style={{ fontSize: 12 }}>
                    Display name*
                  </Typography>
                </div>
              )}
              {recording && false ? null : (
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="Display Name"
                  variant="outlined"
                  value={newDisplayName}
                  style={{ width: fieldsWidthSplit }}
                  InputProps={{
                    style: {
                      fontSize: 12,
                      // backgroundColor: loading ? '#e8e8e8' : '#f7f7f7'
                      backgroundColor: '#f7f7f7'
                    }
                  }}
                  onChange={event => {
                    setNewDisplayName(event.target.value);
                  }}
                />
              )}
            </div>

            <div style={{ width: fieldsWidthSplit, marginLeft: 5 }}>
              {recording && false ? null : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'start',
                    width: fieldsWidthSplit,
                    marginBottom: 8
                  }}
                >
                  <Typography style={{ fontSize: 12 }}>Phone number</Typography>
                </div>
              )}
              {recording && false ? null : (
                <PhoneInput
                  style={{ width: fieldsWidthSplit }}
                  inputStyle={{ width: fieldsWidthSplit }}
                  country={
                    newNumber ? '' : newCountryCode ? newCountryCode : ''
                  }
                  value={newNumber}
                  onChange={phone => setNewNumber(phone)}
                />
              )}
            </div>
          </div>

          {recording && false ? null : <br />}

          {/* Title section*/}
          {/* {recording && auth.email && auth.email.includes('@hp.com') ? null : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'start',
                width: fieldsWidth
              }}
            >
              <Typography style={{ fontSize: 12 }}>Title</Typography>
            </div>
          )}
          {recording && auth.email && auth.email.includes('@hp.com') ? null : (
            <TextField
              autoFocus
              margin="dense"
              placeholder="Title"
              variant="outlined"
              value={newTitle}
              style={{ width: fieldsWidth }}
              InputProps={{
                style: {
                  fontSize: 12,
                  // backgroundColor: loading ? '#e8e8e8' : '#f7f7f7'
                  backgroundColor: '#f7f7f7'
                }
              }}
              onChange={event => {
                setNewTitle(event.target.value);
              }}
            />
          )}
          {recording && auth.email && auth.email.includes('@hp.com') ? null : (
            <br />
          )} */}

          {/* Company Section*/}
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'start',
              width: fieldsWidth
            }}
          >
            <Typography style={{ fontSize: 12 }}>Company</Typography>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: fieldsWidth
            }}
          >
            <TextField
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  if (!isValidEmail(newEmail)) {
                    alert('Please fill a valid email');
                  } else if (!newDisplayName) {
                    alert('Please fill in your display name');
                  } else {
                    if (props.onConfirm)
                      props.onConfirm({
                        display_name: newDisplayName,
                        company_name: displayCompany ? newCompanyName : null,
                        email: newEmail,
                        country_code: newCountryCode,
                        language: captionLanguage,
                        title: newTitle,
                        phone_number: newNumber,
                        org_type: orgType
                      });
                  }
                  ev.preventDefault();
                }
              }}
              autoFocus
              margin="dense"
              placeholder="Company Name"
              style={{ width: fieldsWidth }}
              variant="outlined"
              InputProps={{
                style: {
                  fontSize: 12,
                  backgroundColor: '#f7f7f7'
                }
              }}
              value={newCompanyName}
              onChange={event => {
                setNewCompanyName(event.target.value);
              }}
            /> */}
          {/* <Tooltip title="Display your company?">
              <Checkbox
                checked={displayCompany}
                onChange={event => {
                  setDisplayCompany(event.target.checked);
                }}
                style={{ width: '50px' }}
                color="primary"
              ></Checkbox>
            </Tooltip> 
          </div>
          <br />*/}

          {/* Org Type Section*/}
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'start',
              width: fieldsWidth
            }}
          >
            <Typography style={{ fontSize: 12 }}>Organization Type</Typography>
          </div>

          <div
            style={{
              width: fieldsWidth,
              fontSize: 12,
              fontFamily: 'Roboto'
            }}
          >
            <Select
              options={getOrgTypeOptions()}
              components={{
                Option: props => {
                  const { ...data } = props;
                  return (
                    <div
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        marginBottom: '10px'
                      }}
                      onClick={() => {
                        props.selectOption(data.data);
                      }}
                    >
                      <span style={{ marginLeft: '5px' }}>{data.label}</span>
                    </div>
                  );
                }
              }}
              value={
                orgType
                  ? {
                      value: orgType,
                      label: orgType
                    }
                  : null
              }
              onChange={option => {
                setOrgType(option.value);
              }}
            />
          </div> */}

          <Button
            style={{
              backgroundColor: '#008bbf',
              color: 'white',
              marginTop: '20px',
              marginBottom: '40px',
              width: fieldsWidth
            }}
            onClick={() => {
              if (!isValidEmail(newEmail)) {
                alert('Please fill a valid email');
              } else if (!newDisplayName && !recording) {
                alert('Please fill in your display name');
              } else {
                if (props.onConfirm)
                  props.onConfirm({
                    display_name: newDisplayName,
                    company_name: displayCompany ? newCompanyName : null,
                    email: newEmail,
                    country_code: newCountryCode,
                    language: captionLanguage,
                    title: newTitle,
                    phone_number: newNumber,
                    org_type: orgType
                  });
              }
            }}
          >
            Confirm
          </Button>
        </DialogContent>
      </div>
    </Dialog>
  );
};

ChatSettingsDialog.propTypes = {
  width: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  recording: PropTypes.bool
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus,
    localSession: state.localSession
  }),
  dispatch =>
    bindActionCreators(
      {
        updateAutoFilled
      },
      dispatch
    )
)(ChatSettingsDialog);
