//handles room state
import {
  SET_ROOM_DATA,
  SET_ROOM_CLOSED,
  SET_LAST_COMMENTS_LENGTH,
  INCREMENT_UNREAD_COUNT,
  SET_CHAT_DRAWER_OPEN,
  SET_ROOM_SESSIONS,
  SET_VIDEO_DID_END,
  SET_ROOM_START_TIME,
  SET_VIDEO_SUBTITLES,
  SET_FORCE_TAB_INDEX,
  SET_QUIZ_STATUS,
  SET_QA_COUNT,
  SET_QA_NOTIFY_COUNT,
  SET_QA_NOTIFY_STATUS
} from '../types/roomStatus';
const INITIAL_STATE = {
  start_time: null,
  duration: null,
  limit: null,
  unreadCommentsCount: 0,
  lastCommentLength: 0,
  chatDrawerOpen: true,
  roomFound: false,
  room_detail: null,
  wave: null,
  region: null,
  roomSessions: [],
  privateChat: null,
  video_type: null,
  mux_asset_id: null,
  videoDidEnd: false,
  external_link: null,
  is_pre_recorded: null,
  subtitles: null,
  quizStatus: null,
  forceTabIndex: null,
  feedbackFormId: null,
  qaCount: null,
  qaNotifyCount: null,
  qaNotifyStatus: 'outside'
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_QA_NOTIFY_STATUS: {
      const { payload } = action;
      return { ...state, qaNotifyStatus: payload };
    }
    case SET_QA_NOTIFY_COUNT: {
      const { payload } = action;
      return { ...state, qaNotifyCount: payload };
    }
    case SET_QA_COUNT: {
      const { payload } = action;
      return { ...state, qaCount: payload };
    }
    case SET_VIDEO_SUBTITLES: {
      const { payload } = action;
      return { ...state, subtitles: payload };
    }
    case SET_FORCE_TAB_INDEX: {
      const { payload } = action;
      return { ...state, forceTabIndex: payload };
    }
    case SET_QUIZ_STATUS: {
      const { payload } = action;
      return { ...state, quizStatus: payload };
    }
    case SET_ROOM_DATA: {
      const { payload } = action;
      if (payload) {
        var region;
        if (payload.description) {
          if (payload.description.includes('APJ')) {
            region = 'APJ';
          } else if (
            payload.description.includes('EMEA') ||
            payload.description.includes('AMS')
          ) {
            region = 'AMERICA';
          }
        }
        return {
          ...state,
          vidyard_uuid: payload.mux_playback_id
            ? payload.mux_playback_id
            : payload.vidyard_uuid,
          start_time: payload.start_time,
          duration: payload.duration,
          limit: payload.limit,
          room_detail: payload.segment ? payload.segment : payload.detail,
          wave: payload.webinar_number,
          region: region,
          roomFound: true,
          privateChat: payload.private_chat,
          video_type: payload.video_type,
          mux_asset_id: payload.mux_asset_id,
          external_link: payload.external_link,
          is_pre_recorded: payload.is_pre_recorded,
          feedbackFormId: payload.feedback_formid
        };
      }
      return state;
    }

    case SET_ROOM_CLOSED: {
      return {
        ...state,
        roomFound: false
      };
    }

    case SET_VIDEO_DID_END: {
      return {
        ...state,
        videoDidEnd: true
      };
    }

    case SET_ROOM_START_TIME: {
      const { payload } = action;
      return {
        ...state,
        start_time: payload
      };
    }

    case INCREMENT_UNREAD_COUNT: {
      const { payload } = action;
      return {
        ...state,
        unreadCommentsCount: state.unreadCommentsCount + 1,
        lastCommentLength: payload
      };
    }

    case SET_LAST_COMMENTS_LENGTH: {
      const { payload } = action;
      return {
        ...state,
        lastCommentLength: payload
      };
    }

    case SET_CHAT_DRAWER_OPEN: {
      const { payload } = action;
      const newUnreadCount = payload === true ? 0 : state.unreadCommentsCount;
      return {
        ...state,
        chatDrawerOpen: payload,
        unreadCommentsCount: newUnreadCount
      };
    }

    case SET_ROOM_SESSIONS: {
      const { payload } = action;
      if (payload) {
        return {
          ...state,
          roomSessions: payload
        };
      }
      return state;
    }

    default:
      return state;
  }
};
