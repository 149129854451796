import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import UsersList from '../usersList/UsersList';

const LikesDialog = props => {
  const {
    auth,
    open,
    anchorEl,
    setAnchorEl,
    numOfLikes,
    likesArray,
    closeDialog
  } = props;

  return (
    <div {...props}>
      <Popover
        id={'likesDialog'}
        open={open}
        anchorEl={anchorEl}
        onClose={closeDialog}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            maxHeight: '300px',
            minWidth: '350px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
              backgroundColor: '#efeff1',
              borderBottom: '1px solid rgba(0,0,0,0.1)'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  display: 'felx',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#008bbf',
                  padding: 5,
                  margin: '10px 2px 10px 10px',
                  borderRadius: '50%',
                  paddingBottom: 2
                }}
              >
                <ThumbUpIcon
                  fontSize="small"
                  style={{ color: '#ffffff', fontSize: 16 }}
                ></ThumbUpIcon>
              </div>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 12
                }}
              >
                {numOfLikes}
              </Typography>
            </div>

            <CloseIcon
              style={{ margin: '10px' }}
              fontSize="small"
              onClick={event => {
                setAnchorEl(null);
              }}
            ></CloseIcon>
          </div>
          <UsersList users={likesArray}></UsersList>
        </div>
      </Popover>
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(LikesDialog);
