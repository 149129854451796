import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Button,
  Tooltip,
  Checkbox,
  MenuItem,
  Input,
  Select,
  TextareaAutosize,
  DialogTitle
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { getExitFormId } from '../../utils/CountryUtils';
// import Select from 'react-select';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
import { submitFeedback } from '../../api/feedback';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Loader from '../loader/Loader';

const EndingDialog = props => {
  const { auth, width, roomStatus } = props;
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [contactChecked, setContactChecked] = React.useState(true);
  const [productsChecked, setProductsChecked] = React.useState([false, false]);
  const [rerender, setRerender] = React.useState(false);
  const [viewNumber, setViewNumber] = React.useState(1);
  const [recommendationScore, setRecommendationScore] = React.useState();
  const [recommendationReason, setRecommendationReason] = React.useState();
  const [moreInformation, setMoreInformation] = React.useState();
  const [oneOnone, setOneOnone] = React.useState();
  const [loadingIframe, setLoadingIframe] = React.useState(true);
  const fieldsWidth = width > 800 ? '400px' : '240px';
  const buttonsWidth = width > 800 ? '80px' : '20px';
  const products = ['1', '2', '3', '4', '5'];
  const formId = getExitFormId(roomStatus.room_detail);

  const insertMoreInformation = function(value) {
    setMoreInformation(value);
    setViewNumber(4);
  };
  const insertoneOnone = function(value) {
    setOneOnone(value);
    submitFunction(value);
  };
  const insertRecommendationReason = function(value) {
    setRecommendationReason(value);
  };

  const goNext = function(value) {
    setViewNumber(value + 1);
  };
  const submitFunction = function(oneOnoneValue) {
    if (props.onClose) props.onClose();
    submitFeedback(auth, {
      pmf_score: recommendationScore,
      pmf_detail: recommendationReason,
      more_info: moreInformation,
      one_on_one: oneOnoneValue
    });
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const typeformURL = `https://usestrive.typeform.com/to/${formId}?email=${auth.email}&room_id=${auth.room_id}&method=web&webinar_number=${roomStatus.wave}`;
  const typeformURL = `https://app.usestrive.com/#/strive-forms?form_id=${roomStatus.feedbackFormId}&room_id=${auth.room_id}&method=web&email=${auth.email}`;
  if (!roomStatus.feedbackFormId) return null;
  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={() => {}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
          // height: '400px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#20003f',
            width: '100%',
            paddingBottom: 10,
            paddingTop: 10
          }}
        >
          <div
            style={{
              backgroundColor: '#20003f',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              marginLeft: 20
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                color: 'white'
              }}
            >
              Thank you for watching
            </Typography>
          </div>
          <div
            style={{
              alignSelf: 'flex-end'
            }}
          >
            <IconButton
              style={{ width: '24px', height: '32px', marginRight: '10px' }}
              onClick={event => {
                if (props.onClose) props.onClose();
              }}
            >
              <CloseIcon
                style={{ width: '16px', height: '16px', color: 'white' }}
                fontSize="small"
              />
            </IconButton>
          </div>
        </div>

        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 0,
            width: '100%'
          }}
        >
          <div style={{ width: '100%' }}>
            <div
              style={{
                width: width > 768 ? '600px' : '100%',
                height: width > 768 ? '540px' : '90vh'
              }}
              // dangerouslySetInnerHTML={{
              //   __html:
              //     '<iframe src="' +
              //     typeformURL +
              //     '" width="100%" height="100%"></iframe><script> (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() </script>'
              // }}
            >
              {loadingIframe && <Loader style={{ position: 'absolute' }} />}
              <iframe
                src={typeformURL}
                style={{ width: '100%', height: '100%', border: 'none' }}
                onLoad={() => {
                  setLoadingIframe(false);
                }}
              />
            </div>
          </div>

          {/* <Typography
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              marginBottom: '50px',
              alignSelf: 'center',
              justifyContent: 'center'
            }}
          >
            Thank you for watching!
          </Typography>
          <Typography
            style={{
              fontSize: '16px'
              // marginBottom: '20px'
            }}
          >
            {viewNumber == 1
              ? 'How likely are you to recommend the Indigo LIVE webcast to a colleague?'
              : viewNumber == 2
              ? 'Why did you choose ' + recommendationScore + '?'
              : viewNumber == 3
              ? 'Would you like to get more information about the Indigo new portfolio and solutions?'
              : viewNumber == 4
              ? 'Would you like to schedule a one-on-one live demo?'
              : ''}
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: viewNumber == 1 ? 'center' : 'start',
              width: '100%'
            }}
          >
            {products.map((product, index) => (
              <Button
                style={{
                  backgroundColor: '#008bbf',
                  color: 'white',
                  marginTop: '20px',
                  // marginBottom: '40px',
                  width: buttonsWidth,
                  borderRadius: 0,
                  border: '1px solid white',
                  borderTopLeftRadius: product == '1' ? 5 : 0,
                  borderBottomLeftRadius: product == '1' ? 5 : 0,
                  borderTopRightRadius: product == '5' ? 5 : 0,
                  borderBottomRightRadius: product == '5' ? 5 : 0,
                  minWidth: '50px'
                }}
                onClick={() => {
                  changeRecommendation(product);
                }}
              >
                {product}
              </Button>
            ))}
            {viewNumber == 10 ? (
              products.map((product, index) => (
                <Button
                  style={{
                    backgroundColor: '#008bbf',
                    color: 'white',
                    marginTop: '20px',
                    marginBottom: '40px',
                    width: buttonsWidth,
                    borderRadius: 0,
                    border: '1px solid white',
                    borderTopLeftRadius: product == '1' ? 5 : 0,
                    borderBottomLeftRadius: product == '1' ? 5 : 0,
                    borderTopRightRadius: product == '5' ? 5 : 0,
                    borderBottomRightRadius: product == '5' ? 5 : 0,
                    minWidth: '50px'
                  }}
                  onClick={() => {
                    changeRecommendation(product);
                  }}
                >
                  {product}
                </Button>
              ))
            ) : viewNumber == 2 ? (
              <TextareaAutosize
                placeholder="Type Your answer here..."
                style={{ width: fieldsWidth, padding: 10, borderRadius: 5 }}
                onChange={event => {
                  insertRecommendationReason(event.target.value);
                }}
                onKeyPress={ev => {
                  if (ev.key === 'Enter') {
                    goNext(2);
                  }
                }}
              />
            ) : viewNumber == 3 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Button
                  style={{
                    backgroundColor: 'white',
                    color: '#008bbf',
                    width: 100,
                    borderRadius: 5,
                    marginBottom: 10,
                    border: '1px solid #008bbf',
                    paddingLeft: 7,
                    display: 'flex',
                    justifyContent: 'start'
                  }}
                  onClick={() => {
                    insertMoreInformation('yes');
                  }}
                >
                  <CheckIcon
                    style={{ width: 13, marginRight: 10, marginLeft: 5 }}
                  ></CheckIcon>
                  Yes
                </Button>
                <Button
                  style={{
                    backgroundColor: 'white',
                    color: '#008bbf',
                    width: 100,
                    borderRadius: 5,
                    border: '1px solid #008bbf',
                    paddingLeft: 7,
                    display: 'flex',
                    justifyContent: 'start'
                  }}
                  onClick={() => {
                    insertMoreInformation('no');
                  }}
                >
                  <CloseIcon
                    style={{ width: 13, marginRight: 10, marginLeft: 5 }}
                  ></CloseIcon>
                  No
                </Button>
              </div>
            ) : viewNumber == 4 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Button
                  style={{
                    backgroundColor: 'white',
                    color: '#008bbf',
                    width: 100,
                    borderRadius: 5,
                    marginBottom: 10,
                    border: '1px solid #008bbf',
                    paddingLeft: 7,
                    display: 'flex',
                    justifyContent: 'start'
                  }}
                  onClick={() => {
                    insertoneOnone('yes');
                  }}
                >
                  <CheckIcon
                    style={{ width: 13, marginRight: 10, marginLeft: 5 }}
                  ></CheckIcon>
                  Yes
                </Button>
                <Button
                  style={{
                    backgroundColor: 'white',
                    color: '#008bbf',
                    width: 100,
                    borderRadius: 5,
                    border: '1px solid #008bbf',
                    paddingLeft: 7,
                    display: 'flex',
                    justifyContent: 'start'
                  }}
                  onClick={() => {
                    insertoneOnone('no');
                  }}
                >
                  <CloseIcon
                    style={{ width: 13, marginRight: 10, marginLeft: 5 }}
                  ></CloseIcon>
                  No
                </Button>
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography>Not likely</Typography>
            <Typography>Very likely</Typography>
          </div>
          {viewNumber == 2 || viewNumber == 3 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '95%'
              }}
            >
              <Button
                style={{
                  backgroundColor: '#008bbf',
                  color: 'white',
                  marginTop: '20px',
                  marginBottom: '40px',
                  width: 20
                }}
                onClick={() => {
                  goNext(viewNumber);
                }}
              >
                Next
              </Button>
            </div>
          ) : viewNumber == 4 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: viewNumber == 1 ? 'center' : 'start',
                width: '100%'
              }}
            >
              <Button
                style={{
                  backgroundColor: '#008bbf',
                  color: 'white',
                  marginTop: '20px',
                  marginBottom: '40px',
                  width: 100,
                  fontSize: 15
                }}
                onClick={() => submitFunction()}
              >
                Submit
              </Button>
            </div>
          ) : null} */}
        </DialogContent>
      </div>
    </Dialog>
  );
};

EndingDialog.propTypes = {
  width: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(EndingDialog);
