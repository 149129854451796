import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent
} from '@material-ui/core';
import React, { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const TypeformDialog = props => {
  const { auth, width, roomStatus, url, title } = props;

  useEffect(() => {});

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (!url) return null;
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={() => {}}
      maxWidth="lg"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#0299d7',
            width: '100%',
            paddingBottom: 10,
            paddingTop: 10
          }}
        >
          <div
            style={{
              backgroundColor: '#0299d7',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              marginLeft: 20
            }}
          >
            {title ? (
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: 'white'
                }}
              >
                {title}
              </Typography>
            ) : null}
          </div>
          <div
            style={{
              alignSelf: 'flex-end'
            }}
          >
            <IconButton
              style={{ width: '24px', height: '32px', marginRight: '10px' }}
              onClick={event => {
                if (props.onClose) props.onClose();
              }}
            >
              <CloseIcon
                style={{ width: '16px', height: '16px', color: 'white' }}
                fontSize="small"
              />
            </IconButton>
          </div>
        </div>

        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 0,
            width: '100%'
          }}
        >
          <div style={{ width: '100%' }}>
            <div
              style={{
                width: width > 768 ? '700px' : '100%',
                height: width > 768 ? '540px' : '90vh'
              }}
              dangerouslySetInnerHTML={{
                __html:
                  '<iframe src="' +
                  url +
                  '" width="100%" height="100%"></iframe><script> (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() </script>'
              }}
            />
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

TypeformDialog.propTypes = {
  width: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  url: PropTypes.string,
  title: PropTypes.string
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(TypeformDialog);
