import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import useWindowDimensions from '../../hooks/windowDimensions';
import appBarBackground from '../../assets/appbar_background.png';
import titeLogo from '../../assets/title_logo.png';
import {
  authenticate,
  setSessionId,
  setCompanyName,
  setDisplayName
} from '../../store/actions/auth';
import queryString from 'querystring';
import { getOngoingRoomStatus } from '../../api/rooms';
import Loader from '../../component/loader/Loader';
import { setRoomData } from '../../store/actions/roomStatus';
import OngoingRoom from '../../component/ongoingRoom/OngoingRoom';
import jwt_decode from 'jwt-decode';

const Ongoing = props => {
  const { height } = useWindowDimensions();
  const [loading, setLoading] = React.useState(true);
  const [roomStatus, setRoomStatus] = React.useState(null);
  const {
    match,
    authenticate,
    setRoomData,
    setSessionId,
    setCompanyName,
    setDisplayName
  } = props;
  const roomId = match.params.id;
  const queryParams = queryString.decode(props.location.search.substring(1));

  useEffect(() => {
    if (loading) {
      try {
        var payload = jwt_decode(queryParams.token);
        authenticate(payload, queryParams.token);
      } catch (error) {
        console.log(error);
      }

      authenticate();
      getOngoingRoomStatus(queryParams.token, roomId)
        .then(res => {
          if (res) {
            setSessionId(res.user_status_id);
            setCompanyName(res.company);
            setDisplayName(res.display_name);
            setRoomData(res);
            setRoomStatus(res);
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    }
  }, []);

  const appBarRender = (
    <AppBar position="fixed">
      <Toolbar
        style={{
          backgroundImage: `url(${appBarBackground})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <img src={titeLogo} height="80%" />
      </Toolbar>
    </AppBar>
  );
  if (!queryParams.token)
    return (
      <div>
        {appBarRender}
        <div
          style={{
            height: `${height * 0.8}px`,
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography style={{ marginLeft: '20px', textAlign: 'center' }}>
            Looks like you have no access, please ask for another link
          </Typography>
        </div>
      </div>
    );

  if (loading)
    return (
      <div>
        <div
          style={{
            height: `${height * 0.8}px`,
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader />
        </div>
      </div>
    );

  if (!roomStatus)
    return (
      <div>
        {appBarRender}
        <div
          style={{
            height: `${height * 0.8}px`,
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography style={{ marginLeft: '20px', textAlign: 'center' }}>
            Session not found
          </Typography>
        </div>
      </div>
    );
  return (
    <OngoingRoom
      firstLoad={roomStatus ? roomStatus.first_load : null}
      startWatchTime={roomStatus ? roomStatus.start_watch_time : null}
      roomId={roomId}
    />
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch =>
    bindActionCreators(
      {
        authenticate: (payload, jwt) => authenticate(payload, jwt),
        setRoomData: data => setRoomData(data),
        setSessionId: id => setSessionId(id),
        setCompanyName: company => setCompanyName(company),
        setDisplayName: displayName => setDisplayName(displayName)
      },
      dispatch
    )
)(Ongoing);
