import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
const styles = {
  noncenter: {
    display: 'flex'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  root: {
    position: 'relative'
  },
  top: {
    color: '#d9d9d9'
  },
  bottom: {
    color: '#008bbf',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  }
};
const Loader = props => {
  const { classes, size, center } = props;
  return (
    <div className={center ? classes.container : classes.noncenter} {...props}>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.top}
          size={size}
          thickness={4}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.bottom}
          size={size}
          thickness={4}
        />
      </div>
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.number,
  center: PropTypes.bool
};

Loader.defaultProps = {
  size: 32,
  center: true
};

export default withStyles(styles)(Loader);
