import React, { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Live from '../pages/live/Live';
import theme from './Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Recording from '../pages/recording/Recording';
import PrivateRoom from '../pages/proom/PrivateRoom';
import Timeline from '../pages/timeline/Timeline';
import PreMasterclassEmp from '../pages/masterclass/PreMasterclassEmp';
import Ongoing from '../pages/ongoing/Ongoing';
import PreMasterclass from '../pages/masterclass/PreMasterclass';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLocalSession } from '../store/actions/localSession';
import axios from '../api/axios';
import TeaserPage from '../pages/teaserPage/TeaserPage';

const App = props => {
  const { setLocalSession } = props;
  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then(ipRes => {
        if (ipRes && ipRes.data) {
          setLocalSession({
            ip: ipRes.data.ip,
            countryCode: ipRes.data.country
          });
        }
      })
      .catch(error => {});
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Route path={`/recordings/:id`} component={Recording} />
      </BrowserRouter>
      <BrowserRouter>
        <Route path={`/video/:id`} component={PrivateRoom} />
      </BrowserRouter>
      <BrowserRouter>
        <Route path={`/timeline`} component={Timeline} />
      </BrowserRouter>
      <BrowserRouter>
        <Route path={`/masterclass`} component={PreMasterclassEmp} />
      </BrowserRouter>
      <BrowserRouter>
        <Route path={`/mc2021`} component={PreMasterclass} />
      </BrowserRouter>
      <BrowserRouter>
        <Route path={`/sneakpeek/:id`} component={TeaserPage} />
      </BrowserRouter>
      <BrowserRouter>
        <Route path={`/ongoing/:id`} component={Ongoing} />
      </BrowserRouter>
      <BrowserRouter>
        <Route path={`/:token`} component={Live} />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({ setLocalSession }, dispatch)
)(App);
