export const getNextWebcastsRegisterLink = (region, segment, wave) => {
  return null;
  if (!region || !segment || !wave) return null;
  var segmentStr = segment;
  if (segment.includes(' ')) {
    segmentStr = segment.split(' ')[0];
  }
  if (region == 'APJ') {
    if (wave == '1') {
      if (segmentStr == 'Commercial') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=6f423320-a626-8204-483a-f2d544434593&sender_email=web_platform`;
      } else if (segmentStr == 'Labels') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=ce4d67a4-6457-d6b0-4310-192a9e91f176&sender_email=web_platform`;
      } else if (segmentStr == 'Flexible') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=837e8195-43f2-c982-299c-9c7987748e10&sender_email=web_platform`;
      } else if (segmentStr == 'Folding') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=fe176e07-1355-5206-b8a5-1aaa2a12a677&sender_email=web_platform`;
      }
    } else if (wave == '2') {
      if (segmentStr == 'Commercial') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=44f58041-55c1-e1be-85d7-dab68cb9d472&sender_email=web_platform`;
      } else if (segmentStr == 'Labels') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=05a3d9b3-7906-c1c2-5831-e32c70150877&sender_email=web_platform`;
      } else if (segmentStr == 'Flexible') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=b8952eca-4303-5983-05f4-0df941faf437&sender_email=web_platform`;
      }
    } else if (wave == '3') {
      if (segmentStr == 'Commercial') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=ada1cea6-a23a-068f-0da6-a85c70b8c2da&sender_email=web_platform`;
      } else if (segmentStr == 'Labels') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=aaaf08d4-0edd-e315-4c78-d26e0ca84ed8&sender_email=web_platform`;
      }
    }
  } else if (region == 'EMEA' || region == 'NA' || region == 'LA') {
    if (wave == '1') {
      if (segmentStr == 'Commercial') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=8066bc34-437b-a7de-c1b8-550fe0a1f919&sender_email=web_platform`;
      } else if (segmentStr == 'Labels') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=d2e463fb-0231-31a2-ce3c-6fc6499efbfa&sender_email=web_platform`;
      } else if (segmentStr == 'Flexible') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=d1316bfd-70ef-2a5a-a852-634a64b94be2&sender_email=web_platform`;
      } else if (segmentStr == 'Folding') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=39a2f614-bf40-5417-0079-351a3393811a&sender_email=web_platform`;
      }
    } else if (wave == '2') {
      if (segmentStr == 'Commercial') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=43f948cc-5938-3e0b-a427-177b23bf399e&sender_email=web_platform`;
      } else if (segmentStr == 'Labels') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=02792eb7-9f6b-e682-339c-ba4849e8a24a&sender_email=web_platform`;
      } else if (segmentStr == 'Flexible') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=ca808b6b-223b-720e-a8da-875fbb32121d&sender_email=web_platform`;
      }
    } else if (wave == '3') {
      if (segmentStr == 'Commercial') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=b9d8a045-98ec-b102-2a03-23ccbb5e23f6&sender_email=web_platform`;
      } else if (segmentStr == 'Labels') {
        return `https://usestrive.typeform.com/to/qExHVN?region=${region}&segment=${segmentStr}&room_id=09dbc50d-8fcc-7cb4-5f9d-4c442a909f3f&sender_email=web_platform`;
      }
    }
  }
  return null;
};

export const getOrgTypeOptions = () => {
  return [
    { value: 'Print Service Provider', label: 'Print Service Provider' },
    {
      value: 'Label and/or Packaging Converter',
      label: 'Label and/or Packaging Converter'
    },
    {
      value: 'HP Indigo Solutions Partner',
      label: 'HP Indigo Solutions Partner'
    },
    { value: 'HP Indigo Channel Partner', label: 'HP Indigo Channel Partner' },
    { value: 'Equipment Manufacturer', label: 'Equipment Manufacturer' },
    { value: 'Brand', label: 'Brand' },
    {
      value: 'Marketing Services Provider',
      label: 'Marketing Services Provider'
    },
    { value: 'Media/Industry research', label: 'Media/Industry research' },
    {
      value: 'Education organization (University, College etc.)',
      label: 'Education organization (University, College etc.)'
    },
    { value: 'Other', label: 'Other' }
  ];
};

export const getNextWebcastsData = (region, segment, wave) => {
  return [];
  if (!region || !segment || !wave) return [];
  if (region == 'APJ') {
    switch (segment.toLowerCase()) {
      case 'commercial':
        if (wave == 1) {
          return [
            {
              date: '20 May',
              name: 'Webcast 2',
              time: '13:00 pm SGT',
              title: 'High Value Applications'
            },
            {
              date: '4 June',
              name: 'Webcast 3',
              time: '13:00 pm SGT',
              title: 'Productivity and Automation'
            },
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '13:00 pm SGT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 2) {
          return [
            {
              date: '4 June',
              name: 'Webcast 3',
              time: '13:00 pm SGT',
              title: 'Productivity and Automation'
            },
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '13:00 pm SGT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 3) {
          return [
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '13:00 pm SGT',
              title: 'Open Q&A with Alon'
            }
          ];
        }
        break;

      case 'labels':
        if (wave == 1) {
          return [
            {
              date: '20 May',
              name: 'Webcast 2',
              time: '14:30 pm SGT',
              title: 'Volume Production with HP Indigo Digital Solutions'
            },
            {
              date: '4 June',
              name: 'Webcast 3',
              time: '14:30 pm SGT',
              title: 'Print More Applications'
            },
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '14:30 pm SGT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 2) {
          return [
            {
              date: '4 June',
              name: 'Webcast 3',
              time: '14:30 pm SGT',
              title: 'Print More Applications'
            },
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '14:30 pm SGT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 3) {
          return [
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '14:30 pm SGT',
              title: 'Open Q&A with Alon'
            }
          ];
        }
        break;

      case 'folding cartons':
        if (wave == 1) {
          return [
            {
              date: '10 June',
              name: 'Webcast 2',
              time: '13:00 pm SGT',
              title: 'Open Q&A with Alon'
            }
          ];
        }
        break;

      case 'flexible packaging':
        if (wave == 1) {
          return [
            {
              date: '26 May',
              name: 'Webcast 2',
              time: '14:30 pm SGT',
              title: 'Jump on a Fast Moving Train'
            },
            {
              date: '10 June',
              name: 'Webcast 3',
              time: '13:00 pm SGT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 2) {
          return [
            {
              date: '10 June',
              name: 'Webcast 3',
              time: '13:00 pm SGT',
              title: 'Open Q&A with Alon'
            }
          ];
        }
        break;
    }
  } else if (region == 'AMERICA') {
    switch (segment.toLowerCase()) {
      case 'commercial':
        if (wave == 1) {
          return [
            {
              date: '20 May',
              name: 'Webcast 2',
              time: '10:30 am EDT',
              title: 'High Value Applications'
            },
            {
              date: '4 June',
              name: 'Webcast 3',
              time: '10:30 am EDT',
              title: 'Productivity and Automation'
            },
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '10:30 am EDT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 2) {
          return [
            {
              date: '4 June',
              name: 'Webcast 3',
              time: '10:30 am EDT',
              title: 'Productivity and Automation'
            },
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '10:30 am EDT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 3) {
          return [
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '10:30 am EDT',
              title: 'Open Q&A with Alon'
            }
          ];
        }
        break;

      case 'labels':
        if (wave == 1) {
          return [
            {
              date: '20 May',
              name: 'Webcast 2',
              time: '12:00 noon EDT',
              title: 'Volume Production with HP Indigo Digital Solutions'
            },
            {
              date: '4 June',
              name: 'Webcast 3',
              time: '12:00 noon EDT',
              title: 'Print More Applications'
            },
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '11:30 am EDT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 2) {
          return [
            {
              date: '4 June',
              name: 'Webcast 3',
              time: '12:00 noon EDT',
              title: 'Print More Applications'
            },
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '11:30 am EDT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 3) {
          return [
            {
              date: '18 June',
              name: 'Webcast 4',
              time: '11:30 am EDT',
              title: 'Open Q&A with Alon'
            }
          ];
        }
        break;

      case 'folding cartons':
        if (wave == 1) {
          return [
            {
              date: '10 June',
              name: 'Webcast 2',
              time: '11:30 am EDT',
              title: 'Open Q&A with Alon'
            }
          ];
        }
        break;

      case 'flexible packaging':
        if (wave == 1) {
          return [
            {
              date: '27 May',
              name: 'Webcast 2',
              time: '12:00 noon EDT',
              title: 'Jump on a Fast Moving Train'
            },
            {
              date: '10 June',
              name: 'Webcast 3',
              time: '12:00 noon EDT',
              title: 'Open Q&A with Alon'
            }
          ];
        } else if (wave == 2) {
          return [
            {
              date: '10 June',
              name: 'Webcast 3',
              time: '12:00 noon EDT',
              title: 'Open Q&A with Alon'
            }
          ];
        }
        break;
    }
  }
  return [];
};
