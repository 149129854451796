export const AUTHENTICATE = 'AUTHENTICATE';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_TITLE = 'SET_TITLE';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_ORG_TYPE = 'SET_ORG_TYPE';
