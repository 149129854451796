import React, { useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Tooltip
} from '@material-ui/core';
import useWindowDimensions from '../../hooks/windowDimensions';
import queryString from 'querystring';
import Loader from '../../component/loader/Loader';
import jwt_decode from 'jwt-decode';
import chatSettingsIcon from '../../assets/svg_icons/chat_settings_icon.svg';
import ChatSettingsDialog from '../../component/chatSettingsDialog/ChatSettingsDialog';
import { authenticate } from '../../store/actions/auth';
import EntryFormDialog from '../../component/entryFormDialog/EntryFormDialog';
import {
  setEmail,
  setDisplayName,
  setCompanyName,
  setCountryCode,
  setLanguage
} from '../../store/actions/auth';
import { isValidEmail } from '../../utils/StringUtils';
import titeLogo from '../../assets/indigo_logo_1.png';
import background from '../../assets/live_background.png';
import './PrivateRoomStyles.css';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import AgoraRTC from 'agora-rtc-sdk';

const PrivateRoom = props => {
  const { height, width } = useWindowDimensions();
  const { match, authenticate, auth } = props;
  const queryParams = queryString.decode(props.location.search.substring(1));
  const token = queryParams.token;
  const [newCredentials, setNewCredentials] = React.useState(null);
  const [settingsCredentials, setSettingsCredentials] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [bootIntercom, setBootIntercom] = React.useState(true);
  const [localStreamAudio, setLocalStreamAudio] = React.useState(false);
  const [localStreamVideo, setLocalStreamVideo] = React.useState(true);
  const [client, setClient] = React.useState(null);
  const [incomingRemoteStream, setIncomingRemoteStream] = React.useState(null);
  const [localStream, setLocalStream] = React.useState(null);
  const [remoteStream, setRemoteStream] = React.useState(null);
  const [videoReady, setVideoReady] = React.useState(false);
  const roomId = match.params.id;

  const onStreamAdded = evt => {
    console.log('~~~~~~ STREAM ADDED ~~~~~~');
    setIncomingRemoteStream(evt.stream);
  };

  useEffect(() => {
    if (!videoReady) {
      const video = document.getElementById('local_stream');
      if (video) {
        setVideoReady(true);
      }
    }
    if (auth.email && videoReady && !client) {
      const newClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' });
      AgoraRTC.Logger.disableLogUpload();
      newClient.init('a176a69286c94d9ba11c79498ac2e1b7', () => {
        console.log('client init');
        setClient(newClient);
        newClient.join(
          '006a176a69286c94d9ba11c79498ac2e1b7IACrC8bjdeLH0gv5ioj5jANs4V0hh3fmoWOFOJF0K5nqSTJAiIMAAAAAEADpEZp3I3TrXgEAAQAidOte',
          'test_channel_2',
          null,
          uid => {
            console.log('join with uid', uid);
            const lclSteam = AgoraRTC.createStream({
              streamID: uid,
              audio: true,
              video: true,
              screen: false
            });
            setLocalStream(lclSteam);
            lclSteam.init(
              () => {
                setLoading(false);
                lclSteam.disableAudio();
                lclSteam.play('local_stream', { fit: 'contain' }, function(
                  errState
                ) {
                  console.log(errState);
                  if (errState && errState.status !== 'aborted') {
                    // setError(errState);
                    // The playback fails, probably due to browser policy. You can resume the playback by user gesture.
                  }
                });
                newClient.publish(lclSteam, err => {
                  console.log('public error?', err);
                  if (err) {
                    setError({ ERROR: 'client.publish()', error: err });
                  }
                });
              },
              err => {
                setError({ ERROR: 'stream.init()', error: err });
                setLoading(false);
                console.log('stream failed', err);
              }
            );
            newClient.on('stream-added', onStreamAdded);
            newClient.on('peer-leave', evt => {
              setRemoteStream(null);
              var list = document.getElementById('remote_stream');
              if (list && list.hasChildNodes()) {
                list.removeChild(list.childNodes[0]);
              }
            });
            newClient.on('stream-subscribed', evt => {
              console.log('~~~~~~ STREAM SUBSCRIBED ~~~~~~');
              const rmtStream = evt.stream;
              rmtStream.play('remote_stream', { fit: 'contain' }, function(
                errState
              ) {
                console.log('error remote stream??', errState);
                if (errState && errState.status !== 'aborted') {
                  // setError(errState);
                  // The playback fails, probably due to browser policy. You can resume the playback by user gesture.
                }
              });
              setRemoteStream(rmtStream);
            });
          }
        );
      });
    } else if (
      auth.email &&
      videoReady &&
      incomingRemoteStream &&
      localStream
    ) {
      console.log('HANDLING STREAM ADDED@@@@@@');
      if (!remoteStream) {
        console.log('SUBSCRIBING!@!@!!');
        client.subscribe(
          incomingRemoteStream,
          { video: true, audio: true },
          err => {
            console.log('error in sub', err);
          }
        );
      }
      setIncomingRemoteStream(null);
    }
  }, [auth.email, videoReady, client, incomingRemoteStream]);

  if (bootIntercom && window.Intercom) {
    window.Intercom('boot');
    setBootIntercom(false);
  }

  var decodedJwtPayload;
  if (token) {
    decodedJwtPayload = jwt_decode(token);
  }
  if (!newCredentials && decodedJwtPayload && !settingsCredentials) {
    authenticate(decodedJwtPayload, token);
    setNewCredentials(decodedJwtPayload);
  } else if (
    newCredentials &&
    settingsCredentials &&
    (settingsCredentials.email != newCredentials.email ||
      settingsCredentials.language != newCredentials.language)
  ) {
    authenticate(settingsCredentials);
    setNewCredentials(settingsCredentials);
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'start',
        backgroundSize: 'fit',
        backgroundRepeat: 'no-repeat',
        color: 'white'
      }}
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <Typography style={{ marginTop: '100px', fontSize: 18 }}>
          error {JSON.stringify(error)}
        </Typography>
      ) : (
        <div>
          <img
            src={titeLogo}
            height="44px"
            style={{ marginRight: '20px', marginLeft: '5px' }}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '50px'
          }}
        >
          <div
            id="local_stream"
            class="video-placeholder"
            style={{ width: '400px', height: '300px' }}
          ></div>
          {!loading && (
            <div style={{ display: 'flex' }}>
              <IconButton
                onClick={event => {
                  if (localStream) {
                    if (localStreamAudio) localStream.disableAudio();
                    else localStream.enableAudio();
                    setLocalStreamAudio(!localStreamAudio);
                  }
                }}
              >
                {localStreamAudio ? <MicIcon /> : <MicOffIcon />}
              </IconButton>

              <IconButton
                onClick={event => {
                  if (localStream) {
                    if (localStreamVideo) localStream.disableVideo();
                    else localStream.enableVideo();
                    setLocalStreamVideo(!localStreamVideo);
                  }
                }}
              >
                {localStreamVideo ? <VideocamIcon /> : <VideocamOffIcon />}
              </IconButton>
            </div>
          )}
        </div>

        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            Other
            <div
              id="remote_stream"
              class="video-placeholder"
              style={{ width: '400px', height: '300px' }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch =>
    bindActionCreators(
      {
        authenticate: (payload, jwt) => authenticate(payload, jwt),
        setDisplayName: displayName => setDisplayName(displayName),
        setEmail: email => setEmail(email),
        setCompanyName: companyName => setCompanyName(companyName),
        setCountryCode: countryCode => setCountryCode(countryCode),
        setLanguage: language => setLanguage(language)
      },
      dispatch
    )
)(PrivateRoom);
