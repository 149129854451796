import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useSubscription, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import UserSession from '../userSession/UserSession';
import { Button, Typography } from '@material-ui/core';
import QaList from './QaList';
import useWindowDimensions from '../../hooks/windowDimensions';
import Loader from '../loader/Loader';
import ChatField from '../chatField/ChatField';
import {
  setQaNotifyCount,
  setQaNotifyStatus
} from '../../store/actions/roomStatus';

const QaTab = props => {
  const {
    chatDisabled,
    isMobile,
    loading,
    drawerWidth,
    error,
    videoDidEnd,
    onSettingsClick,
    mobileHeight,
    setQaNotifyCount,
    setQaNotifyStatus,
    auth,
    roomStatus
  } = props;
  const { width, height } = useWindowDimensions();
  useEffect(
    () => () => {
      setQaNotifyStatus('outside');
      setQaNotifyCount(0);
    },
    []
  );
  useEffect(() => {
    setQaNotifyStatus('inside');
    setQaNotifyCount(0);
  }, []);

  const ADD_QA_COMMENT = gql`
  mutation addQaComment ( $commentText: String!, $anonymous: Boolean!) {
    insert_db_main_indigo_live_qa(objects: {session_id: ${auth.session_id}, invite_id:${auth.invite_id} room_id: "${auth.room_id}", message: $commentText, is_anonymous:$anonymous}){
      affected_rows
    }
  }
`;
  const [addQaComment] = useMutation(ADD_QA_COMMENT);

  const commentSendSection =
    loading && false ? (
      <Loader />
    ) : error ? (
      <div>Error</div>
    ) : !roomStatus.roomFound && !loading ? (
      <div>Cannot be found</div>
    ) : (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '20%',
          fontSize: 14
        }}
      >
        {loading || chatDisabled ? ( //videoDidEnd
          <Typography
            component={'span'}
            style={{
              border: '1px solid grey',
              borderRadius: '5px',
              width: (width <= 800 ? width * 0.95 : drawerWidth * 0.95) - 2,
              fontSize: 12,
              fontWeight: 'bold',
              backgroundColor: '#d6d6d6'
            }}
          >
            <div
              style={{
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {loading ? (
                <Loader
                  size={16}
                  center={false}
                  style={{ marginRight: '10px', marginTop: '2px' }}
                />
              ) : null}
              {loading ? (
                <div>Connecting to chat</div>
              ) : videoDidEnd ? (
                <div>Room has ended</div>
              ) : (
                <div>Waiting for room to start</div>
              )}
            </div>
          </Typography>
        ) : (
          <div style={{ height: '16px' }} />
        )}
        <ChatField
          isQa={true}
          disabled={loading || chatDisabled || !auth.session_id} //videoDidEnd
          width={width}
          onSend={(comment, gif, anonymous) => {
            return addQaComment({
              variables: {
                commentText: comment,
                anonymous: anonymous
              }
            });
          }}
          onSettingsClick={() => {
            if (onSettingsClick) onSettingsClick();
          }}
        ></ChatField>
      </div>
    );
  return (
    <div
      style={{
        height: mobileHeight ? mobileHeight : '100%',
        maxHeight: mobileHeight,
        minHeight: mobileHeight,
        backgroundColor: 'white',
        marginTop: '10px'
      }}
    >
      <QaList
        width={width > 800 ? 400 : width / 2}
        chatDisabled={chatDisabled}
        isMobile={isMobile}
      />
      {!auth.is_organizer && commentSendSection}
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch =>
    bindActionCreators(
      {
        setQaNotifyCount,
        setQaNotifyStatus
      },
      dispatch
    )
)(QaTab);
