import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography } from '@material-ui/core';
import useWindowDimensions from '../../hooks/windowDimensions';

const styles = theme => ({});

const WebcastCard = props => {
  const { webcastName, webcastDate, webcastTime, webcastTitle } = props;
  const { width } = useWindowDimensions();
  const fontsize = width > 768 ? '20px' : '12px';
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: width > 768 ? 20 : 5
      }}
    >
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          borderLeft: '20px solid #004a78',
          width: width > 768 ? '500px' : '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '20px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              style={{
                fontSize: fontsize,
                fontWeight: 'bold',
                color: '#004a78'
              }}
            >
              {webcastName}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'Column',
              marginLeft: 35,
              alignItems: 'start'
            }}
          >
            <Typography
              style={{
                fontSize: fontsize,
                color: 'white',
                textAlign: 'left'
              }}
            >
              {webcastDate}
            </Typography>
            <Typography
              style={{
                fontSize: fontsize,
                color: 'white',
                textAlign: 'left'
              }}
            >
              {webcastTime}
            </Typography>
            <Typography
              style={{
                fontSize: fontsize,
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'left'
              }}
            >
              {webcastTitle}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

WebcastCard.propTypes = {
  webcastName: PropTypes.string,
  webcastDate: PropTypes.string,
  webcastTime: PropTypes.string,
  webcastTitle: PropTypes.string
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(withStyles(styles)(WebcastCard));
