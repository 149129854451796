import { SET_LOCAL_SESSION, UPDATE_AUTO_FILLED } from '../types/localSession';

export const setLocalSession = data => {
  return dispatch => {
    dispatch({
      type: SET_LOCAL_SESSION,
      payload: data
    });
  };
};

export const updateAutoFilled = () => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTO_FILLED
    });
  };
};
