//handles invite state
import {
  AUTHENTICATE,
  SET_SESSION_ID,
  SET_DISPLAY_NAME,
  SET_EMAIL,
  SET_COMPANY_NAME,
  SET_COUNTRY_CODE,
  SET_LANGUAGE,
  SET_TITLE,
  SET_PHONE_NUMBER,
  SET_ORG_TYPE
} from '../types/auth';
const INITIAL_STATE = {
  authenticated: false,
  display_name: null,
  email: null,
  invite_id: null,
  region: null,
  vidyard_uuid: null,
  room_id: null,
  jwt: null,
  session_id: null,
  company_name: null,
  country_code: null,
  language: null,
  title: null,
  phone_number: null,
  org_type: null
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATE: {
      const { payload, jwt } = action;
      if (payload) {
        return {
          ...state,
          authenticated: true,
          display_name: payload.display_name,
          email: payload.email,
          invite_id: payload.id,
          region: payload.region,
          room_id: payload.room_id,
          company_name: payload.company_name,
          language: payload.language,
          title: payload.title,
          phone_number: payload.phone_number,
          country_code: payload.country_code,
          org_type: payload.org_type,
          is_organizer: payload.is_organizer,
          jwt: jwt
        };
      }
      return state;
    }

    case SET_SESSION_ID: {
      const { payload } = action;
      if (payload) {
        return {
          ...state,
          session_id: payload
        };
      }
      return state;
    }

    case SET_DISPLAY_NAME: {
      const { payload } = action;
      if (payload) {
        return {
          ...state,
          display_name: payload
        };
      }
      return state;
    }

    case SET_EMAIL: {
      const { payload } = action;
      if (payload) {
        return {
          ...state,
          email: payload
        };
      }
      return state;
    }

    case SET_COMPANY_NAME: {
      const { payload } = action;
      return {
        ...state,
        company_name: payload
      };
    }

    case SET_LANGUAGE: {
      const { payload } = action;
      return {
        ...state,
        language: payload
      };
    }

    case SET_COUNTRY_CODE: {
      const { payload } = action;
      return {
        ...state,
        country_code: payload
      };
    }

    case SET_TITLE: {
      const { payload } = action;
      return {
        ...state,
        title: payload
      };
    }

    case SET_PHONE_NUMBER: {
      const { payload } = action;
      return {
        ...state,
        phone_number: payload
      };
    }

    case SET_ORG_TYPE: {
      const { payload } = action;
      return {
        ...state,
        org_type: payload
      };
    }

    default:
      return state;
  }
};
