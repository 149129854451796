import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Popover,
  IconButton,
  TextField,
  Button,
  Tooltip
} from '@material-ui/core';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { bindActionCreators } from 'redux';
import UserSession from '../userSession/UserSession';
import CloseIcon from '@material-ui/icons/Close';
import UserSessionLoader from '../userSessionLoader/UserSessionLoader';
import { getTimeFromString } from '../../utils/TimeUtils';
import hpLogo from '../../assets/hp_logo.png';
import useWindowDimensions from '../../hooks/windowDimensions';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Flag from 'react-world-flags';
import thumbsUpEmptyIcon from '../../assets/thumbsup-empty.png';
import thumbsUpFullIcon from '../../assets/thumbsup-full.png';

const httpMsg = function(content) {
  const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  return content.replace(reg, "<a target='_blank' href='$1$2'>$1$2</a>");
};

const QaComment = props => {
  const [deleting, setDeleting] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null); //for user session popover
  const popoverOpen = Boolean(anchorEl); //for user session popover
  const [highlight, setHighlight] = React.useState(false);
  const [userLikeId, setUserLikeId] = React.useState(null);
  const { width } = useWindowDimensions();
  const { classes, auth, chatDisabled, onActionClicked } = props;
  const renderMessage = function(content) {
    return (
      <span>
        <span
          dangerouslySetInnerHTML={{
            __html: httpMsg(content)
          }}
        />
      </span>
    );
  };

  const ADD_LIKE = gql`
    mutation addLike($commentId: Int!, $sessionId: Int!) {
      insert_db_main_indigo_live_qa_likes(
        objects: { chat_id: $commentId, session_id: $sessionId }
      ) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
  const [addLike] = useMutation(ADD_LIKE);

  const DELETE_LIKE = gql`
    mutation deleteLike($likeId: bigint!) {
      delete_db_main_indigo_live_qa_likes(where: { id: { _eq: $likeId } }) {
        affected_rows
      }
    }
  `;
  const [deleteLike] = useMutation(DELETE_LIKE);

  const comment = props.comment;
  useEffect(() => {
    if (auth.session_id && comment && comment.indigo_live_qa_likes) {
      var newUserLikeId = null;
      for (var like of comment.indigo_live_qa_likes) {
        if (like && like.session_id == auth.session_id) {
          newUserLikeId = like.id;
          break;
        }
      }
      if (userLikeId != newUserLikeId) setUserLikeId(newUserLikeId);
    }
  }, [comment, auth.session_id]);
  const DELETE_COMMENT = gql`
    mutation deleteComment {
      update_db_main_indigo_live_qa(
        where: { id: { _eq: ${comment.id} } }
        _set: { deleted_at: "now()" }
      ) {
        affected_rows
      }
    }
  `;
  const [deleteComment] = useMutation(DELETE_COMMENT);

  const UPDATE_COMMENT_PRIORITY = gql`
     mutation deleteComment($priority: Int!) {
      update_db_main_indigo_live_qa(
        where: { id: { _eq: ${comment.id} } }
        _set: { priority_level: $priority }
      ) {
        affected_rows
      }
    }
  `;
  const [updateCommentPriority] = useMutation(UPDATE_COMMENT_PRIORITY);
  if (!comment || !comment.indigo_live_active_session) return null;
  var showName =
    !comment.is_anonymous ||
    (comment.is_anonymous &&
      comment.indigo_live_active_session.id == auth.session_id);
  return (
    <div style={props.style}>
      <div
        style={{
          backgroundColor: highlight ? '#efeff1' : null,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          maxWidth: `${width <= 800 ? width - 50 : 370}px`,
          minWidth: `${width <= 800 ? width - 50 : 370}px`,
          marginLeft: '20px',
          marginRight: '20px',
          width: '100%'
        }}
      >
        {/* name and message column */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: `${width <= 800 ? width - 50 : 370}px`
          }}
        >
          {/* NAME first row */}
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginBottom: '5px',
              marginLeft: '2px'
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
                fontFamily: 'Roboto',
                color: showName
                  ? props.comment.indigo_live_active_session.color || '#000000'
                  : '#000000',
                fontSize: 16,
                cursor: 'pointer',
                marginRight: '3px'
              }}
              onClick={event => {
                if (!showName) return;
                if (props.comment && props.comment.indigo_live_active_session)
                  setAnchorEl(event.currentTarget);
              }}
            >
              {showName
                ? props.comment.indigo_live_active_session.display_name +
                  (comment.is_anonymous ? ' [anonymous]' : '')
                : 'Anonymous'}
            </span>
            {showName &&
            props.comment.indigo_live_active_session.country_code ? (
              <Flag
                style={{
                  marginRight: '3px',
                  width: '20px',
                  height: '20px'
                }}
                code={props.comment.indigo_live_active_session.country_code}
              />
            ) : null}
            {showName &&
            props.comment.indigo_live_active_session.email &&
            props.comment.indigo_live_active_session.email.includes(
              '@hp.com'
            ) ? (
              <img
                src={hpLogo}
                style={{
                  marginRight: '3px',
                  width: '20px',
                  height: '20px'
                }}
              />
            ) : (
              <span
                style={{
                  color: '#A3A8AB',
                  fontFamily: 'Roboto',
                  marginLeft: '3px'
                }}
              >
                {props.comment.indigo_live_active_session.company_name}
              </span>
            )}
          </span>

          {/* MESSAGE second row */}
          {props.comment.indigo_live_active_session.title ? (
            <span
              style={{
                fontFamily: 'Roboto',
                fontSize: 12,
                marginRight: '3px',
                marginLeft: '3px',
                marginBottom: '10px',
                color: 'grey'
              }}
            >
              {props.comment.indigo_live_active_session.title
                ? '(' + props.comment.indigo_live_active_session.title + ')'
                : null}
            </span>
          ) : null}

          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginBottom: '12px',
              marginLeft: '2px'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: 'Roboto',
                wordWrap: 'break-word',
                maxWidth: `${width <= 800 ? width - 140 : 350}px`,
                minWidth: `${width <= 800 ? width - 140 : 350}px`
              }}
            >
              {renderMessage(props.comment.message)}
            </span>
          </span>

          {/* ACTIONS third row */}
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontFamily: 'Roboto',
              fontSize: 12,
              alignItems: 'center'
            }}
          >
            <span style={{ color: '#A3A8AB', marginRight: '10px' }}>
              {getTimeFromString(props.comment.created_at)}
            </span>
            {(auth.is_organizer ||
              comment.indigo_live_active_session.id == auth.session_id) &&
              (deleting ? (
                <span
                  style={{
                    marginRight: '10px',
                    color: '#292C32',
                    fontWeight: 'bold'
                  }}
                >
                  Are you sure?
                  <Tooltip title="Keep question">
                    <IconButton
                      size={'small'}
                      onClick={() => {
                        if (saving) return;
                        setDeleting(false);
                      }}
                      disabled={chatDisabled}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete question">
                    <IconButton
                      size={'small'}
                      onClick={() => {
                        if (saving) return;
                        setSaving(true);
                        if (onActionClicked) onActionClicked();
                        deleteComment()
                          .then(() => {
                            setDeleting(false);
                            setSaving(false);
                          })
                          .catch(() => {
                            setSaving(false);
                          });
                      }}
                      disabled={chatDisabled}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              ) : (
                <span
                  style={{
                    marginRight: '10px',
                    color: '#292C32',
                    fontWeight: 'bold',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (chatDisabled) return;
                    setDeleting(true);
                  }}
                >
                  Delete
                </span>
              ))}
          </span>
        </div>

        <Popover
          id={'userSession'}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              style={{ width: '22px', height: '22px', marginRight: '10px' }}
              onClick={event => {
                setAnchorEl(null);
              }}
            >
              <CloseIcon
                style={{ width: '14px', height: '14px' }}
                fontSize="small"
              />
            </IconButton>
          </div>
          <UserSession
            session={
              props.comment ? props.comment.indigo_live_active_session : null
            }
          />
        </Popover>

        {/* like area */}
        <div
          style={{
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            width: '10%',
            justifyContent: 'flex-end'
          }}
        >
          {auth.is_organizer && (
            <Tooltip
              title={comment.priority_level == -1 ? 'Unresolve' : 'Resolve'}
            >
              <IconButton
                size={'small'}
                style={{
                  color: comment.priority_level == -1 ? '#008bbf' : null
                }}
                onClick={() => {
                  if (saving) return;
                  setSaving(true);
                  if (onActionClicked) onActionClicked();
                  updateCommentPriority({
                    variables: {
                      priority: comment.priority_level == -1 ? 0 : -1
                    }
                  })
                    .then(() => {
                      setSaving(false);
                    })
                    .catch(() => {
                      setSaving(false);
                    });
                }}
                disabled={chatDisabled}
              >
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
          )}
          {auth.is_organizer && comment.priority_level != -1 && (
            <Tooltip title={comment.priority_level == 1 ? 'Unpin' : 'Pin'}>
              <IconButton
                size={'small'}
                style={{
                  color: comment.priority_level == 1 ? '#008bbf' : null
                }}
                onClick={() => {
                  if (saving) return;
                  setSaving(true);
                  if (onActionClicked) onActionClicked();
                  updateCommentPriority({
                    variables: {
                      priority: comment.priority_level == 1 ? 0 : 1
                    }
                  })
                    .then(() => {
                      setSaving(false);
                    })
                    .catch(() => {
                      setSaving(false);
                    });
                }}
                disabled={chatDisabled}
              >
                <FontAwesomeIcon icon={faThumbtack} />
              </IconButton>
            </Tooltip>
          )}
          <Typography
            style={{
              // marginRight: '15px',
              color: '#292C32',
              fontWeight: 'bold',
              cursor: 'cursor',
              minWidth: '30px'
            }}
          >
            {comment.indigo_live_qa_likes &&
            comment.indigo_live_qa_likes.length > 0
              ? `+${comment.indigo_live_qa_likes.length}`
              : ''}
          </Typography>
          {!auth.is_organizer &&
            (auth.session_id == comment.indigo_live_active_session.id ? (
              <div style={{ minWidth: '26.5px' }} />
            ) : (
              <IconButton
                size={'small'}
                onClick={() => {
                  if (saving) return;
                  var promise;
                  if (!userLikeId) {
                    promise = addLike({
                      variables: {
                        commentId: comment.id,
                        sessionId: auth.session_id
                      }
                    });
                  } else {
                    promise = deleteLike({ variables: { likeId: userLikeId } });
                  }
                  promise
                    .then(() => {
                      setSaving(false);
                    })
                    .catch(() => {
                      setSaving(false);
                    });
                }}
                disabled={chatDisabled}
              >
                {userLikeId ? (
                  <img src={thumbsUpFullIcon} width="18px" />
                ) : (
                  <img src={thumbsUpEmptyIcon} width="18px" />
                )}
              </IconButton>
            ))}
        </div>
      </div>
    </div>
  );
};

QaComment.propTypes = {
  comment: PropTypes.shape({
    indigo_live_active_session: PropTypes.shape({
      color: PropTypes.string,
      display_name: PropTypes.string.isRequired,
      company_name: PropTypes.string,
      email: PropTypes.string,
      title: PropTypes.string,
      country_code: PropTypes.string
    }),
    message: PropTypes.string.isRequired,
    created_at: PropTypes.any
  }),
  icon: PropTypes.node,
  width: PropTypes.number,
  chatDisabled: PropTypes.bool
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(QaComment);
