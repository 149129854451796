import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  IconButton,
  TextField,
  Button,
  InputAdornment,
  Popper,
  Popover,
  Tooltip,
  Badge,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import emojiButtonIcon from '../../assets/svg_icons/emoji_button.svg';
import gifButtonIcon from '../../assets/gif_icon.png';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Picker from 'emoji-picker-react';
import chatSettingsIcon from '../../assets/svg_icons/chat_settings_icon.svg';
import ReplyIcon from '@material-ui/icons/Reply';
import GifPicker from '../gifPicker/GifPicker';
import { Gif } from '@giphy/react-components';
import Checkbox from '@material-ui/core/Checkbox';

const drawerWidth = 450;

const ChatField = props => {
  const {
    auth,
    width,
    disabled,
    onSend,
    roomStatus,
    isReply,
    disableMentions,
    isQa
  } = props;

  const [mentionsAnchorEl, setMentionsAnchorEl] = React.useState(null); //for mentions popover
  const [mentionOptions, setMentionOptions] = React.useState([]); //for mentions selection popup [sessions{id:,display_name,color:..}]
  const [toSendMentions, setToSendMentions] = React.useState([]); //to send mentions {startIndex, endIndex, sessionId, color, text}
  const [commentText, setCommentText] = useState(''); //for comment textfield
  const [anchorEl, setAnchorEl] = React.useState(null); //for emoji popover
  const open = Boolean(anchorEl); //for emoji popover
  const [sendAsAnonymous, setSendAsAnonymous] = React.useState(false);

  const [gifAnchorEl, setGifAnchorEl] = React.useState(null); //for gif popover
  const gifOpen = Boolean(gifAnchorEl); //for gif popover

  const [sendingComment, setSendingComment] = useState(false);
  const [selectedGif, setSelectedGif] = useState(null);

  const sendCommentHandler = function() {
    if (commentText && commentText.length > 0 && auth.session_id) {
      var parsedComment = commentText;
      var addedLength = 0;
      for (var mention of toSendMentions) {
        if (
          mention &&
          mention.startIndex + addedLength < parsedComment.length
        ) {
          var replacement =
            '<mention>' +
            mention.sessionId.toString() +
            ';' +
            mention.color.toString() +
            ';' +
            mention.text +
            '</mention>';
          var index = mention.startIndex;
          addedLength += replacement.length - mention.text.length;
          parsedComment =
            parsedComment.substr(0, index) +
            replacement +
            parsedComment.substring(mention.endIndex);
        }
      }
      setMentionOptions([]);
      if (onSend) {
        if (!sendingComment) {
          setSendingComment(true);
          onSend(parsedComment, selectedGif, sendAsAnonymous)
            .then(() => {
              setCommentText('');
              setSelectedGif(null);
              setSendingComment(false);
            })
            .catch(error => {
              console.log(error);
              setSendingComment(false);
            });
        }
      }
    } else if (auth.session_id && selectedGif && !isQa) {
      if (onSend) {
        if (!sendingComment) {
          setSendingComment(true);
          onSend('', selectedGif)
            .then(() => {
              setCommentText('');
              setSelectedGif(null);
              setSendingComment(false);
            })
            .catch(error => {
              console.log(error);
              setSendingComment(false);
            });
        }
      }
    }
  };

  const onSendMessageChange = function(comment) {
    if (toSendMentions && toSendMentions.length > 0) {
      //to check if any mentions were removed
      var newMentions = [];
      for (var i = 0; i < toSendMentions.length; i++) {
        const mention = toSendMentions[i];
        if (mention && mention.endIndex && mention.endIndex <= comment.length) {
          newMentions.push(mention);
        }
      }
      setToSendMentions(newMentions);
    }
    if (!disableMentions && comment.includes('@')) {
      var startIndex = comment.indexOf('@');
      var tempComment = comment.substring(startIndex + 1);
      if (tempComment.indexOf(' ') < 0) {
        var matches = roomStatus.roomSessions.filter(
          s =>
            s.display_name.toLowerCase().includes(tempComment.toLowerCase()) &&
            s &&
            s.id &&
            auth.session_id &&
            s.id.toString() !== auth.session_id.toString()
        );
        setMentionOptions(matches.slice(0, 6));
        return;
      }
    }
    setMentionOptions([]);
  };

  return (
    <div>
      {isQa && !auth.is_organizer && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>Send as Anonymous:</Typography>
            <Checkbox
              checked={sendAsAnonymous}
              color="primary"
              onChange={e => {
                setSendAsAnonymous(e.target.checked);
              }}
            />
          </div>
        </div>
      )}
      {((isQa && !auth.is_organizer) || !isQa) && (
        <TextField
          onClick={event => {
            setMentionsAnchorEl(event.currentTarget);
          }}
          id="comment-send-field"
          variant="outlined"
          placeholder="Message ..."
          multiline
          fullWidth
          autoFocus={true}
          disabled={disabled}
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              sendCommentHandler();
              ev.preventDefault();
            }
          }}
          InputProps={{
            style: {
              paddingRight: '10px',
              paddingLeft: '10px',
              fontSize: 12,
              backgroundColor: '#f7f7f7',
              padding: '13px'
            },
            endAdornment: (
              <InputAdornment position="end">
                <div>
                  {!isQa && (
                    <IconButton
                      disabled={disabled}
                      style={{ marginRight: '10px' }}
                      size={'small'}
                      onClick={event => {
                        setGifAnchorEl(event.currentTarget);
                      }}
                    >
                      <Badge
                        variant="dot"
                        invisible={!selectedGif}
                        color="primary"
                      >
                        <img
                          src={gifButtonIcon}
                          style={{ width: '18px', height: '18px' }}
                        />
                      </Badge>
                    </IconButton>
                  )}
                  <IconButton
                    disabled={disabled}
                    size={'small'}
                    onClick={event => {
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <img
                      src={emojiButtonIcon}
                      style={{ width: '18px', height: '18px' }}
                    />
                  </IconButton>
                </div>
              </InputAdornment>
            )
          }}
          style={{
            width: width <= 800 ? width * 0.95 : drawerWidth * 0.95,
            fontSize: 12
          }}
          value={commentText}
          onChange={event => {
            onSendMessageChange(event.target.value);
            setCommentText(event.target.value);
          }}
        ></TextField>
      )}
      {isReply ? (
        <IconButton
          color="primary"
          style={{ padding: 10 }}
          onClick={sendCommentHandler}
        >
          <ReplyIcon />
        </IconButton>
      ) : null}
      {!isReply ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: width <= 800 ? width * 0.95 : drawerWidth * 0.95,
            marginBottom: width <= 800 ? 20 : 0,
            marginTop: '10px'
          }}
        >
          <div>
            <Tooltip title="Settings">
              <span>
                <IconButton
                  disabled={disabled}
                  size={'small'}
                  style={{ marginRight: '10px' }}
                  onClick={event => {
                    if (props.onSettingsClick) props.onSettingsClick();
                  }}
                >
                  <img src={chatSettingsIcon} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
          {((isQa && !auth.is_organizer) || !isQa) && (
            <Button
              disabled={disabled}
              style={{
                backgroundColor: disabled ? '#cfcfcf' : '#008bbf',
                color: disabled ? '#9c9c9c' : 'white'
              }}
              onClick={sendCommentHandler}
            >
              {isQa ? 'Send Question' : 'Chat'}
            </Button>
          )}
        </div>
      ) : null}

      <Popper
        id={'mentionsPicker'}
        open={mentionOptions && mentionOptions.length > 0}
        anchorEl={mentionsAnchorEl}
        style={{ zIndex: 2000 }}
        placement="top"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: width <= 800 ? width * 0.95 : drawerWidth * 0.95,
            backgroundColor: 'white',
            border: '1px solid #efeff1',
            borderRadius: '5px'
          }}
        >
          {mentionOptions.map(m => (
            <Button
              key={m.id}
              fullWidth
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                color: m.color,
                fontSize: 12
              }}
              onMouseDown={ev => {
                var mentionIndex = commentText.lastIndexOf('@');
                var mentionEndIndex = mentionIndex + m.display_name.length + 1;
                var tempComment = commentText.substring(0, mentionIndex);
                tempComment += '@' + m.display_name + ' ';
                setCommentText(tempComment);
                var newMentions = toSendMentions; //{startIndex, endIndex, sessionId, color, text}
                newMentions.push({
                  startIndex: mentionIndex,
                  endIndex: mentionEndIndex,
                  sessionId: m.id,
                  color: m.color,
                  text: tempComment.substring(mentionIndex, mentionEndIndex)
                });
                setToSendMentions(newMentions);
                setMentionOptions([]);
                ev.preventDefault();
              }}
            >
              {m.display_name}
            </Button>
          ))}
        </div>
      </Popper>

      <Popover
        id={'emojiPicker'}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 2010 }}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton
            style={{ width: '24px', height: '32px', marginRight: '10px' }}
            onClick={event => {
              setAnchorEl(null);
            }}
          >
            <CloseIcon
              style={{ width: '16px', height: '16px' }}
              fontSize="small"
            />
          </IconButton>
        </div>
        <Picker
          onEmojiClick={(event, emojiObject) => {
            setCommentText(commentText + emojiObject.emoji);
            setAnchorEl(null);
          }}
        ></Picker>
      </Popover>

      <Popover
        id={'gifPicker'}
        open={gifOpen}
        anchorEl={gifAnchorEl}
        style={{ zIndex: 2010 }}
        onClose={() => {
          setGifAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton
            style={{ width: '24px', height: '32px', marginRight: '10px' }}
            onClick={event => {
              setGifAnchorEl(null);
            }}
          >
            <CloseIcon
              style={{ width: '16px', height: '16px' }}
              fontSize="small"
            />
          </IconButton>
        </div>
        <GifPicker
          width={width}
          initialSelectedGif={selectedGif}
          onSelect={gif => {
            if (gif) {
              setGifAnchorEl(null);
            }
            setSelectedGif(gif);
          }}
        />
      </Popover>
      <Popper
        id={'selectedGif'}
        open={selectedGif}
        anchorEl={mentionsAnchorEl}
        style={{ zIndex: 2000 }}
        placement="top"
        onClose={() => {}}
      >
        <div
          style={{
            width: width <= 800 ? width * 0.95 : drawerWidth * 0.95,
            backgroundColor: 'white',
            border: '1px solid #efeff1',
            borderRadius: '5px'
          }}
        >
          <div
            style={{
              marginLeft: '20px',
              marginBottom: '10px'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={event => {
                  setSelectedGif(null);
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography>Preview</Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {selectedGif && (
                <Gif
                  gif={selectedGif}
                  width={200}
                  hideAttribution={true}
                  onGifClick={(gif, e) => {
                    e.preventDefault();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Popper>
    </div>
  );
};

ChatField.propTypes = {
  width: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onSend: PropTypes.func,
  onSettingsClick: PropTypes.func
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(ChatField);
