import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles, Toolbar, AppBar, Typography } from '@material-ui/core';
import { authenticate } from '../../store/actions/auth';
import { useSubscription, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import styles from './PreChatRoomStyles';
import ChatRoom from '../chatRoom/ChatRoom';
import Loader from '../loader/Loader';
import useWindowDimensions from '../../hooks/windowDimensions';
import appBarBackground from '../../assets/appbar_background.png';
import titeLogo from '../../assets/title_logo.png';

//this component fetches and sets auth from indigo_live_invites and renders <ChatRoom/> if result is found
const PreChatRoom = props => {
  const { inviteId, jwt, roomId, authenticate, roomStatus, auth } = props;
  const { height } = useWindowDimensions();

  const ROOM_SUBSCRIPTION = gql`
    subscription invitesSub {
      db_main_indigo_live_invites(limit: 1, where: {id: {_eq: ${inviteId}}, _and: {room_id: {_eq: "${roomId}"}, _and: {deleted_at: {_is_null: true}}}}) {
        id
        display_name
        email
        room_id
        company_name
        language
        title
        phone_number
        region
        country_code
        org_type
        is_organizer
      }
    }
  `;
  const ROOM_DATA_QUERY = gql`
    query roomDataQuery {
      db_main_indigo_live_rooms(where: {room_id: {_eq: "${roomId}"}}) {
        live_quiz_formid
        style
        title
        qa_tab
        chat_tab
      }
    }
  `;

  const appBarRender = (
    <AppBar position="fixed">
      <Toolbar
        style={{
          backgroundImage: `url(${appBarBackground})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <img src={titeLogo} height="80%" />
      </Toolbar>
    </AppBar>
  );
  const { data, loading, error } = useSubscription(ROOM_SUBSCRIPTION);
  const roomDataResponse = useQuery(ROOM_DATA_QUERY);
  if (loading || roomDataResponse.loading)
    return (
      <div>
        {/* {appBarRender} */}
        <div
          style={{
            height: `${height * 0.8}px`,
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader center={false} />
          <Typography style={{ marginLeft: '20px', textAlign: 'center' }}>
            Hold Tight - Preparing Room..
          </Typography>
        </div>
      </div>
    );

  if (error || roomDataResponse.error)
    return (
      <div>
        {appBarRender}
        <div
          style={{
            height: `${height * 0.8}px`,
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography style={{ marginLeft: '20px', textAlign: 'center' }}>
            Oops an error occured, try to refresh..
          </Typography>
        </div>
      </div>
    );

  if (
    data &&
    data.db_main_indigo_live_invites &&
    data.db_main_indigo_live_invites.length > 0 &&
    roomDataResponse.data &&
    roomDataResponse.data.db_main_indigo_live_rooms &&
    roomDataResponse.data.db_main_indigo_live_rooms.length > 0
  ) {
    if (!auth.authenticated)
      authenticate(data.db_main_indigo_live_invites[0], jwt);
    return (
      <ChatRoom
        roomId={roomId}
        roomData={roomDataResponse.data.db_main_indigo_live_rooms[0]}
      />
    );
  }
  return (
    <div>
      {appBarRender}
      <div
        style={{
          height: `${height * 0.8}px`,
          marginTop: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography style={{ marginLeft: '20px', textAlign: 'center' }}>
          Looks like you have no access to view this room
        </Typography>
      </div>
    </div>
  );
};

PreChatRoom.propTypes = {
  inviteId: PropTypes.string.isRequired,
  jwt: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch =>
    bindActionCreators(
      {
        authenticate: (data, jwt) => authenticate(data, jwt)
      },
      dispatch
    )
)(withStyles(styles)(PreChatRoom));
