import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authReducer from './auth';
import roomStatusReducer from './roomStatus';
import localSessionReducer from './localSession';

export default combineReducers({
  routing: routerReducer,
  auth: authReducer,
  roomStatus: roomStatusReducer,
  localSession: localSessionReducer
});
