export const SET_ROOM_DATA = 'SET_ROOM_DATA';
export const SET_ROOM_CLOSED = 'SET_ROOM_CLOSED';
export const SET_CHAT_DRAWER_OPEN = 'SET_CHAT_DRAWER_OPEN';
export const INCREMENT_UNREAD_COUNT = 'INCREMENT_UNREAD_COUNT';
export const SET_LAST_COMMENTS_LENGTH = 'SET_LAST_COMMENTS_LENGTH';
export const SET_ROOM_SESSIONS = 'SET_ROOM_SESSIONS';
export const SET_VIDEO_DID_END = 'SET_VIDEO_DID_END';
export const SET_ROOM_START_TIME = 'SET_ROOM_START_TIME';
export const SET_VIDEO_SUBTITLES = 'SET_VIDEO_SUBTITLES';
export const SET_FORCE_TAB_INDEX = 'SET_FORCE_TAB_INDEX';
export const SET_QUIZ_STATUS = 'SET_QUIZ_STATUS';
export const SET_QA_COUNT = 'SET_QA_COUNT';
export const SET_QA_NOTIFY_COUNT = 'SET_QA_NOTIFY_COUNT';
export const SET_QA_NOTIFY_STATUS = 'SET_QA_NOTIFY_STATUS';
