import React, {
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  setLastCommentsLength,
  incrementCommentUnreadCount,
  setQaNotifyCount
} from '../../store/actions/roomStatus';
import AdminIcon from '@material-ui/icons/AccountBalance';
import useStayScrolled from 'react-stay-scrolled';
import { useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loader from '../loader/Loader';
import useWindowDimensions from '../../hooks/windowDimensions';
import { Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import QaComment from './QaComment';
import VisibilitySensor from 'react-visibility-sensor';

const QaList = props => {
  const {
    width,
    roomStatus,
    setQaNotifyCount,
    auth,
    isMobile,
    chatDisabled
  } = props;
  const [viewedCommentsMap, setViewedCommentsMap] = useState(null);
  const [newCommentsMap, setNewCommentsMap] = useState({});
  const [commentsViewStatus, setCommentsViewStatus] = useState({});

  const { height } = useWindowDimensions();

  const scrollRef = useRef();
  const scrollDownRef = useRef();

  const getCommentsMapped = comments => {
    if (!comments) return;
    var result = {};
    for (var c of comments) {
      if (c) result[c.id] = true;
    }
    return result;
    // setViewedCommentsMap(result);
  };

  var COMMENTS_SUBSCRIPTION = gql`
  subscription chatSub {
    db_main_indigo_live_qa(where: {room_id: {_eq: "${auth.room_id}"}, _and: {deleted_at: {_is_null: true}}}, order_by: {created_at: desc}) {
      message
      id
      is_anonymous
      indigo_live_active_session {
        active
        company_name
        color
        email
        title
        display_name
        id
        country_code
        phone_number
      }
      indigo_live_qa_likes {
        id
        session_id
      }
      created_at
    }
  }
`;
  if (auth.is_organizer) {
    COMMENTS_SUBSCRIPTION = gql`
      subscription chatSub {
      db_main_indigo_live_qa(where: {room_id: {_eq: "${auth.room_id}"}, _and: {deleted_at: {_is_null: true}}}, order_by: {priority_level: desc, indigo_live_qa_likes_aggregate: {count: desc}, created_at: desc}) {
        message
        id
        is_anonymous
        priority_level
        indigo_live_active_session {
          active
          company_name
          color
          email
          title
          display_name
          id
          country_code
          phone_number
        }
        indigo_live_qa_likes {
          id
          session_id
        }
        created_at
      }
    }
   `;
  }

  var { data, loading, error } = useSubscription(COMMENTS_SUBSCRIPTION);
  useEffect(() => {
    if (!data || !data.db_main_indigo_live_qa) return;
    if (viewedCommentsMap == null) {
      var newViewedCommentsMap = getCommentsMapped(data.db_main_indigo_live_qa);
      setViewedCommentsMap(newViewedCommentsMap);
    } else {
      var unreadCommentsMap = {};
      for (var comment of data.db_main_indigo_live_qa) {
        if (!viewedCommentsMap[comment.id]) {
          unreadCommentsMap[comment.id] = true;
        }
      }
      setNewCommentsMap(unreadCommentsMap);
    }
  }, [
    data && data.db_main_indigo_live_qa ? data.db_main_indigo_live_qa : data
  ]);

  useEffect(() => {
    setQaNotifyCount(Object.keys(newCommentsMap).length);
  }, [newCommentsMap]);

  const onCommentInView = comment => {
    if (!comment || !comment.id) return;
    if (newCommentsMap[comment.id]) {
      var tmpNewComments = { ...newCommentsMap };
      delete tmpNewComments[comment.id];
      if (viewedCommentsMap != null) {
        var tmpViewedComments = { ...viewedCommentsMap };
        if (!tmpViewedComments[comment.id]) {
          tmpViewedComments[comment.id] = true;
        }
        setViewedCommentsMap(tmpViewedComments);
      }
      setNewCommentsMap(tmpNewComments);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        maxHeight:
          auth.is_organizer && !isMobile
            ? '100%'
            : isMobile
            ? `${
                height > 800
                  ? 380
                  : height > 700
                  ? 280
                  : height > 600
                  ? 210
                  : 180
              }px`
            : `${height >= 1000 ? '75%' : '68%'}`,
        minHeight:
          auth.is_organizer && !isMobile
            ? '100%'
            : isMobile
            ? `${
                height > 800
                  ? 380
                  : height > 700
                  ? 200
                  : height > 600
                  ? 210
                  : 180
              }px`
            : `${height >= 1000 ? '75%' : '68%'}`
      }}
      id="comments-section"
      ref={scrollRef}
    >
      {loading || !auth.session_id ? (
        <Loader />
      ) : error || !data.db_main_indigo_live_qa ? (
        <div>Error</div>
      ) : data.db_main_indigo_live_qa &&
        data.db_main_indigo_live_qa.length == 0 ? (
        <div
          style={{
            display: 'flex',
            // position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          {auth.is_organizer ? (
            <Typography>Questions will appear here</Typography>
          ) : (
            <Typography>Ask a question below!</Typography>
          )}
        </div>
      ) : (
        <div style={{ marginLeft: '12px', marginTop: '10px' }}>
          {data.db_main_indigo_live_qa.map((comment, index) => (
            <VisibilitySensor
              onChange={v => {
                if (v) onCommentInView(comment);
              }}
            >
              <QaComment
                key={index.toString()}
                style={{
                  marginTop: '30px',
                  marginBottom: '30px',
                  backgroundColor:
                    comment.priority_level == 1 && auth.is_organizer
                      ? '#e5eae1'
                      : comment.priority_level == 0 && auth.is_organizer
                      ? '#f5f5f5'
                      : auth.is_organizer
                      ? '#E6E6E6'
                      : null,
                  borderLeft:
                    auth.is_organizer && newCommentsMap[comment.id]
                      ? '5px solid #648464'
                      : null,
                  borderRadius: '10px'
                }}
                onActionClicked={() => {
                  onCommentInView(comment);
                }}
                comment={comment}
                width={width}
                chatDisabled={chatDisabled}
                icon={comment.admin ? <AdminIcon /> : <div />}
              />
            </VisibilitySensor>
          ))}
          <div ref={scrollDownRef} />
        </div>
      )}
    </div>
  );
};

QaList.propTypes = {
  width: PropTypes.number,
  isMobile: PropTypes.bool,
  chatDisabled: PropTypes.bool
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch =>
    bindActionCreators(
      {
        setLastCommentsLength: length => setLastCommentsLength(length),
        incrementCommentUnreadCount: newCommentLength =>
          incrementCommentUnreadCount(newCommentLength),
        setQaNotifyCount
      },
      dispatch
    )
)(QaList);
