import React, { useLayoutEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ChatComment from '../chatComment/ChatComment';
import {
  setLastCommentsLength,
  incrementCommentUnreadCount
} from '../../store/actions/roomStatus';
import useStayScrolled from 'react-stay-scrolled';
import { useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loader from '../loader/Loader';
import useWindowDimensions from '../../hooks/windowDimensions';
import { Typography, IconButton } from '@material-ui/core';
import PrivateChatComment from '../privateChatComment/PrivateChatComment';

const PrivateChatList = props => {
  const { width, roomStatus, auth, isMobile, chatDisabled, chatRoomId } = props;
  const [notifyNewMessage, setNotifyNewMessage] = useState(false);
  const [scrolledDown, setScrolledDown] = useState(true);
  const [initialScrolled, setInitialScrolled] = React.useState(false);
  const { height } = useWindowDimensions();

  const ref = useRef();
  const CHAT_SUB = gql`
    subscription privateChatSub {
      db_main_indigo_live_private_chat(where: {room_id: {_eq: "${chatRoomId}"}, _and: {deleted_at: {_is_null: true}}}, order_by: {created_at: asc}) {
        gif_id
        id
        message
        room_id
        session_id
        created_at
        indigo_live_active_session {
          active
          color
          company_name
          country_code
          display_name
          email
          id
          org_type
          phone_number
          room_id
          title
        }
      }
    }
  `;
  var { data, loading, error } = useSubscription(CHAT_SUB);
  const { stayScrolled, isScrolled, scrollBottom } = useStayScrolled(ref);

  const onScroll = useCallback(() => {
    const scrollHeight = document.getElementById('comments-section')
      .scrollHeight;
    const currentScroll =
      document.getElementById('comments-section').offsetHeight +
      document.getElementById('comments-section').scrollTop;
    if (Math.abs(currentScroll - scrollHeight) < 100) {
      setScrolledDown(true);
    } else if (Math.abs(currentScroll - scrollHeight) >= 100) {
      setScrolledDown(false);
    }
    if (isScrolled()) setNotifyNewMessage(false);
  }, []);

  useLayoutEffect(() => {
    if (scrolledDown) {
      try {
        scrollBottom();
      } catch (error) {}
    }
  }, [
    data && data.db_main_indigo_live_private_chat
      ? data.db_main_indigo_live_private_chat.length
      : 0
  ]);

  if (!loading && !error && auth.session_id && !initialScrolled) {
    setTimeout(() => {
      try {
        scrollBottom();
      } catch (error) {}
    }, 700);
    setInitialScrolled(true);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        maxHeight: isMobile
          ? `${
              height > 800 ? 380 : height > 700 ? 280 : height > 600 ? 210 : 180
            }px`
          : `${height >= 1000 ? '75%' : '68%'}`,
        minHeight: isMobile
          ? `${
              height > 800 ? 380 : height > 700 ? 200 : height > 600 ? 210 : 180
            }px`
          : `${height >= 1000 ? '75%' : '68%'}`
      }}
      id="comments-section"
      onScroll={onScroll}
      ref={ref}
    >
      {loading || !auth.session_id ? (
        <Loader />
      ) : error || !data.db_main_indigo_live_private_chat ? (
        <div>Error</div>
      ) : (
        <div style={{ marginLeft: '12px', marginTop: '10px' }}>
          {data.db_main_indigo_live_private_chat.map((comment, index) => (
            <PrivateChatComment
              key={index.toString()}
              style={{ marginTop: '30px', marginBottom: '30px' }}
              comment={comment}
              width={width}
              chatDisabled={chatDisabled}
            />
          ))}
        </div>
      )}
    </div>
  );
};

PrivateChatList.propTypes = {
  width: PropTypes.number,
  isMobile: PropTypes.bool,
  chatDisabled: PropTypes.bool
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch =>
    bindActionCreators(
      {
        setLastCommentsLength: length => setLastCommentsLength(length),
        incrementCommentUnreadCount: newCommentLength =>
          incrementCommentUnreadCount(newCommentLength)
      },
      dispatch
    )
)(PrivateChatList);
