const { DateTime } = require('luxon');

//returns number of seconds for the video to end, if negative video has already ended
export const videoEndingIn = (videoStartTime, videoDurationSecs) => {
  if (videoStartTime) {
    const startTimeUtc = DateTime.fromISO(videoStartTime, { zone: 'utc' });
    const endTimeUtc = startTimeUtc.plus({ seconds: videoDurationSecs });
    const now = DateTime.local();
    const diff = endTimeUtc.toLocal().diff(now, ['seconds']);
    return diff.seconds;
  }
  return null;
};

//returns number of seconds for the video to start, if negative video has already started
export const videoStartingIn = (videoStartTime, videoDurationSecs) => {
  if (videoStartTime) {
    const startTimeUtc = DateTime.fromISO(videoStartTime, { zone: 'utc' });
    const now = DateTime.local();
    const diff = startTimeUtc.toLocal().diff(now, ['seconds']);
    return diff.seconds;
  }
  return null;
};

export const getTimeFromString = dateTimeStr => {
  if (dateTimeStr) {
    const utcTime = DateTime.fromISO(dateTimeStr, { zone: 'utc' });
    return utcTime.toLocal().toFormat('HH:mm');
  }
  return null;
};

export const timeToLocalString = function(time, format) {
  try {
    if (time) {
      const startTimeUtc = DateTime.fromISO(time, {
        zone: 'utc'
      });
      const local = startTimeUtc.toLocal();
      return format && format == 'iso'
        ? local.toISO()
        : local.toFormat(format ? format : 'MM dd yy, h:mm a');
    }
  } catch (error) {}
};

export const getVideoStartTimeLocal = (videoStartTime, plusMinutes, iso) => {
  try {
    if (videoStartTime) {
      const startTimeUtc = DateTime.fromISO(videoStartTime, {
        zone: 'utc'
      }).plus({ minutes: plusMinutes || 0 });
      const local = startTimeUtc.toLocal();
      return iso ? local.toISO() : local.toFormat('MM dd yy, h:mm a');
    }
  } catch (error) {}
};

export const timeToUtc = localTime => {
  try {
    if (!localTime) return;
    return DateTime.fromISO(localTime)
      .toUTC()
      .toISO();
  } catch (error) {}
};
