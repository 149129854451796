import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../component/loader/Loader';
import useWindowDimensions from '../../hooks/windowDimensions';
import background from '../../assets/live_background.png';
import { getAllRooms } from '../../api/rooms';
import {
  Typography,
  Tab,
  Tabs,
  Button,
  Toolbar,
  IconButton,
  AppBar
} from '@material-ui/core';
import RoomCard from '../../component/roomCard/roomCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { timeToLocalString } from '../../utils/TimeUtils';
import titeLogo from '../../assets/indigo_logo.png';
import EventIcon from '@material-ui/icons/Event';
import CountDown from '../../component/countDown/CountDown';
import VideoPlayer from '../../component/videoPlayer/videoPlayer';

const TeaserPage = props => {
  const { height, width } = useWindowDimensions();
  const { auth, roomStatus, match } = props;
  const muxPlaybackId = match.params.id;

  const [shutdownIntercom, setShutdownIntercom] = React.useState(true);
  if (window.Intercom && shutdownIntercom) {
    window.Intercom('shutdown');
    setShutdownIntercom(false);
  }
  console.log('teaser page', muxPlaybackId);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#20003f'
      }}
    >
      <div
        style={{
          width: `${width > 800 ? width - 200 : width}px`
        }}
      >
        <VideoPlayer
          isLive={false}
          src={`https://stream.mux.com/${muxPlaybackId}.m3u8`}
          defaultSubtitles={auth.language}
          autoPlay={false}
          onVideoEnd={() => {
            // setEndingDialogOpen(true);
          }}
        />
      </div>
    </div>
  );
};

export default connect(state => ({
  auth: state.auth,
  roomStatus: state.roomStatus
}))(TeaserPage);
