import { withStyles } from '@material-ui/core/styles';
import React, { useLayoutEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ChatComment from '../chatComment/ChatComment';
import {
  setLastCommentsLength,
  incrementCommentUnreadCount
} from '../../store/actions/roomStatus';
import AdminIcon from '@material-ui/icons/AccountBalance';
import useStayScrolled from 'react-stay-scrolled';
import { useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import chatBackground from '../../assets/chat_background.png';
import Loader from '../loader/Loader';
import useWindowDimensions from '../../hooks/windowDimensions';
import { Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = theme => ({});

const ChatList = props => {
  const {
    width,
    roomStatus,
    auth,
    setLastCommentsLength,
    incrementCommentUnreadCount,
    isMobile,
    chatDisabled
  } = props;
  const [notifyNewMessage, setNotifyNewMessage] = useState(false);
  const [scrolledDown, setScrolledDown] = useState(true);
  const [initialScrolled, setInitialScrolled] = React.useState(false);
  const { height } = useWindowDimensions();

  const ref = useRef();
  const COMMENTS_SUBSCRIPTION = gql`
   subscription chatSub {
    db_main_indigo_live_chat(where: {room_id: {_eq: "${auth.room_id}"}, _and: {deleted_at: {_is_null: true}}}, order_by: {created_at: asc}) {
      message
      invite_id
      id
      gif_id
      indigo_live_active_session {
        active
        company_name
        color
        email
        title
        display_name
        id
        country_code
        phone_number
      }
      indigo_live_chat_likes {
        id
        session_id
        indigo_live_active_session {
          color
          company_name
          country_code
          display_name
          email
          id
          phone_number
          title
          active
        }
      }
      indigo_live_chat_replies(order_by: {created_at: asc}) {
        chat_id
        id
        message
        gif_id
        created_at
        indigo_live_active_session {
          color
          company_name
          country_code
          display_name
          email
          id
          title
          created_at
          phone_number
        }
        indigo_live_chat_likes {
          id
          session_id
          indigo_live_active_session {
            color
            company_name
            country_code
            display_name
            email
            id
            phone_number
            title
            active
          }
        }
      }
      created_at
    }
  }
  `;
  var { data, loading, error } = useSubscription(COMMENTS_SUBSCRIPTION);
  const { stayScrolled, isScrolled, scrollBottom } = useStayScrolled(ref);

  const onScroll = useCallback(() => {
    const scrollHeight = document.getElementById('comments-section')
      .scrollHeight;
    const currentScroll =
      document.getElementById('comments-section').offsetHeight +
      document.getElementById('comments-section').scrollTop;
    if (Math.abs(currentScroll - scrollHeight) < 100) {
      setScrolledDown(true);
    } else if (Math.abs(currentScroll - scrollHeight) >= 100) {
      setScrolledDown(false);
    }
    if (isScrolled()) setNotifyNewMessage(false);
  }, []);

  useLayoutEffect(() => {
    if (scrolledDown) {
      try {
        scrollBottom();
      } catch (error) {}
    }
  }, [
    data && data.db_main_indigo_live_chat
      ? data.db_main_indigo_live_chat.length
      : 0
  ]);
  if (!loading && !error && data.db_main_indigo_live_chat && !isMobile) {
    if (data.db_main_indigo_live_chat.length !== roomStatus.lastCommentLength) {
      if (!roomStatus.chatDrawerOpen) {
        var diff =
          data.db_main_indigo_live_chat.length - roomStatus.lastCommentLength;
        if (diff > 0)
          incrementCommentUnreadCount(data.db_main_indigo_live_chat.length);
        else setLastCommentsLength(data.db_main_indigo_live_chat.length);
      } else {
        setLastCommentsLength(data.db_main_indigo_live_chat.length);
      }
    }
  }

  if (!loading && !error && auth.session_id && !initialScrolled) {
    setTimeout(() => {
      try {
        scrollBottom();
      } catch (error) {}
    }, 700);
    setInitialScrolled(true);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        maxHeight: isMobile
          ? `${
              height > 800 ? 380 : height > 700 ? 280 : height > 600 ? 210 : 180
            }px`
          : `${height >= 1000 ? '75%' : '68%'}`,
        minHeight: isMobile
          ? `${
              height > 800 ? 380 : height > 700 ? 200 : height > 600 ? 210 : 180
            }px`
          : `${height >= 1000 ? '75%' : '68%'}`
        // backgroundImage: `url(${chatBackground})`,
        // backgroundPosition: 'start',
        // backgroundSize: 'fit',
        // backgroundRepeat: 'no-repeat'
      }}
      id="comments-section"
      onScroll={onScroll}
      ref={ref}
    >
      {loading || !auth.session_id ? (
        <Loader />
      ) : error || !data.db_main_indigo_live_chat ? (
        <div>Error</div>
      ) : (
        <div style={{ marginLeft: '12px', marginTop: '10px' }}>
          {data.db_main_indigo_live_chat.map((comment, index) => (
            <ChatComment
              key={index.toString()}
              style={{ marginTop: '30px', marginBottom: '30px' }}
              comment={comment}
              width={width}
              chatDisabled={chatDisabled}
              icon={comment.admin ? <AdminIcon /> : <div />}
            />
          ))}
        </div>
      )}
    </div>
  );
};

ChatList.propTypes = {
  width: PropTypes.number,
  isMobile: PropTypes.bool,
  chatDisabled: PropTypes.bool
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch =>
    bindActionCreators(
      {
        setLastCommentsLength: length => setLastCommentsLength(length),
        incrementCommentUnreadCount: newCommentLength =>
          incrementCommentUnreadCount(newCommentLength)
      },
      dispatch
    )
)(withStyles(styles)(ChatList));
