import {
  SET_ROOM_CLOSED,
  SET_ROOM_DATA,
  INCREMENT_UNREAD_COUNT,
  SET_LAST_COMMENTS_LENGTH,
  SET_CHAT_DRAWER_OPEN,
  SET_ROOM_SESSIONS,
  SET_VIDEO_DID_END,
  SET_ROOM_START_TIME,
  SET_VIDEO_SUBTITLES,
  SET_FORCE_TAB_INDEX,
  SET_QUIZ_STATUS,
  SET_QA_COUNT,
  SET_QA_NOTIFY_COUNT,
  SET_QA_NOTIFY_STATUS
} from '../types/roomStatus';

export const setRoomData = data => {
  return dispatch => {
    dispatch({
      type: SET_ROOM_DATA,
      payload: data
    });
  };
};

export const setQaNotifyStatus = data => {
  return dispatch => {
    dispatch({
      type: SET_QA_NOTIFY_STATUS,
      payload: data
    });
  };
};

export const setQaNotifyCount = data => {
  return dispatch => {
    dispatch({
      type: SET_QA_NOTIFY_COUNT,
      payload: data
    });
  };
};

export const setQaCount = data => {
  return dispatch => {
    dispatch({
      type: SET_QA_COUNT,
      payload: data
    });
  };
};

export const setQuizStatus = data => {
  return dispatch => {
    dispatch({
      type: SET_QUIZ_STATUS,
      payload: data
    });
  };
};

export const setForceTabIndex = data => {
  return dispatch => {
    dispatch({
      type: SET_FORCE_TAB_INDEX,
      payload: data
    });
  };
};

export const setRoomClosed = () => {
  return dispatch => {
    dispatch({
      type: SET_ROOM_CLOSED
    });
  };
};

export const setVideoDidEnd = () => {
  return dispatch => {
    dispatch({
      type: SET_VIDEO_DID_END
    });
  };
};

export const setRoomStartTime = time => {
  return dispatch => {
    dispatch({
      type: SET_ROOM_START_TIME,
      payload: time
    });
  };
};

export const incrementCommentUnreadCount = newCommentLength => {
  return dispatch => {
    dispatch({
      type: INCREMENT_UNREAD_COUNT,
      payload: newCommentLength
    });
  };
};

export const setLastCommentsLength = length => {
  return dispatch => {
    dispatch({
      type: SET_LAST_COMMENTS_LENGTH,
      payload: length
    });
  };
};

export const setDrawerOpen = open => {
  return dispatch => {
    dispatch({
      type: SET_CHAT_DRAWER_OPEN,
      payload: open
    });
  };
};

export const setRoomSessions = sessions => {
  return dispatch => {
    dispatch({
      type: SET_ROOM_SESSIONS,
      payload: sessions
    });
  };
};

export const setRoomSubtitles = subtitles => {
  return dispatch => {
    dispatch({
      type: SET_VIDEO_SUBTITLES,
      payload: subtitles
    });
  };
};
