import background from '../../assets/live_background.png';

export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    // backgroundImage: `url(${background})`,
    backgroundColor: '#20003f',
    backgroundPosition: 'start',
    backgroundSize: 'fit',
    backgroundRepeat: 'no-repeat',
    color: 'white'
  }
});
