import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Popover, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserSession from '../userSession/UserSession';
import CloseIcon from '@material-ui/icons/Close';
import UserSessionLoader from '../userSessionLoader/UserSessionLoader';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { getTimeFromString } from '../../utils/TimeUtils';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import hpLogo from '../../assets/hp_logo.png';
import useWindowDimensions from '../../hooks/windowDimensions';
import likeOutlined from '../../assets/svg_icons/like_outline.svg';
import likeFilled from '../../assets/svg_icons/like_filled.svg';
import LikesDialog from '../likesDialog/LikesDialog';
import { Gif } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import Flag from 'react-world-flags';
const gf = new GiphyFetch('5sULLXdSVcxZJ4ZERHodmV6sgBvtDylV');

const styles = theme => ({});

const httpMsg = function(content) {
  const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  return content.replace(reg, "<a target='_blank' href='$1$2'>$1$2</a>");
};

const CommentReply = props => {
  const [anchorEl, setAnchorEl] = React.useState(null); //for user session popover
  const popoverOpen = Boolean(anchorEl); //for user session popover

  const [mentionAnchorEl, setMentionAnchorEl] = React.useState(null); //for mention session popover
  const mentionPopoverOpen = Boolean(mentionAnchorEl); //for mention session popover

  const [highlight, setHighlight] = React.useState(false);

  const [commentMentions, setCommentMentions] = React.useState([]);
  const [commentTexts, setCommentTexts] = React.useState([]);

  const [initialized, setInitialized] = React.useState(false);
  const [initializing, setInitializing] = React.useState(false);

  const [userLikeId, setUserLikeId] = React.useState(null); //updates from subscription hook to know if user liked or not
  const [userUnliked, setUserUnliked] = React.useState(false); //to prevent subscription hook to reset user like after unlike
  const [sendingLikeRequest, setSendingLikeRequest] = React.useState(false);
  const [likesAnchorEl, setLikesAnchorEl] = React.useState(null);
  const likesOpen = Boolean(likesAnchorEl);

  const [fetchingGif, setFetchingGif] = React.useState(false);
  const [fetchedGif, setFetchedGif] = React.useState(null);

  const { width } = useWindowDimensions();
  const { classes, auth, chatDisabled } = props;

  const DELETE_LIKE = gql`
    mutation deleteLike($likeId: bigint!) {
      delete_db_main_indigo_live_chat_likes(where: { id: { _eq: $likeId } }) {
        affected_rows
      }
    }
  `;

  const ADD_LIKE = gql`
    mutation addLike($commentId: Int!, $sessionId: Int!) {
      insert_db_main_indigo_live_chat_likes(
        objects: { chat_reply_id: $commentId, session_id: $sessionId }
      ) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;
  const [addLike] = useMutation(ADD_LIKE);
  const [deleteLike] = useMutation(DELETE_LIKE);
  if (!fetchingGif && !fetchedGif && props.comment && props.comment.gif_id) {
    setFetchingGif(true);
    gf.gif(props.comment.gif_id)
      .then(res => {
        setFetchedGif(res.data);
        setFetchingGif(false);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const likeClickHandler = function() {
    if (!sendingLikeRequest) {
      if (userLikeId) {
        //unlike
        setSendingLikeRequest(true);
        const oldLikeId = userLikeId;
        deleteLike({ variables: { likeId: userLikeId } })
          .then(() => {
            setUserUnliked(true);
            setUserLikeId(null);
            setSendingLikeRequest(false);
          })
          .catch(err => {
            console.log(err);
            setUserUnliked(false);
            setUserLikeId(oldLikeId);
            setSendingLikeRequest(false);
          });
        setUserUnliked(true);
        setUserLikeId(null);
      } else {
        //like
        setSendingLikeRequest(true);
        const oldUserUnliked = userUnliked;
        addLike({
          variables: { commentId: props.comment.id, sessionId: auth.session_id }
        })
          .then(res => {
            if (
              res.data &&
              res.data.insert_db_main_indigo_live_chat_likes &&
              res.data.insert_db_main_indigo_live_chat_likes.returning &&
              res.data.insert_db_main_indigo_live_chat_likes.returning.length >
                0 &&
              res.data.insert_db_main_indigo_live_chat_likes.returning[0] &&
              res.data.insert_db_main_indigo_live_chat_likes.returning[0].id
            ) {
              setUserLikeId(
                res.data.insert_db_main_indigo_live_chat_likes.returning[0].id
              );
              setUserUnliked(false);
            }
            setSendingLikeRequest(false);
          })
          .catch(err => {
            console.log(err);
            setUserLikeId(null);
            setUserUnliked(oldUserUnliked);
            setSendingLikeRequest(false);
          });
      }
    }
  };
  useEffect(() => {
    if (!initialized && !initializing && auth.session_id) {
      setInitializing(true);
      initializeTextsAndMentions();
      setInitialized(true);
      setInitializing(false);
    }
  });
  const renderMessage = function(content) {
    return (
      <span>
        {commentTexts.map((t, index) => (
          <span key={index}>
            <span
              dangerouslySetInnerHTML={{
                __html: httpMsg(t.text)
              }}
            />
            <span
              style={{
                fontWeight: 'bold',
                color:
                  t.mentionIndex >= 0
                    ? commentMentions[t.mentionIndex].color
                    : null,
                cursor: 'pointer'
              }}
              onClick={event => {
                if (
                  t.mentionIndex >= 0 &&
                  commentMentions[t.mentionIndex] &&
                  commentMentions[t.mentionIndex].session_id
                )
                  setMentionAnchorEl(event.currentTarget);
              }}
            >
              {t.mentionIndex >= 0
                ? commentMentions[t.mentionIndex].text
                : null}
            </span>
            {t.mentionIndex >= 0 &&
            commentMentions[t.mentionIndex] &&
            commentMentions[t.mentionIndex].session_id ? (
              <Popover
                id={'mentionSession'}
                open={mentionPopoverOpen}
                anchorEl={mentionAnchorEl}
                onClose={() => {
                  setMentionAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <IconButton
                    style={{
                      width: '22px',
                      height: '22px',
                      marginRight: '10px'
                    }}
                    onClick={event => {
                      setMentionAnchorEl(null);
                    }}
                  >
                    <CloseIcon
                      style={{ width: '14px', height: '14px' }}
                      fontSize="small"
                    />
                  </IconButton>
                </div>
                <UserSessionLoader
                  session_id={commentMentions[t.mentionIndex].session_id}
                />
              </Popover>
            ) : null}
          </span>
        ))}
        {fetchedGif && (
          <Gif
            hideAttribution={true}
            gif={fetchedGif}
            width={200}
            onGifClick={(gif, e) => {
              e.preventDefault();
            }}
          />
        )}
      </span>
    );
  };

  const initializeTextsAndMentions = function() {
    var content = props.comment.message;
    var mentions = []; //{text:,session_id, color}
    var texts = []; //{text:,mentionIndex:}
    if (content && !content.includes('<mention>')) {
      texts = [{ text: content, mentionIndex: -1 }];
    }
    if (content) {
      while (content.includes('<mention>')) {
        var openIndex = content.indexOf('<mention>');
        var closeIndex = content.indexOf('</mention>');
        var rawMention = content.substring(openIndex + 9, closeIndex);
        if (rawMention) {
          var mentionData = rawMention.split(';');
          if (mentionData && mentionData.length > 2) {
            if (mentionData[0] == auth.session_id && !highlight) {
              setHighlight(true);
            }
            mentions.push({
              index: openIndex,
              text: mentionData[2],
              session_id: mentionData[0],
              color: mentionData[1]
            });
          }
        }
        var firstTemp = content.substring(0, openIndex);
        texts.push({ text: firstTemp, mentionIndex: mentions.length - 1 });
        var secondTemp = content.substring(closeIndex + 10);
        content = secondTemp;
        if (secondTemp.includes('<mention>')) {
          content = secondTemp.substring(secondTemp.indexOf('<mention>'));
        }
        if (secondTemp.includes('<mention>')) {
          secondTemp = secondTemp.substring(0, secondTemp.indexOf('<mention>'));
        }
        texts.push({ text: secondTemp, mentionIndex: -1 });
      }
    }
    setCommentMentions(mentions);
    setCommentTexts(texts);
  };
  // const userComment =
  //   props.comment.indigo_live_active_session &&
  //   props.comment.indigo_live_active_session.id === auth.session_id;
  const chatLikes = props.comment ? props.comment.indigo_live_chat_likes : null;
  if (chatLikes && chatLikes.length > 0) {
    for (var like of chatLikes) {
      if (
        like &&
        like.session_id == auth.session_id &&
        !userLikeId &&
        !userUnliked
      ) {
        setUserLikeId(like.id);
        break;
      }
    }
  }
  const handleLikeListClick = event => {
    if (auth.email && auth.email.includes('@hp.com')) {
      setLikesAnchorEl(event.currentTarget);
    }
  };

  const handleLikeListClose = () => {
    setLikesAnchorEl(null);
  };
  const comment = props.comment;
  const replies = props.comment ? props.comment.indigo_live_chat_replies : null;
  if (!comment) return null;
  return (
    <div style={props.style}>
      <div
        style={{
          backgroundColor: highlight ? '#efeff1' : null,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          maxWidth: `${width <= 800 ? width - 88 : 328}px`, //-42 pixels
          minWidth: `${width <= 800 ? width - 88 : 328}px`,
          marginLeft: '20px',
          marginRight: '20px'
        }}
      >
        {/* name and message column */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: `${width <= 800 ? width - 105 : 370}px`
          }}
        >
          {/* NAME first row */}
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginBottom: '5px',
              marginLeft: '2px'
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
                fontFamily: 'Roboto',
                color: comment
                  ? comment.indigo_live_active_session.color || '#000000'
                  : null,
                fontSize: 16,
                cursor: 'pointer',
                marginRight: '3px'
              }}
              onClick={event => {
                if (props.comment && props.comment.indigo_live_active_session)
                  setAnchorEl(event.currentTarget);
              }}
            >
              {props.comment.indigo_live_active_session.display_name}
            </span>
            {props.comment.indigo_live_active_session.country_code ? (
              <Flag
                style={{
                  marginRight: '3px',
                  width: '20px',
                  height: '20px'
                }}
                code={props.comment.indigo_live_active_session.country_code}
              />
            ) : null}
            {props.comment.indigo_live_active_session.email &&
            props.comment.indigo_live_active_session.email.includes(
              '@hp.com'
            ) ? (
              <img
                src={hpLogo}
                style={{
                  marginRight: '3px',
                  width: '20px',
                  height: '20px'
                }}
              />
            ) : (
              <span
                style={{
                  color: '#A3A8AB',
                  fontFamily: 'Roboto',
                  marginLeft: '3px'
                }}
              >
                {props.comment.indigo_live_active_session.company_name}
              </span>
            )}
          </span>

          {/* MESSAGE second row */}
          {props.comment.indigo_live_active_session.title ? (
            <span
              style={{
                fontFamily: 'Roboto',
                fontSize: 12,
                marginRight: '3px',
                marginLeft: '3px',
                marginBottom: '10px',
                color: 'grey'
              }}
            >
              {props.comment.indigo_live_active_session.title
                ? '(' + props.comment.indigo_live_active_session.title + ')'
                : null}
            </span>
          ) : null}

          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginBottom: '12px',
              marginLeft: '2px'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: 'Roboto',
                wordWrap: 'break-word',
                maxWidth: `${width <= 800 ? width - 182 : 308}px`,
                minWidth: `${width <= 800 ? width - 182 : 308}px`
              }}
            >
              {renderMessage(props.comment.message)}
            </span>
          </span>

          {/* ACTIONS third row */}
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontFamily: 'Roboto',
              fontSize: 12
            }}
          >
            <span style={{ color: '#A3A8AB', marginRight: '10px' }}>
              {getTimeFromString(props.comment.created_at)}
            </span>

            {chatLikes && chatLikes.length ? (
              <span
                style={{
                  marginRight: '15px',
                  color: '#292C32',
                  fontWeight: 'bold',
                  cursor:
                    auth.email && auth.email.includes('@hp.com')
                      ? 'pointer'
                      : 'cursor'
                }}
                onClick={handleLikeListClick}
              >
                {chatLikes.length} Likes
              </span>
            ) : null}
          </span>
          <LikesDialog
            open={likesOpen}
            anchorEl={likesAnchorEl}
            setAnchorEl={setLikesAnchorEl}
            numOfLikes={chatLikes.length}
            likesArray={chatLikes}
            closeDialog={handleLikeListClose}
          ></LikesDialog>
        </div>

        <Popover
          id={'userSession'}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              style={{ width: '22px', height: '22px', marginRight: '10px' }}
              onClick={event => {
                setAnchorEl(null);
              }}
            >
              <CloseIcon
                style={{ width: '14px', height: '14px' }}
                fontSize="small"
              />
            </IconButton>
          </div>
          <UserSession
            session={
              props.comment ? props.comment.indigo_live_active_session : null
            }
          />
        </Popover>

        {/* like area */}
        <div
          style={{
            fontSize: 14,
            display: 'flex',
            alignItems: 'flex-end',
            width: '10%',
            justifyContent: 'flex-end'
          }}
        >
          <div
            style={{
              fontSize: 14,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <IconButton
              size={'small'}
              onClick={likeClickHandler}
              disabled={sendingLikeRequest || chatDisabled}
            >
              <img src={userLikeId ? likeFilled : likeOutlined} width="18px" />
            </IconButton>
          </div>
        </div>
      </div>

      {/* REPLY section */}
      {replies && replies.length ? (
        <div
          style={{
            maxWidth: `${width <= 800 ? width - 100 : 370}px`,
            minWidth: `${width <= 800 ? width - 100 : 370}px`,
            marginLeft: '40px',
            marginRight: '20px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px'
          }}
        >
          <div
            style={{
              width: '0px',
              height: '35px',
              border: '1px solid #C4C4C4'
            }}
          ></div>
          {/* <Comment comment={replies[0]} /> */}
        </div>
      ) : null}
    </div>
  );
};

CommentReply.propTypes = {
  comment: PropTypes.shape({
    indigo_live_active_session: PropTypes.shape({
      color: PropTypes.string,
      display_name: PropTypes.string.isRequired,
      company_name: PropTypes.string,
      email: PropTypes.string,
      country_code: PropTypes.string
    }),
    message: PropTypes.string.isRequired,
    created_at: PropTypes.any,
    invite_id: PropTypes.any
  }),
  icon: PropTypes.node,
  width: PropTypes.number
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(withStyles(styles)(CommentReply));
