import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import useWindowDimensions from '../../hooks/windowDimensions';
import ChatList from '../chatList/ChatList';
import Loader from '../loader/Loader';
import { useSubscription, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import ChatField from '../chatField/ChatField';
// import QaList from './QaList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrivateChatList from '../privateChatList/PrivateChatList';
import { IconButton } from '@material-ui/core';

const LiveChatTab = props => {
  const { width, height } = useWindowDimensions();
  const {
    classes,
    roomStatus,
    auth,
    chatDisabled,
    loading,
    error,
    drawerWidth,
    videoDidEnd,
    mobileHeight,
    onSettingsClick,
    isMobile,
    selectedOtherPrivateSession,
    setSelectedOtherPrivateSession,
    selectedPivateRoomId,
    setSelectedPivateRoomId
  } = props;
  const [isQa, setIsQa] = useState(false);

  var ADD_PRIVATE_COMMENT = gql`
  mutation addPrivateComment ( $commentText: String!, $gifId: String!) {
    insert_db_main_indigo_live_private_chat(objects: {session_id: ${auth.session_id}, room_id: "${selectedPivateRoomId}", message: $commentText, gif_id:$gifId}){
      affected_rows
    }
  }
`;

  var ADD_COMMENT = gql`
  mutation addComment ( $commentText: String!, $gifId: String!) {
    insert_db_main_indigo_live_chat(objects: {session_id: ${auth.session_id}, invite_id:${auth.invite_id} room_id: "${auth.room_id}", message: $commentText, gif_id:$gifId}){
      affected_rows
    }
  }
`;
  const [addPrivateComment] = useMutation(ADD_PRIVATE_COMMENT);
  const [addComment] = useMutation(ADD_COMMENT);

  const commentSendSection =
    loading && false ? (
      <Loader />
    ) : error ? (
      <div>Error</div>
    ) : !roomStatus.roomFound && !loading ? (
      <div>Cannot be found</div>
    ) : (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '20%',
          fontSize: 14
        }}
      >
        {loading || chatDisabled ? ( //videoDidEnd
          <Typography
            component={'span'}
            style={{
              border: '1px solid grey',
              borderRadius: '5px',
              width: (width <= 800 ? width * 0.95 : drawerWidth * 0.95) - 2,
              fontSize: 12,
              fontWeight: 'bold',
              backgroundColor: '#d6d6d6'
            }}
          >
            <div
              style={{
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {loading ? (
                <Loader
                  size={16}
                  center={false}
                  style={{ marginRight: '10px', marginTop: '2px' }}
                />
              ) : null}
              {loading ? (
                <div>Connecting to chat</div>
              ) : videoDidEnd ? (
                <div>Room has ended</div>
              ) : (
                <div>Waiting for room to start</div>
              )}
            </div>
          </Typography>
        ) : (
          <div style={{ height: '16px' }} />
        )}

        <ChatField
          onQaToggleChange={isQa => {
            setIsQa(isQa);
          }}
          disabled={loading || chatDisabled || !auth.session_id} //videoDidEnd
          width={width}
          onSend={(comment, gif, anonymous) => {
            if (selectedOtherPrivateSession && selectedPivateRoomId) {
              return addPrivateComment({
                variables: {
                  commentText: comment,
                  gifId: gif && gif.id ? gif.id : ''
                }
              });
            }
            return addComment({
              variables: {
                commentText: comment,
                gifId: gif && gif.id ? gif.id : ''
              }
            });
          }}
          onSettingsClick={() => {
            if (onSettingsClick) onSettingsClick();
          }}
        ></ChatField>
      </div>
    );

  return (
    <div
      style={{
        height: mobileHeight ? mobileHeight : '100%',
        maxHeight: mobileHeight,
        minHeight: mobileHeight,
        backgroundColor: 'white',
        marginTop: '10px'
      }}
    >
      {selectedOtherPrivateSession && selectedPivateRoomId ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: `${height * 0.65}px`,
            maxHeight: `${height * 0.65}px`,
            minHeight: `${height * 0.65}px`,
            overflow: 'auto'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <IconButton
              style={{ alignSelf: 'center' }}
              onClick={() => {
                setSelectedOtherPrivateSession(null);
                setSelectedPivateRoomId(null);
              }}
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'bold'
              }}
            >
              Chatting with {selectedOtherPrivateSession.display_name}
            </Typography>
            <div style={{ width: '40px' }} />
          </div>
          <PrivateChatList
            width={width > 800 ? 400 : width / 2}
            chatDisabled={chatDisabled}
            chatRoomId={selectedPivateRoomId}
          />
        </div>
      ) : (
        <ChatList
          width={width > 800 ? 400 : width / 2}
          chatDisabled={chatDisabled}
          isMobile={isMobile}
        />
      )}
      {commentSendSection}
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(LiveChatTab);
