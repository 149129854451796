import axios from './axios';

export const getAllRooms = async round => {
  try {
    var roundQueryParam = '';
    if (round) roundQueryParam = `round=${round}&`;
    var res = await axios({
      method: 'get',
      url: `/api/Room/rooms?${roundQueryParam}api_key=qLqb3IPJft1tfjMOnlh49H7KsMMhvvHg`
    });
    return res.data.content;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getVideoSubtitles = async muxAssetId => {
  try {
    var muxAssetIdQueryParam = '';
    if (muxAssetId) muxAssetIdQueryParam = `mux_asset_id=${muxAssetId}&`;
    var res = await axios({
      method: 'get',
      url: `/api/Room/subtitles?${muxAssetIdQueryParam}api_key=qLqb3IPJft1tfjMOnlh49H7KsMMhvvHg`
    });
    return res.data.content;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getOngoingRoomStatus = async (jwt, roomId) => {
  try {
    var roomIdQueryParam = '';
    if (roomId) roomIdQueryParam = `room_id=${roomId}`;
    var res = await axios({
      method: 'get',
      url: `/api/Ongoing/status?${roomIdQueryParam}`,
      headers: {
        Authorization: 'Bearer ' + jwt
      }
    });
    return res.data.content;
  } catch (error) {
    console.log(error);
    return null;
  }
};
