import axios from 'axios';

axios.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json';
    config.baseURL =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? 'https://indigo-live-backend-twsbos4a7q-ew.a.run.app' //'http://localhost:3015'
        : 'https://indigo-live-backend-twsbos4a7q-ew.a.run.app';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axios;
