import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Tooltip
} from '@material-ui/core';
import styles from './RecordingStyles';
import useWindowDimensions from '../../hooks/windowDimensions';
import gql from 'graphql-tag';
import queryString from 'querystring';
import { useSubscription } from '@apollo/react-hooks';
import Loader from '../../component/loader/Loader';
import vidyardEmbed from '@vidyard/embed-code';
import jwt_decode from 'jwt-decode';
import chatSettingsIcon from '../../assets/svg_icons/chat_settings_icon.svg';
import ChatSettingsDialog from '../../component/chatSettingsDialog/ChatSettingsDialog';
import { authenticate } from '../../store/actions/auth';
import EntryFormDialog from '../../component/entryFormDialog/EntryFormDialog';
import {
  setEmail,
  setDisplayName,
  setCompanyName,
  setCountryCode,
  setLanguage
} from '../../store/actions/auth';
import { isValidEmail } from '../../utils/StringUtils';
import { getRecordingData, logRecordingSession } from '../../api/recording';
import titeLogo from '../../assets/hp-indigo-icon.png';
import VideoPlayer from '../../component/videoPlayer/videoPlayer';

const Recording = props => {
  const { height, width } = useWindowDimensions();
  const {
    match,
    classes,
    authenticate,
    auth,
    setEmail,
    setDisplayName,
    setCompanyName,
    setCountryCode,
    setLanguage,
    localSession
  } = props;
  const queryParams = queryString.decode(props.location.search.substring(1));
  const token = queryParams.token;
  const [videoRendered, setVideoRendered] = React.useState(false);
  const [videoRendering, setVideoRendering] = React.useState(false);
  const [newCredentials, setNewCredentials] = React.useState(null);
  const [settingsCredentials, setSettingsCredentials] = React.useState(null);
  const [entryDialogOpen, setEntryDialogOpen] = React.useState(true);
  const [recordingData, setRecordingData] = React.useState(null);
  const [loadingData, setLoadingData] = React.useState(true);
  const [sentRequest, setSentRequest] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [bootIntercom, setBootIntercom] = React.useState(true);
  const [selectedVimeoLanguage, setSelectedVimeoLanguage] = React.useState(
    null
  );

  if (bootIntercom && window.Intercom) {
    window.Intercom('boot');
    setBootIntercom(false);
  }

  if (!sentRequest && loadingData) {
    setSentRequest(true);
    getRecordingData(match.params.id)
      .then(data => {
        setRecordingData(data);
        setLoadingData(false);
      })
      .catch(err => {
        setRequestError(true);
        console.log(err);
        setLoadingData(false);
      });
  }

  var decodedJwtPayload;
  if (token) {
    decodedJwtPayload = jwt_decode(token);
    // decodedJwtPayload.language = 'pt';
  }
  if (!newCredentials && decodedJwtPayload && !settingsCredentials) {
    authenticate(decodedJwtPayload);
    setNewCredentials(decodedJwtPayload);
  } else if (
    newCredentials &&
    settingsCredentials &&
    (settingsCredentials.email != newCredentials.email ||
      settingsCredentials.language != newCredentials.language)
  ) {
    authenticate(settingsCredentials);
    setNewCredentials(settingsCredentials);
  }
  const onEntryFormConfirm = function(data) {
    const dataNewEmail = data.email;
    const dataNewDisplayName = data.display_name;
    const dataNewCompanyName = data.company_name;
    const dataNewCountryCode = data.country_code;
    const dataNewCaptionLanguage = data.language;

    if (dataNewCaptionLanguage) {
      setLanguage(dataNewCaptionLanguage);
    }
    if (isValidEmail(dataNewEmail)) {
      setEmail(dataNewEmail);
      if (dataNewDisplayName) setDisplayName(dataNewDisplayName);
      if (dataNewCompanyName) setCompanyName(dataNewCompanyName);
      if (dataNewCountryCode) setCountryCode(dataNewCountryCode);
      const newAuth = auth;
      newAuth.email = dataNewEmail;
      newAuth.display_name = dataNewDisplayName;
      newAuth.company_name = dataNewCompanyName;
      newAuth.country_code = dataNewCountryCode;
      newAuth.language = dataNewCaptionLanguage || auth.language;
      logRecordingSession(newAuth, match.params.id, localSession);
      setEntryDialogOpen(false);
    } else {
      alert('Please fill a valid email');
    }
  };

  const getEmbedLink = function() {
    if (!recordingData) return;
    const vimeos = recordingData.vimeo_translations;
    if (vimeos) {
      if (auth.language && vimeos[auth.language]) {
        return `https://player.vimeo.com/video/${vimeos[auth.language]}`;
      } else {
        for (const key of Object.keys(vimeos)) {
          return `https://player.vimeo.com/video/${vimeos[key]}`;
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      {loadingData ? (
        <Loader />
      ) : requestError ? (
        <Typography style={{ marginTop: '100px', fontSize: 18 }}>
          Oops an error occured, try to refresh
        </Typography>
      ) : !recordingData ? (
        <Typography style={{ marginTop: '100px', fontSize: 18 }}>
          Recording not available yet
        </Typography>
      ) : (
        <div>
          <div
            style={{ display: 'flex', marginTop: '10px', marginBottom: '5px' }}
          >
            <img
              src={titeLogo}
              height="44px"
              style={{ marginRight: '20px', marginLeft: '5px' }}
            />
            <Typography style={{ fontSize: width > 800 ? 32 : 24 }}>
              {recordingData.title}
            </Typography>
          </div>

          {!recordingData.video_type || recordingData.video_type != 'mux' ? (
            <iframe
              src={getEmbedLink()}
              width={`${width > 800 ? width - 200 : width}px`}
              height={`${((width > 800 ? width - 200 : width) * 1080) /
                1920}px`}
              frameborder="0"
              // scrolling="auto"
              // allow="autoplay; fullscreen"
              // style={{ position: 'absolute' }}
              allowFullScreen
            ></iframe>
          ) : (
            <div
              style={{
                width: `${width > 800 ? width - 200 : width}px`
              }}
            >
              <VideoPlayer
                isLive={false}
                src={`https://stream.mux.com/${recordingData.vidyard_uuid}.m3u8`}
                defaultSubtitles={auth.language}
                onVideoEnd={() => {
                  // setEndingDialogOpen(true);
                }}
              />
            </div>
          )}
        </div>
      )}
      {loadingData || !recordingData ? null : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: `${width > 800 ? width - 200 : width}px`
          }}
        >
          <span>
            <IconButton
              size={'small'}
              style={{ marginRight: '10px' }}
              onClick={event => {
                setEntryDialogOpen(true);
              }}
            >
              <img src={chatSettingsIcon} />
            </IconButton>
          </span>
          <div />
        </div>
      )}
      {loadingData || !recordingData ? null : (
        <EntryFormDialog
          width={width}
          open={entryDialogOpen}
          onConfirm={onEntryFormConfirm}
          translationLanguages={recordingData.vimeo_translations}
        />
      )}
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus,
    localSession: state.localSession
  }),
  dispatch =>
    bindActionCreators(
      {
        authenticate: payload => authenticate(payload),
        setDisplayName: displayName => setDisplayName(displayName),
        setEmail: email => setEmail(email),
        setCompanyName: companyName => setCompanyName(companyName),
        setCountryCode: countryCode => setCountryCode(countryCode),
        setLanguage: language => setLanguage(language)
      },
      dispatch
    )
)(withStyles(styles)(Recording));
