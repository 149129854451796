import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useWindowDimensions from '../../hooks/windowDimensions';
import Loader from '../loader/Loader';

const QuizTab = props => {
  const { auth, roomData, iframeHeight } = props;
  const { width, height } = useWindowDimensions();
  const [loadingQuizPage, setLoadingQuizPage] = useState(true);
  if (!roomData || !roomData.live_quiz_formid) return null;
  var countryFlagStr = '';
  if (auth.country_code) countryFlagStr = `&country=${auth.country_code}`;
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {loadingQuizPage && <Loader style={{ position: 'absolute' }} />}
      {auth && auth.display_name && auth.email && (
        <iframe
          style={{ width: '100%', height: iframeHeight, border: 'none' }}
          src={`https://app.usestrive.com/#/strive-quizzes?form_id=${roomData.live_quiz_formid}&email=${auth.email}&name=${auth.display_name}${countryFlagStr}`}
          onLoad={() => {
            setLoadingQuizPage(false);
          }}
        />
      )}
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(QuizTab);
