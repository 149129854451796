import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Typography, Tooltip, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import useWindowDimensions from '../../hooks/windowDimensions';
import {
  setSessionId,
  setEmail,
  setDisplayName,
  setCompanyName,
  setCountryCode,
  setLanguage,
  setTitle,
  setPhoneNumber,
  setOrgType,
  authenticate
} from '../../store/actions/auth';
import Loader from '../loader/Loader';
import { setRoomStartTime } from '../../store/actions/roomStatus';
import ChatSettingsDialog from '../chatSettingsDialog/ChatSettingsDialog';
import chatSettingsIcon from '../../assets/svg_icons/chat_settings_icon.svg';
import {
  updateOngoingSession,
  updateOngoingStartTime
} from '../../api/session';

import {
  videoEndingIn,
  videoStartingIn,
  getVideoStartTimeLocal,
  timeToUtc
} from '../../utils/TimeUtils';
import { getExitFormId } from '../../utils/CountryUtils';
import { isValidEmail } from '../../utils/StringUtils';
import titeLogo from '../../assets/indigo_logo_1.png';
import EndingDialog from '../endingDialog/EndingDialog';
import CountDown from '../countDown/CountDown';
import VideoPlayer from '../videoPlayer/videoPlayer';
import background from '../../assets/live_background.png';

const OngoingRoom = props => {
  const { width, height } = useWindowDimensions();
  const [dialogOpen, setDialogOpen] = React.useState(true); //for settings dialog
  const [endingDialogOpen, setEndingDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [changedCredentials, setChangedCredentials] = React.useState(null);
  const [timerDate, setTimerDate] = React.useState(null);
  const [renderVideo, setRenderVideo] = React.useState(false);
  const [renderCenterTimer, setRenderCenterTimer] = React.useState(false);
  const [firstLoadHandled, setFirstLoadHandled] = React.useState(false);
  const [initVidStatus, setInitVidStatus] = React.useState(true);
  const [updateSession, setUpdateSession] = React.useState(false);
  const [sessionTimerSet, setSessionTimerSet] = React.useState(false);
  const {
    roomStatus,
    auth,
    authenticate,
    setRoomStartTime,
    firstLoad,
    startWatchTime,
    roomId
  } = props;
  const [startTime, setStartTime] = React.useState(startWatchTime);

  const onChatSettingsConfirm = function(data) {
    setChangedCredentials(data);
    setDialogOpen(false);
    setUpdateSession(true);
  };

  const initializeVideoStatus = function() {
    const startingIn = videoStartingIn(
      roomStatus.start_time,
      roomStatus.duration
    );
    if (startingIn > 0) {
      //set timer and don't play the video
      setRenderVideo(false);
      var timerLocal = getVideoStartTimeLocal(roomStatus.start_time);
      setTimerDate(timerLocal);
      setTimeout(() => {
        setInitVidStatus(true);
      }, startingIn * 1000);
    } else {
      //play video and set timer
      var fullDurationsCount = Math.floor(
        startingIn / (-1 * roomStatus.duration)
      );
      const newStartTime = getVideoStartTimeLocal(
        roomStatus.start_time,
        Math.floor(((fullDurationsCount + 1) * roomStatus.duration) / 60),
        true
      );
      var skipTime =
        (startingIn / (-1 * roomStatus.duration) - fullDurationsCount) *
        roomStatus.duration;
      var nextVideoStartsIn = roomStatus.duration - skipTime;
      if (startTime) {
        if (
          videoStartingIn(startTime) > -3600 &&
          videoStartingIn(startTime) < 0
        ) {
          const timerLocal = getVideoStartTimeLocal(startTime, 60);
          setTimerDate(timerLocal);
          nextVideoStartsIn = videoStartingIn(startTime) + 3600;
          setRoomStartTime(startTime);
        } else {
          const timerLocal = getVideoStartTimeLocal(newStartTime);
          setTimerDate(timerLocal);
          setStartTime(newStartTime);
          updateOngoingStartTime(
            auth.jwt,
            roomId,
            auth.email,
            timeToUtc(newStartTime)
          );
        }
      } else {
        const timerLocal = getVideoStartTimeLocal(newStartTime);
        setTimerDate(timerLocal);
        setStartTime(newStartTime);
        updateOngoingStartTime(
          auth.jwt,
          roomId,
          auth.email,
          timeToUtc(newStartTime)
        );
      }
      if (
        (!firstLoad && startTime && videoStartingIn(startTime) < 0) ||
        firstLoadHandled
      ) {
        setTimeout(() => {
          setRenderVideo(true);
        }, 1000);
      } else {
        setRenderCenterTimer(true);
        setFirstLoadHandled(true);
      }
      setTimeout(() => {
        setRenderCenterTimer(false);
        setRenderVideo(false);
        setInitVidStatus(true);
      }, nextVideoStartsIn * 1000);
    }
  };

  useEffect(() => {
    if (dialogOpen) return;
    if (updateSession && startTime) {
      updateOngoingSession(auth, timeToUtc(startTime));
      setUpdateSession(false);
    }
    if (changedCredentials) {
      authenticate(changedCredentials, auth.jwt);
      setChangedCredentials(null);
    }
    if (!timerDate || initVidStatus) {
      initializeVideoStatus();
      setInitVidStatus(false);
    }
    if (!sessionTimerSet) {
      setSessionTimerSet(true);
      setInterval(() => {
        setUpdateSession(true);
      }, 300000);
    }
  }, [changedCredentials, updateSession, initVidStatus, dialogOpen]);

  const video = loading ? (
    <Loader style={{ height: `${height * 0.5}px` }} />
  ) : roomStatus.vidyard_uuid && roomStatus.video_type == 'mux' ? (
    <VideoPlayer
      isLive={true}
      src={`https://stream.mux.com/${roomStatus.vidyard_uuid}.m3u8`}
      placeholderSrc="https://stream.mux.com/g02U02LFEgSTEyrmlq02OeiayLwAHGgahAV6JrfNoPRYGI.m3u8"
      defaultSubtitles={auth.language}
      onVideoEnd={() => {
        setEndingDialogOpen(true);
      }}
    />
  ) : null;

  if (roomStatus && roomStatus.external_link) {
    window.location.href = roomStatus.external_link;
    return null;
  }
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'start',
        backgroundSize: 'fit',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <div
        position="fixed"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '78px',
          width: '100%',
          backgroundColor: 'transparent',
          padding: '0px'
        }}
      >
        <img src={titeLogo} height="44px" style={{ marginLeft: '20px' }} />
        {timerDate && width > 800 && !renderCenterTimer && (
          <div
            style={{
              backgroundColor: 'rgba(3, 3, 0,0.5)',
              padding: '10px',
              borderRadius: '10px',
              margin: '10px'
            }}
          >
            <CountDown
              overrideText={
                renderVideo
                  ? 'Session will start again in'
                  : 'Session will start in'
              }
              timeTillDate={timerDate}
              timeFormat="MM DD YYYY, h:mm a"
            />
          </div>
        )}
      </div>

      <EndingDialog
        width={width}
        open={endingDialogOpen}
        onClose={() => {
          setEndingDialogOpen(false);
        }}
        onConfirm={() => {}}
      />

      <ChatSettingsDialog
        width={width}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        recording={true}
        onConfirm={onChatSettingsConfirm}
      />
      {renderVideo && (
        <div style={{ width: `${width > 800 ? width * 0.8 : width}px` }}>
          {video}
        </div>
      )}

      {renderCenterTimer && timerDate && (
        <CountDown timeTillDate={timerDate} timeFormat="MM DD YYYY, h:mm a" />
      )}

      <div
        style={{
          width: `${width > 800 ? width * 0.8 : width}px`,
          marginTop: '10px'
        }}
      >
        <Tooltip title="Settings">
          <span>
            <IconButton
              disabled={false}
              size={'small'}
              style={{ marginRight: '10px' }}
              onClick={event => {
                setDialogOpen(true);
              }}
            >
              <img src={chatSettingsIcon} />
            </IconButton>
          </span>
        </Tooltip>
      </div>

      {timerDate && width <= 800 && !renderCenterTimer && (
        <div
          style={{
            backgroundColor: 'rgba(3, 3, 0,0.5)',
            padding: '10px',
            borderRadius: '10px',
            margin: '10px'
          }}
        >
          <CountDown
            overrideText={
              renderVideo
                ? 'Session will start again in'
                : 'Session will start in'
            }
            timeTillDate={timerDate}
            timeFormat="MM DD YYYY, h:mm a"
            overrideFontSize="10px"
          />
        </div>
      )}
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch =>
    bindActionCreators(
      {
        setSessionId: sessionId => setSessionId(sessionId),
        setDisplayName: displayName => setDisplayName(displayName),
        setEmail: email => setEmail(email),
        setCompanyName: companyName => setCompanyName(companyName),
        setCountryCode: countryCode => setCountryCode(countryCode),
        setLanguage: language => setLanguage(language),
        setTitle: title => setTitle(title),
        setPhoneNumber: phoneNumber => setPhoneNumber(phoneNumber),
        setOrgType: orgType => setOrgType(orgType),
        authenticate: (payload, jwt) => authenticate(payload, jwt),
        setRoomStartTime: time => setRoomStartTime(time)
      },
      dispatch
    )
)(OngoingRoom);
