import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../component/loader/Loader';
import useWindowDimensions from '../../hooks/windowDimensions';
import background from '../../assets/live_background.png';
import { getAllRooms } from '../../api/rooms';
import {
  Typography,
  Tab,
  Tabs,
  Button,
  Toolbar,
  IconButton,
  AppBar
} from '@material-ui/core';
import RoomCard from '../../component/roomCard/roomCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './styles.css';
import { timeToLocalString } from '../../utils/TimeUtils';
import titeLogo from '../../assets/indigo_logo.png';
import EventIcon from '@material-ui/icons/Event';

function TabPanel(props) {
  const { children, value, index, maxHeight, minHeight, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`segment-tabpanel-${index}`}
      aria-labelledby={`segment-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div
          style={{
            height: '100%',
            maxHeight: maxHeight,
            minHeight: minHeight
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

const Timeline = props => {
  const { height, width } = useWindowDimensions();
  const { auth, roomStatus } = props;
  const [allRooms, setAllRooms] = useState(null); //grouped by round {round: , rooms}
  const [allRounds, setAllRounds] = useState(null);
  const [selectedRound, setSelectedRound] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const getSelectedTabFilter = function() {
    switch (selectedTab) {
      case 0:
        return 'Labels';
      case 1:
        return 'Commercial';
      case 2:
        return 'Flexible Packaging';
      case 3:
        return 'Folding Cartons';
    }
  };
  const settings = {
    dots: true,
    slidesToShow: width > 800 ? 2 : 1
  };
  useEffect(() => {
    getAllRooms()
      .then(rooms => {
        const parsedRounds = [];
        const parsedRooms = [];
        if (rooms) {
          for (var r of rooms) {
            if (parsedRounds.includes(r.round)) {
              for (var i = 0; i < parsedRooms.length; i++) {
                if (parsedRooms[i].round == r.round) break;
              }
              if (i < parsedRooms.length) {
                parsedRooms[i].rooms.push(r);
              }
            } else {
              parsedRounds.push(r.round);
              parsedRooms.push({ round: r.round, rooms: [r] });
            }
          }
        }
        if (parsedRounds.length > 0) setSelectedRound(parsedRounds[0]);
        setAllRooms(parsedRooms);
        setAllRounds(parsedRounds);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        setError(true);
      });
  }, []);

  const currRooms = function() {
    if (allRooms && selectedRound) {
      var res = allRooms.find(r => r.round == selectedRound);
      return res ? res.rooms : null;
    }
  };

  const isMobile = width <= 800;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: '100vh',
        color: 'black'
      }}
    >
      {loading && <Loader />}
      {error && <Typography>Error</Typography>}

      {!error && !loading && currRooms() && (
        <div style={{ width: '100%' }}>
          <AppBar style={{ backgroundColor: 'white', color: 'black' }}>
            <Toolbar>
              {!isMobile && (
                <img
                  src={titeLogo}
                  height="40px"
                  style={{ marginRight: '60px' }}
                />
              )}
              <Tabs
                value={selectedTab}
                centered
                indicatorColor="primary"
                onChange={(event, newIndex) => {
                  setSelectedTab(newIndex);
                }}
              >
                <Tab
                  label="Labels"
                  id={`segment-tab-${0}`}
                  style={{ fontSize: isMobile ? 10 : 16 }}
                />
                <Tab
                  label="Commercial"
                  id={`segment-tab-${1}`}
                  style={{ fontSize: isMobile ? 10 : 16 }}
                />
                <Tab
                  label="Flexible Packaging"
                  id={`segment-tab-${1}`}
                  style={{ fontSize: isMobile ? 10 : 16 }}
                />
                <Tab
                  label="Folding Cartons"
                  id={`segment-tab-${1}`}
                  style={{ fontSize: isMobile ? 10 : 16 }}
                />
              </Tabs>
            </Toolbar>
          </AppBar>

          <div style={{ marginTop: '60px' }} />
          {/* Latest upcoming indigo live */}
          {currRooms().filter(
            r => r.occured == false && r.detail == getSelectedTabFilter()
          ) &&
          currRooms().filter(
            r => r.occured == false && r.detail == getSelectedTabFilter()
          ).length > 0 ? (
            <div
              style={{
                backgroundColor: 'rgb(241, 243, 244)',
                paddingTop: '50px'
              }}
            >
              <Typography
                style={{
                  marginLeft: isMobile ? '50px' : '100px',
                  fontSize: isMobile ? 22 : 32,
                  font:
                    'Google Sans,Noto Sans,Noto Sans JP,Noto Sans KR,Noto Naskh Arabic,Noto Sans Thai,Noto Sans Hebrew,Noto Sans Bengali,sans-serif'
                }}
              >
                Upcoming sessions
              </Typography>
              {isMobile && (
                <img
                  src={background}
                  style={{
                    marginLeft: isMobile ? '50px' : '100px',
                    width: '240px',
                    marginRight: '50px',
                    height: '100%'
                  }}
                />
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingLeft: isMobile ? '50px' : '100px',
                  paddingRight: isMobile ? '50px' : '100px',
                  paddingTop: '50px',
                  paddingBottom: '100px',
                  marginBottom: '20px'
                }}
              >
                {!isMobile && (
                  <img
                    src={background}
                    style={{
                      width: '300px',
                      marginRight: '50px',
                      height: '100%'
                    }}
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography
                    onClick={() => {
                      const room = currRooms().filter(
                        r =>
                          r.occured == false &&
                          r.detail == getSelectedTabFilter()
                      )[0];
                      if (!room.occured) {
                        window.open(
                          'https://usestrive.typeform.com/to/BNYLy9',
                          '_blank'
                        );
                      } else if (room.occured && room.recording_url) {
                        window.open(`${room.recording_url}`, '_blank');
                      }
                    }}
                    style={{
                      fontSize: isMobile ? 14 : 18,
                      fontWeight: 600,
                      color: '#1a73e8',
                      cursor: 'pointer',
                      font:
                        '400 24px/32px Google Sans,Noto Sans,Noto Sans JP,Noto Sans KR,Noto Naskh Arabic,Noto Sans Thai,Noto Sans Hebrew,Noto Sans Bengali,sans-serif'
                    }}
                  >
                    {
                      currRooms().filter(
                        r =>
                          r.occured == false &&
                          r.detail == getSelectedTabFilter()
                      )[0].title
                    }
                  </Typography>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '15px'
                    }}
                  >
                    <EventIcon style={{ color: '#757575' }} />
                    <Typography>
                      {timeToLocalString(
                        currRooms().filter(
                          r =>
                            r.occured == false &&
                            r.detail == getSelectedTabFilter()
                        )[0].start_time,
                        currRooms().filter(
                          r =>
                            r.occured == false &&
                            r.detail == getSelectedTabFilter()
                        )[0].occured
                          ? 'MMMM d'
                          : 'MMM d - hh:mm a'
                      )}
                    </Typography>
                    <Typography style={{ marginLeft: '25px' }}>
                      {
                        currRooms().filter(
                          r =>
                            r.occured == false &&
                            r.detail == getSelectedTabFilter()
                        )[0].regions
                      }
                    </Typography>
                  </div>

                  <Button
                    style={{
                      width: '100px',
                      color: 'white',
                      backgroundColor: '#1a73e8',
                      marginTop: '20px',
                      fontSize: 18
                    }}
                    color="primary"
                    onClick={() => {
                      window.open(
                        'https://usestrive.typeform.com/to/BNYLy9',
                        '_blank'
                      );
                    }}
                  >
                    Register
                  </Button>
                </div>
              </div>
            </div>
          ) : null}

          {/* Other upcoming sessions */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: isMobile ? '50px' : '100px',
              marginRight: isMobile ? '50px' : '100px'
            }}
          >
            {currRooms().filter(
              r => r.occured == false && r.detail == getSelectedTabFilter()
            ).length > (width > 800 ? 3 : 1) ? (
              <Slider
                dots={true}
                slidesToShow={width > 800 ? 3 : 1}
                style={{ width: '90%' }}
              >
                {currRooms()
                  .filter(
                    r =>
                      r.occured == false && r.detail == getSelectedTabFilter()
                  )
                  .map((r, i) => (
                    <RoomCard room={i == 0 ? null : r} />
                  ))}
              </Slider>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {currRooms()
                  .filter(
                    r =>
                      r.occured == false && r.detail == getSelectedTabFilter()
                  )
                  .map((r, i) => (
                    <RoomCard room={i == 0 ? null : r} />
                  ))}
              </div>
            )}
          </div>

          <Typography
            style={{
              marginLeft: isMobile ? '50px' : '100px',
              marginTop: '70px',
              marginBottom: '40px',
              font:
                '400 32px/40px Google Sans,Noto Sans,Noto Sans JP,Noto Sans KR,Noto Naskh Arabic,Noto Sans Thai,Noto Sans Hebrew,Noto Sans Bengali,sans-serif'
            }}
          >
            Previous sessions
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: isMobile ? '50px' : '100px',
              marginBottom: '50px'
            }}
          >
            {currRooms().filter(
              r => r.occured == true && r.detail == getSelectedTabFilter()
            ).length > (width > 800 ? 3 : 1) ? (
              <Slider
                dots={true}
                slidesToShow={width > 1300 ? 3 : width > 800 ? 2 : 1}
                style={{ width: '90%' }}
              >
                {currRooms()
                  .filter(
                    r => r.occured == true && r.detail == getSelectedTabFilter()
                  )
                  .map((r, i) => (
                    <RoomCard room={r} />
                  ))}
              </Slider>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {currRooms()
                  .filter(
                    r => r.occured == true && r.detail == getSelectedTabFilter()
                  )
                  .map((r, i) => (
                    <RoomCard room={r} applyMargin="50px" />
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(state => ({
  auth: state.auth,
  roomStatus: state.roomStatus
}))(Timeline);
