import React from 'react';
import moment from 'moment';
import './CountDown.css';
import { Typography } from '@material-ui/core';
export class CountDown extends React.Component {
  state = {
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
    _countdown: undefined
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      const { timeTillDate, timeFormat } = this.props;
      const then = moment(timeTillDate, timeFormat).unix();
      const now = moment().unix();
      const _countdown = then - now;
      const duration = moment.duration(_countdown * 1000, 'milliseconds');
      const days = Math.floor(moment.duration(duration).asDays());
      const hours = Math.floor(moment.duration(duration).hours());
      const minutes = Math.floor(moment.duration(duration).minutes());
      const seconds = Math.floor(moment.duration(duration).seconds());

      this.setState({ days, hours, minutes, seconds, _countdown });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { days, hours, minutes, seconds, _countdown } = this.state;
    const { hideText, overrideText, overrideFontSize, title } = this.props;
    // Mapping the date values to radius values
    const daysRadius = mapNumber(days, 30, 0, 0, 360);
    const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
    const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
    const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

    if (!seconds) {
      return null;
    }

    if (_countdown && _countdown > 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {overrideText ? (
            <Typography
              style={{
                fontSize: overrideFontSize ? overrideFontSize : '14px',
                color: 'white',
                textAlign: 'center',
                marginBottom: '5px'
              }}
            >
              {overrideText}
            </Typography>
          ) : (
            title && (
              <img
                src="https://storage.googleapis.com/indigo_live/here_we_go_3/title%40300x.png"
                height="100px"
              />
            )
          )}
          <div className="countdown-wrapper">
            {days > 0 ? (
              <div
                className="countdown-item"
                style={
                  overrideText
                    ? {
                        fontSize: overrideFontSize ? overrideFontSize : '14px',
                        lineHeight: '14px',
                        height: '20px',
                        margin: 0,
                        padding: 0
                      }
                    : null
                }
              >
                {/* <SVGCircle radius={daysRadius} /> */}
                {days}
                <span>days</span>
              </div>
            ) : null}
            {days > 0 || hours > 0 ? (
              <div
                className="countdown-item"
                style={
                  overrideText
                    ? {
                        fontSize: overrideFontSize ? overrideFontSize : '14px',
                        lineHeight: '14px',
                        height: '20px',
                        margin: 0,
                        padding: 0
                      }
                    : null
                }
              >
                {/* <SVGCircle radius={hoursRadius} /> */}
                {hours}
                <span
                  style={
                    overrideText
                      ? {
                          fontSize: overrideFontSize
                            ? overrideFontSize
                            : '14px',
                          lineHeight: '14px'
                        }
                      : null
                  }
                >
                  hours
                </span>
              </div>
            ) : null}
            <div
              className="countdown-item"
              style={
                overrideText
                  ? {
                      fontSize: overrideFontSize ? overrideFontSize : '14px',
                      lineHeight: '14px',
                      height: '20px',
                      margin: 0,
                      padding: 0
                    }
                  : null
              }
            >
              {/* <SVGCircle radius={minutesRadius} /> */}
              {minutes}
              <span
                style={
                  overrideText
                    ? {
                        fontSize: overrideFontSize ? overrideFontSize : '14px',
                        lineHeight: '14px'
                      }
                    : null
                }
              >
                minutes
              </span>
            </div>
            <div
              className="countdown-item"
              style={
                overrideText
                  ? {
                      fontSize: overrideFontSize ? overrideFontSize : '14px',
                      lineHeight: '14px',
                      height: '20px',
                      margin: 0,
                      padding: 0
                    }
                  : null
              }
            >
              {/* <SVGCircle radius={secondsRadius} /> */}
              {seconds}
              <span
                style={
                  overrideText
                    ? {
                        fontSize: overrideFontSize ? overrideFontSize : '14px',
                        lineHeight: '14px'
                      }
                    : null
                }
              >
                seconds
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return hideText ? null : <h1>Press Play Soon..</h1>;
    }
  }
}

const SVGCircle = ({ radius }) => (
  <svg className="countdown-svg">
    <path
      fill="none"
      stroke="#333"
      stroke-width="4"
      d={describeArc(50, 50, 48, 0, radius)}
    />
  </svg>
);

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  var d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y
  ].join(' ');

  return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
  return (
    ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
  );
}
export default CountDown;
