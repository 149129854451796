import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import React from 'react';
import hpLogo from '../../assets/hp_logo.png';

const UsersList = props => {
  const { auth, users } = props;
  return (
    <div {...props}>
      <div>
        {users && users.length > 0
          ? users.map(d => {
              return (
                <div
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: '10px', marginBottom: 10 }}
                >
                  <div
                    key={d.indigo_live_active_session.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      style={{
                        color: d.indigo_live_active_session.color,
                        fontWeight: 'bold',
                        fontSize: 12,
                        marginRight: '5px'
                      }}
                      key={d.indigo_live_active_session.id}
                    >
                      {d.indigo_live_active_session.display_name}
                    </Typography>
                    <Typography
                      style={{
                        color: 'grey',
                        fontSize: 10,
                        // margin: '0px 10px 0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}
                    >
                      {d.indigo_live_active_session.title
                        ? '(' + d.indigo_live_active_session.title + ')'
                        : null}
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      fontSize: 12,
                      marginLeft: '10px'
                    }}
                  >
                    {d.indigo_live_active_session.email ? (
                      <a
                        href={`mailto:${d.indigo_live_active_session.email}`}
                        target="_blank"
                        style={{
                          textDecoration: 'none',
                          color: '#008bbf'
                        }}
                      >
                        {d.indigo_live_active_session.email}
                      </a>
                    ) : null}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 12,
                      marginLeft: '10px'
                    }}
                  >
                    {d.indigo_live_active_session.phone_number ? (
                      <a
                        href={`tel::${d.indigo_live_active_session.phone_number}`}
                        target="_blank"
                        style={{
                          textDecoration: 'none',
                          color: '#008bbf'
                        }}
                      >
                        {d.indigo_live_active_session.phone_number}
                      </a>
                    ) : null}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 12,
                      marginLeft: 10
                    }}
                    component={'span'}
                  >
                    {d.indigo_live_active_session.email &&
                    d.indigo_live_active_session.email.includes('@hp.com') ? (
                      <img src={hpLogo} height="16px" />
                    ) : (
                      d.indigo_live_active_session.company_name
                    )}
                  </Typography>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default connect(
  state => ({
    auth: state.auth,
    roomStatus: state.roomStatus
  }),
  dispatch => bindActionCreators({}, dispatch)
)(UsersList);
